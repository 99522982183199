<template>
  <div class="b2c-page-bg">
    <div v-if="step==1">
      <div class=" fs-sp">
        <div class="w-100 b2c-bg">
          <div class="w-100 b2c-bg-top" v-if="!checkIsInIframe">
            <div class="b2c-top-left" @click="goToStep(0,false)"></div>
            <div class="b2c-top-center">Chọn số của bạn</div>
            <div class="b2c-top-right"></div>
          </div>
          <div class="b2c-steps">
            <div class="b2c-step1"/>
          </div>
          <div class="b2c-text-solo">
            Nhập số thuê bao mong muốn
          </div>
          <div class="b2c-bg-top">
            <div class="b2c-number-087">087</div>
            <div class="b2c-number-msisdn">
              <!-- <input class="b2c-number-input"
                                   v-model.trim="SearchString"
                                   placeholder="Nhập *68, 68*, *8*8*"
                                   maxlength="7"
                                   @keydown.enter="handleSearch()"
                            /> -->
              <input-required-phone
                  v-model.trim="SearchString"
                  @onSearch="handleSearch()"
              />
            </div>
            <div class="b2c-number-btn">
              <button @click="handleSearch()"></button>
            </div>
          </div>
          <div :class="numberExceptCollapse?'b2c-number-except':'b2c-number-except-e'">
            <div class="b2c-number-except-text">Loại trừ số:</div>
            <div class="b2c-number-except-buttons">
              <button @click="exception(i-1)" v-for="i in [5,8,1,2,3,4,6,7,9,10]" :key="i"
                      :class="serverParams.columnFilters.except.find(j=>j==i-1)== undefined?'b2c-number-except-btn':'b2c-number-except-btn-checked'">
                {{ i - 1 }}
              </button>
            </div>
            <button @click="numberExceptCollapse=!numberExceptCollapse"
                    :class="numberExceptCollapse?'b2c-number-except-colapse':'b2c-number-except-nocolapse'"/>
          </div>
          <div :class="'b2c-number-type'">
            <div class="b2c-number-type-text">Danh mục số:</div>
            <!--<select @change="serverParams.page=1" v-model="serverParams.columnFilters.simCategory" class="b2c-number-type-select">
              <option v-for="i in getSimGroups()" :key="i.id" :value="i.id">{{i.text}}</option>
            </select>-->
            <v-select @input="handleCategory" class="w-100 border-0 mr-1 style-select" :clearable="false" :searchable="false"
                      label="text"
                      v-model="serverParams.columnFilters.simCategory" :options="getSimGroups()"
                      :reduce="y => y.Id"
                      placeholder="Tất cả danh mục số"></v-select>
          </div>
        </div>
        <div class=" pr-2 pl-2 ">
<!--          <LuckyNumberMB-->
<!--              @hadleByNow="hadleByNow" :DataPackages="DataPackages"/>-->
          <SearchForMonth :dataSearchMonthly="dataSearchMonthly" @handleSeachMonth="handleSeachMonth"
                          :isSeachMonth="isSeachMonth" v-if="!isHasSearchCode"/>

        </div>
        <div class="d-flex pb-3 pr-2 pl-2 pt-3">
          <div class="b2c-pagination-title"
               style="width: 70%; text-align: justify; padding-right: 15px; font-size: 12px;" v-if="isNotFound">!! Rất
            tiếc, số bạn đang tìm hiện không còn. Có thể bạn sẽ thích những số sau:
          </div>
          <div v-else class="b2c-pagination-title" style="width: 70%;">{{ totalRecords | number }} số hiện có</div>
          <select @change="serverParams.page=1" v-model="serverParams.extra.price_sort"
                  class="b2c-pagination-title-sort" style="text-align: left; max-height: 35px;">
            <option v-for="i in prices" :key="i.id" :value="i.id">{{ i.text }}</option>
          </select>
        </div>
        <div class="w-100" style="min-height: 100px;" v-if="rows.length>0">

          <div class="w-100 pb-2 pr-2 pl-2 pt-0" v-if="!isLoading">
            <b-card :class="cart.find(j=>j.Phone==i.Phone)!=undefined?'p-0 b2c-bg-selected':'p-0'"
                    v-for="i in rows"
                    :key="i.Phone">
              <b-card-text>
                <!-- <div :product_link="`https://itel.vn/san-pham/${i.Phone}`" class="row">
                  <div :product_link="`https://itel.vn/san-pham/${i.Phone}`"
                       style="padding-bottom:5px"
                       class="col-8 b2c-page-msisdn">
                    <span class="b2c-phone" v-html="i.PhoneFormated"></span>
                  </div>
                  <div class="col-4 text-right" v-if="cart.find(j=>j.Phone==i.Phone)==undefined">
                    <button v-if="cart.find(j=>j.Phone==i.Phone)==undefined && !isHasSearchCode" @click="addToCart(i,0)"
                            class="b2c-buy-btn">Mua ngay
                    </button>
                    <button v-if="cart.find(j=>j.Phone==i.Phone)==undefined && isHasSearchCode" @click="addToCart(i,0)"
                            class="b2c-buy-btn">Chọn ngay
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-9">
                    <span class="badge badge-primary b2c-badge cursor-pointer mr-1"
                          @click="handleTypeNumber(i.SimType)">{{ i.group_name }}</span>
                    <span @click="handleTypeTags(item)" v-for="(item,index) of i.Tags" :key="index"
                          class="badge badge-primary b2c-badge cursor-pointer mt-1"
                          :class="index!== i.Tags ? 'mr-1':''"
                    >{{ item.Tag }}</span>
                    <span class="badge badge-primary ml-1 b2c-badge mt-1" @click="handlePledge()"
                          v-if="i.ThoiGianCamKet>0">Số cam kết</span>
                    <span class="badge badge-primary ml-1 b2c-badge mt-1" @click="handleSimVip()"
                          v-if="i.Price > 5000000">SỐ VIP</span>
                  </div>
                </div>
                <div class="row" v-if="i.ThoiGianCamKet>0">
                  <div v-if="!(i.CommitType == 'PSC')" class="b2c-des-highlight">
                       <div class="cs-desc">Cam kết tiêu dùng tài khoản tối thiểu <br/><span class="bold-text">{{ i.CommitValue || "49.000đ" }}/tháng </span>trong <span class="bold-text">{{ i.Months || 36 }} tháng</span></div>
                       <div class="cs-desc_sub">Tiêu dùng tài khoản gồm: đăng ký gói hoặc gọi thoại/SMS/Data</div>
                   </div>
                  <div v-else class="col-12 b2c-camket-text">Cam kết gói <b>{{ i.Pack }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price|number }}đ</b> trong
                    {{ i.ThoiGianCamKet }} tháng
                  </div>
                </div>
                <td style="font-size:14px;text-align:left;line-height:32px"
                    v-else-if="$route.query.sim_category_id_sort && $route.query.sim_category_id_sort == 49">
                  Gói <b>{{ i.Pack }}</b> - <span v-html="PromotionInfo[i.Pack].flow"></span>
                  <p style="font-size:12px;margin-top:10px" v-html="PromotionInfo[i.Pack].extraText"/>
                </td>
                <td style="font-size:14px;text-align:left;line-height:32px" v-else>Được đăng ký Gói cước
                  <b>{{ i.Pack }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price|number }}đ </b>siêu ưu đãi<p
                      style="font-size:12px;margin-top:5px"
                      v-html="PackInfo[i.Pack]"/></td>
                <p class="b2c-price-text" v-if="!isHasSearchCode"><b>{{ i.Price + i.SimPrice|number }}đ</b></p>
                <p class="b2c-price-text" v-else><b>{{ i.Price |number }}đ</b></p>
                <p v-if="(i.Price + i.SimPrice) !=50000 && i.SimType=== 10" class="b2c-price-text line-through"
                   style="font-size: 16px; color: #5e5873; font-weight: 400; padding-right: 8px; padding-top: 0;">
                  50.000đ</p> -->
                <div :product_link="`https://itel.vn/san-pham/${i.Phone}`" class="row">
                  <div :product_link="`https://itel.vn/san-pham/${i.Phone}`" style="padding-bottom:5px"
                       class="col-8 b2c-page-msisdn">
                    <span class="b2c-phone phone-text-mobile" v-html="i.PhoneFormated"></span>
                  </div>
                  <div class="col-4 text-right">
                    <div class="price-text-mobile">
                      <p class="b2c-price-text pt-0"><b>{{ i.Price + i.SimPrice | number }}đ</b></p>
                      <!-- <p class="b2c-price-text b2c-ghtk-fee"
                          style="font-size: 16px; color: #5e5873; font-weight: 400; padding-right: 8px; padding-top: 0;">
                          {{ (i.Price + i.SimPrice + 160000) | number }}đ</p> -->
                    </div>
                  </div>
                </div>
                <div class="row" v-if="i.ThoiGianCamKet > 0">
                  <div v-if="(i.CommitType == 'PSC')" class="col-12 b2c-camket-text b2c-des-highlight mt-0">
                    <div class="cs-desc" style="max-width: 75%;"><span>Cam kết tiêu dùng tài khoản tối thiểu</span><span
                        class="bold-text">{{ formatNumber(i.CommitValue) || "0" }}đ</span><span
                        class="bold-text">/</span><span class="bold-text">tháng </span>trong <span
                        class="bold-text">{{ i.Months || 36 }} tháng</span></div>
                    <div class="cs-desc_sub">Tiêu dùng tài khoản gồm: đăng ký gói hoặc gọi thoại/SMS/Data</div>
                  </div>
                  <div v-else class="col-12 b2c-camket-text b2c-des-highlight mt-0">Cam kết gói <b style="color: black">{{
                      i.Pack
                    }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ</b> trong
                    <b style="color: black">{{ i.Months }}</b> tháng</b>
                    <p style="margin-top:3px; color: black !important;" v-html="PackInfo[i.Pack]"/>
                  </div>
                </div>
                <td style="font-size:14px;text-align:left;line-height:32px"
                    v-else-if="$route.query.sim_category_id_sort && $route.query.sim_category_id_sort == 49"
                    class="b2c-des-highlight">
                  Gói <b>{{ i.Pack }}</b> - <span v-html="PromotionInfo[i.Pack].flow"></span>
                  <p style="font-size:12px;margin-top:10px" v-html="PromotionInfo[i.Pack].extraText"/>
                </td>
                <td style="font-size:14px;text-align:left;line-height:32px" class="b2c-des-highlight"
                    v-else>Được đăng ký Gói cước
                  <b>{{ i.Pack }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ </b>siêu ưu đãi
                  <p style="font-size:12px;margin-top:5px" v-html="PackInfo[i.Pack]"/>
                </td>
                <div class="row badge-mobile mt-1">
                  <div class="col-8 d-flex align-items-center">
                          <span class="badge badge-primary b2c-badge cursor-pointer mr-1"
                                style="padding: 5px 5px;" @click="handleTypeNumber(i.SimType)">{{
                              i.group_name
                            }}</span>
                    <span @click="handleTypeTags(item)" v-for="(item, index) of i.Tags" :key="index"
                          class="badge badge-primary b2c-badge cursor-pointer mt-1"
                          style="padding: 5px 5px;" :class="index !== i.Tags ? 'mr-1' : ''">{{
                        item.Tag
                      }}</span>
                    <span class="badge badge-primary b2c-badge" @click="handlePledge()"
                          style="padding: 5px 5px;" v-if="i.ThoiGianCamKet > 0">Số cam kết</span>
                    <span class="badge badge-primary b2c-badge" @click="handleSimVip()"
                          style="padding: 5px 5px;" v-if="i.Price > 5000000">SỐ VIP</span>
                  </div>
                  <div class="col-4 text-right" v-if="cart.find(j => j.Phone == i.Phone) == undefined">
                    <button v-if="cart.find(j => j.Phone == i.Phone) == undefined && !isHasSearchCode"
                            @click="addToCart(i)" class="b2c-buy-btn">Mua ngay
                    </button>
                    <button v-if="cart.find(j => j.Phone == i.Phone) == undefined && isHasSearchCode"
                            @click="addToCart(i)" class="b2c-buy-btn">Chọn ngay
                    </button>
                  </div>
                </div>
              </b-card-text>
            </b-card>
            <div class="row">
              <b-col cols="12" v-if="totalRecords>rows.length">
                <b-pagination class="float-right pt-1 pb-3"
                              v-model="serverParams.page"
                              :total-rows="totalRecords"
                              :per-page="serverParams.pageSize"
                              first-number
                              last-number
                              @change="w().scrollTo(0,0)"
                ></b-pagination>
              </b-col>
            </div>
          </div>

        </div>
        <!-- <div class="w-100" style="min-height: 100px;" v-else>
                    <div class="d-flex pb-1 pr-2 pl-2 pt-1">
                        <div class="h1">Không tìm thấy số nào!</div>
                    </div>
                </div> -->
      </div>
      <div class=" fs-pc">
        <div class="b2c-pc-header">
          <h2>CHỌN SỐ CỦA BẠN</h2>
          <div class="nav"></div>
        </div>
<!--        <LuckyNumber @hadleByNow="hadleByNow" :DataPackages="DataPackages"/>-->
        <div class="b2c-pc-find mr-auto ml-auto">
          <p class="leading-required-number">Nhập số thuê bao mong muốn</p>
          <div>
            <div>
              <div class="b2c-pc-number-087">087</div>
              <div class="b2c-pc-number-msisdn">
                <!-- <input class="b2c-pc-number-input"
                                       v-model.trim="SearchString"
                                       placeholder="Nhập *68, 68*, *8*8*"
                                       maxlength="7"
                                       @keydown.enter="handleSearch()"
                                /> -->
                <input-required-phone
                    v-model.trim="SearchString"
                    @onSearch="handleSearch()"
                />
              </div>
              <div class="b2c-number-btn">
                <button @click="handleSearch()"></button>
              </div>
            </div>
            <div :class="numberExceptCollapse?'b2c-pc-number-except':'b2c-pc-number-except-e'">
              <div class="b2c-pc-number-except-text">Loại trừ số:</div>
              <div class="b2c-pc-number-except-buttons">
                <button @click="exception(i-1)" v-for="i in [5,8,1,2,3,4,6,7,9,10]" :key="i"
                        :class="serverParams.columnFilters.except.find(j=>j==i-1)== undefined?'b2c-number-except-btn':'b2c-number-except-btn-checked'">
                  {{ i - 1 }}
                </button>
              </div>
              <button @click="numberExceptCollapse=!numberExceptCollapse"
                      :class="numberExceptCollapse?'b2c-number-except-colapse':'b2c-number-except-nocolapse'"/>
            </div>
            <div :class="'b2c-pc-number-type'">
              <div class="b2c-pc-number-type-text">Danh mục số:</div>
              <!--<select @change="serverParams.page=1" v-model="serverParams.columnFilters.simCategory" class="b2c-number-type-select">
                <option v-for="i in getSimGroups()" :key="i.id" :value="i.id">{{i.text}}</option>
              </select>-->
              <v-select @input="handleCategory" class="w-100 border-0 mr-1 style-select" :searchable="false"
                        :clearable="false" label="text"
                        v-model="serverParams.columnFilters.simCategory" :options="getSimGroups()"
                        :reduce="y => y.Id"
                        placeholder="Tất cả danh mục số"></v-select>
            </div>
          </div>
        </div>
        <SearchForMonth :isSeachMonth="isSeachMonth" :dataSearchMonthly="dataSearchMonthly"
                        @handleSeachMonth="handleSeachMonth"/>
        <div class="b2c-pc-find-sub d-flex pb-1 pt-2">
          <div class="b2c-pagination-title" style="width: max-content;" v-if="isNotFound">!!! Rất tiếc, số bạn đang tìm
            hiện không còn. Có thể bạn sẽ thích những số sau:
          </div>
          <div v-else class="b2c-pagination-title" style="width: max-content;">{{ totalRecords | number }} số hiện có
          </div>
          <select @change="serverParams.page=1" v-model="serverParams.extra.price_sort"
                  class="b2c-pagination-title-sort" style="text-align: left;">
            <option v-for="i in prices" :key="i.id" :value="i.id">{{ i.text }}</option>
          </select>
        </div>
        <div class="b2c-pc-list">
          <div class="row" v-if="rows.length>0">
            <b-col cols="12">
              <table>
                <tr>
                  <th style="text-align:center;width:15%">Số điện thoại</th>
                  <th style="width:20%" class="pl-1">Loại số</th>
                  <!--<th>Gói cước</th>-->
                  <th class="pl-1">Gói cước / Chính sách</th>
                  <th style="text-align:center;width:150px">Giá tiền</th>
                  <th style="width:180px"></th>
                </tr>
                <tr v-for="i in rows" :key="i.Phone"
                    :class="cart.find(j=>j.Phone==i.Phone)!=undefined?'b2c-bg-selected':''">
                  <td style="text-align:center" :product_link="`https://itel.vn/san-pham/${i.Phone}`">
                    <span class="b2c-phone" v-html="i.PhoneFormated"></span>
                  </td>
                  <td style="text-align:left">
                    <!-- <span class="badge badge-primary mr-1 b2c-badge" v-if="i.category_name">{{ i.category_name }}</span> -->
                    <span class="badge badge-primary mr-1 b2c-badge" @click="handleSimVip()"
                          v-if="i.Price > 5000000">SỐ VIP</span>
                    <span class="badge badge-primary mr-1 b2c-badge" v-if="i.ThoiGianCamKet>0"
                          @click="handlePledge()">Số cam kết</span>
                    <span class="badge badge-primary b2c-badge cursor-pointer mr-1"
                          @click="handleTypeNumber(i.SimType)">{{ i.group_name }}</span>
                    <span @click="handleTypeTags(item)" v-for="(item,index) of i.Tags" :key="index"
                          class="badge badge-primary b2c-badge cursor-pointer mt-1"
                          :class="index!== i.Tags ? 'mr-1':''"
                    >{{ item.Tag }}</span>
                  </td>
                  <!--<td>{{i.Pack}}</td>
                  <td v-if="i.ThoiGianCamKet>0">{{i.ThoiGianCamKet}} tháng</td>-->
                  <!-- <td style="font-size:14px;text-align:left" v-if="i.ThoiGianCamKet>0">
                    Cam kết gói <b>{{
                      i.Pack
                    }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price|number }}đ</b> trong {{ i.Months }} tháng<p
                      style="font-size:12px;margin-top:10px"
                      v-html="PackInfo[i.Pack]"/>
                  </td> -->
                  <td class="row" v-if="i.ThoiGianCamKet > 0" style="height: 100%">
                    <div v-if="(i.CommitType == 'PSC')" class="col-12 b2c-camket-text b2c-des-highlight mt-0">
                      <div class="cs-desc"><span>Cam kết tiêu dùng tài khoản tối thiểu </span><span
                          class="bold-text">{{ formatNumber(i.CommitValue) || "0" }}đ</span><span
                          class="bold-text">/</span><span class="bold-text">tháng </span>trong <span class="bold-text">{{
                          i.Months || 36
                        }} tháng</span></div>
                      <div class="cs-desc_sub">Tiêu dùng tài khoản gồm: đăng ký gói hoặc gọi thoại/SMS/Data</div>
                    </div>
                    <div v-else class="col-12 b2c-camket-text b2c-des-highlight mt-0">Cam kết gói <b
                        style="color: black">{{ i.Pack }}
                      {{ DataPackages.find((m) => m.Name == i.Pack).Price | number }}đ</b> trong
                      <b style="color: black">{{ i.Months }}</b> tháng</b>
                      <p style="margin-top:3px; color: black !important;" v-html="PackInfo[i.Pack]"/>
                    </div>
                  </td>
                  <td style="font-size:14px;text-align:left;line-height: 120%;margin-top:10px"
                      v-else-if="$route.query.sim_category_id_sort && $route.query.sim_category_id_sort == 49">
                    Gói <b>{{ i.Pack }}</b> - <span v-html="PromotionInfo[i.Pack].flow"></span>
                    <p style="font-size:12px;margin-top:10px"
                       v-html="PromotionInfo[i.Pack].extraText"/>
                  </td>
                  <td style="font-size:14px;text-align:left;" v-else>
                    Được đăng ký Gói cước <b>{{ i.Pack }}
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price|number }}đ </b>siêu ưu đãi<p
                      style="font-size:12px;margin-top:10px"
                      v-html="PackInfo[i.Pack]"/>
                  </td>
                  <td class="text-center">
                    <span>{{ i.Price + i.SimPrice|number }}đ</span> <br/>
                    <span v-if="(i.Price + i.SimPrice) !=50000 && i.SimType=== 10" class="b2c-ghtk-fee line-through"
                          style="color: #5e5873; font-weight: 400; padding-right: 8px;">50.000đ</span>
                  </td>
                  <td>
                    <button v-if="cart.find(j=>j.Phone==i.Phone)==undefined" @click="addToCart(i)"
                            class="b2c-buy-btn">
                      Mua ngay
                    </button>
                  </td>
                </tr>
              </table>
            </b-col>
            <b-col cols="12" v-if="totalRecords>rows.length">
              <b-pagination class="pt-1 pb-3 float-right"
                            v-model="serverParams.page"
                            :total-rows="totalRecords"
                            :per-page="serverParams.pageSize"
                            first-number
                            last-number
                            @change="w().scrollTo(0,0)"
              ></b-pagination>
            </b-col>
          </div>
          <!-- <div class="w-100" style="min-height: 100px;" v-else>
                        <div class="d-flex pb-1 pr-2 pl-2 pt-1">
                            <div class="h1">Không tìm thấy số nào!</div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
    <div v-else-if="step==2">
      <div class="fs-sp">
        <div class="w-100 b2c-bg">
          <div class="w-100 b2c-bg-top" v-if="!checkIsInIframe">
            <div class="b2c-top-left" @click="goToStep(1,false)"></div>
            <div class="b2c-top-center">Chọn gói cước</div>
            <div class="b2c-top-right"></div>
          </div>
          <div class="b2c-steps">
            <div class="b2c-step2"/>
          </div>
          <div class="b2c-text-solo">
            Bạn đã chọn số
            <h1>{{ f087(currentPhone) }}</h1>
            <p v-if="cart && cart.find(j=>j.Phone==currentPhone) && cart.find(j=>j.Phone==currentPhone).ThoiGianCamKet>0">
              <div v-if="!(cart.find(j => j.Phone == currentPhone).CommitType == 'PSC')" class="text-align-center">Số
                này yêu cầu
                sử dụng gói
                cam kết sau trong vòng <b>{{
                    cart.find(j => j.Phone == currentPhone).ThoiGianCamKet
                  }}</b> tháng
              </div>
              <ul v-if="(cart.find(j => j.Phone == currentPhone).CommitType == 'PSC')" class="cs-buy-info mt-2">
                <li>
                  <div class="text-align-center">Cam kết tiêu dùng tài khoản <span
                      class="bold">tối thiểu {{ formatNumber(cart.find(j => j.Phone == currentPhone).CommitValue) || "0" }}đ/tháng</span>
                    trong <span class="bold">{{ cart.find(j => j.Phone == currentPhone).Months || "36" }} tháng</span>
                  </div>
                  <div class="text-align-center small">Tiêu dùng tài khoản gồm: tiền đăng ký gói hoặc nghe/gọi/SMS</div>
                </li>
                <li>
                  <div class="text-align-center bold" style="margin-top: 5px">Ưu đãi đăng ký gói cước:</div>
                  <div class="text-align-center italic small">Không bắt buộc đăng ký gói trong thời gian cam kết</div>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div class="w-100 b2c-float-bg">
        </div>
        <div class="w-100 pb-2 pr-2 pl-2 pt-0"
             v-if="!isLoading && DataPackages && DataPackages.length > 0 && cart && cart.find(j=>j.Phone==currentPhone)">
          <template v-for="i in DataPackages.filter(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack)">
            <b-card @click="isLoading=true;cart.find(j=>j.Phone==currentPhone).Pack=i.Name;isLoading=false"
                    body-class="p-1" :key="i.Id" class="b2c-package-bg-selected"
                    v-if="cart.find(j=>j.Phone==currentPhone).Pack==i.Name">
              <b-card-text>
                <div class="d-flex border-bottom pb-2">
                  <div class="b2c-package-title-selected">{{ i.Name }}</div>
                  <div class="b2c-package-subtitle-selected" v-if="!isHasSearchCode">
                    <p style="color: #ed1f24;">{{ cart.find(m => m.Phone == currentPhone).PackPrice|number }}đ<span
                        style="font-size: 16px;">/Tháng</span></p>
                    <p class="b2c-ghtk-fee line-through" style="font-size: 15px; font-weight: 400; padding-right: 22px;"
                       v-if="(cart.find(m => m.Phone == currentPhone).PackPrice) != (DataPackages.find(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack).Price) && (cart.find(m => m.Phone == currentPhone).SimType===10)">
                      {{
                        DataPackages.find(p => p.Name == cart.find(j => j.Phone == currentPhone).Pack).Price|number
                      }}đ/Tháng</p>
                  </div>
                  <div class="b2c-package-subtitle-selected mt-2" v-else>
                    <p style="color: #ed1f24;">
                      {{ DataPackages.find(p => p.Name == cart.find(j => j.Phone == currentPhone).Pack).Price|number }}đ<span
                        style="font-size: 16px;">/Tháng</span></p>
                  </div>
                </div>
                <div
                    v-if="(cart.find(m => m.Phone == currentPhone).PackPrice) != (DataPackages.find(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack).Price) && (cart.find(m => m.Phone == currentPhone).SimType===10)"
                    style="font-size: 15px; font-style: italic; margin-top: -25px; text-align: center;">(Ưu đãi giá gói
                  áp dụng cho tháng đầu tiên)
                </div>
                <div class="row">
                  <div class="col-12">
                    <div v-html="i.Brief" class="w-100 b2c-package-brief-selected"
                         style="padding:6px !important;line-height:1.5;margin-bottom:10px"></div>
                  </div>
                </div>
                <!--<div class="row border-top mt-1">
                  <div class="col-12 pt-2 b2c-agree-text">
                    <span class="float-left">
                      <b-form-checkbox name="vip" v-model="cart.find(j=>j.Phone==currentPhone).eSIM">Sử dụng ESIM</b-form-checkbox>
                    </span>
                  </div>
                </div>-->
              </b-card-text>
            </b-card>
            <b-card @click="isLoading=true;cart.find(j=>j.Phone==currentPhone).Pack=i.Name;isLoading=false"
                    body-class="p-1" :key="i.Id" v-else>
              <b-card-text>
                <div class="b2c-package-title">{{ i.Name }}</div>
                <div class="b2c-package-subtitle">{{ i.Price|number }}đ</div>
                <div v-html="i.Brief" class="w-100 b2c-package-brief"
                     style="padding:6px !important;line-height:1.5;margin-bottom:10px"></div>
              </b-card-text>
            </b-card>
          </template>
          <!--<b-row>
            <b-col cols="12" class="p-3" v-if="cart.find(j=>j.Phone==currentPhone).ThoiGianCamKet>0">
              <div class="border rounded bg-white p-3">
                <b-form-checkbox :value="true" v-model="cart_info.month_agree">
                  Tôi đồng ý với <a href="https://itel.vn/tin-tuc/tin-dich-vu/cac-thong-tin-khach-hang-can-luu-y-ve-sim-cam-ket" class="text-primary" target="_blank">chính sách sử dụng</a> SIM cam kết của iTEL
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>-->
          <div class="text-center text_lh">
            <feather-icon icon="AlertCircleIcon" class="text-primary"/>
            Hệ thống không giữ số thuê bao cho đến khi Quý Khách hoàn tất thanh toán
          </div>
          <button @click="mapSim" class="b2c-search-button pt-0 b2c-search-button-red" v-if="isHasSearchCode"
                  :class="isHasSearchCode?'mb-1':''">{{ cart[0].Price > 0 ? 'Thanh toán' : 'Kế tiếp' }}
          </button>
          <button @click="goToStep(3)" class="b2c-search-button pt-0 b2c-search-button-red" v-else
                  :class="isHasSearchCode?'mb-1':''">Kế tiếp
          </button>
          <button @click="$router.go(-1)" v-if="isHasSearchCode"
                  class="mt-1 b2c-search-button pt-0 b2c-search-button-secondary">Quay lại
          </button>
        </div>
      </div>
      <div class="fs-pc">
        <div class="b2c-pc-header">
          <h2><a @click="goToStep(1,false)">
            <feather-icon size="24" icon="ChevronLeftIcon"/>
          </a> CHỌN GÓI CƯỚC
          </h2>
          <div class="nav1"></div>
        </div>
        <div class="b2c-pc-pack-content">
          <div class="col-12">
            <div class="package">
              <h4>Bạn đã chọn số</h4>
              <h2>{{ f087(currentPhone) }}</h2>
              <p class="text1"
                 v-if="cart && cart.find(j=>j.Phone==currentPhone) && cart.find(j=>j.Phone==currentPhone).ThoiGianCamKet>0">
                <div v-if="!(cart.find(j => j.Phone == currentPhone).CommitType == 'PSC')" class="text-align-center">Số
                  này yêu cầu
                  sử dụng gói
                  cam kết sau trong vòng <b>{{
                      cart.find(j => j.Phone == currentPhone).ThoiGianCamKet
                    }}</b> tháng
                </div>
                <div v-if="cart.find(j => j.Phone == currentPhone).CommitType == 'PSC'" class="text-align-center">Cam
                  kết tiêu dùng tài khoản tối thiểu <span
                      class="bold">{{ formatNumber(cart.find(j => j.Phone == currentPhone).CommitValue) || "0" }}đ/tháng</span>
                  trong <span class="bold">{{ cart.find(j => j.Phone == currentPhone).Months || "36" }} tháng</span>
                </div>
                <div v-if="cart.find(j => j.Phone == currentPhone).CommitType == 'PSC'" class="text-align-center">Tiêu
                  dùng tài khoản gồm: tiền đăng ký gói hoặc nghe/gọi/SMS
                </div>
                <div v-if="cart.find(j => j.Phone == currentPhone).CommitType == 'PSC'" class="text-align-center bold"
                     style="margin-top: 5px">Ưu đãi đăng ký gói cước:
                </div>
                <div v-if="cart.find(j => j.Phone == currentPhone).CommitType == 'PSC'"
                     class="text-align-center italic">Không bắt buộc đăng ký gói trong thời gian cam kết
                </div>
              </p>
              <div class="subpackage"
                   v-if="cart && cart.find(m => m.Phone == currentPhone) && DataPackages && DataPackages.length > 0">
                <div class="sub-header" style="padding-bottom: 9rem;">
                  <h2>{{ cart.find(m => m.Phone == currentPhone).Pack }}</h2>
                  <h4>
                    <p style="color: #ed1f24;">{{ cart.find(m => m.Phone == currentPhone).PackPrice|number }}đ<span
                        style="font-size: 16px;">/Tháng</span></p>
                    <p class="b2c-ghtk-fee line-through" style="font-size: 15px; padding-right: 22px; font-weight: 400;"
                       v-if="(cart.find(m => m.Phone == currentPhone).PackPrice) != (DataPackages.find(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack).Price) && (cart.find(m => m.Phone == currentPhone).SimType===10)">
                      {{
                        DataPackages.find(p => p.Name == cart.find(j => j.Phone == currentPhone).Pack).Price|number
                      }}đ/Tháng
                    </p>
                  </h4>
                </div>
                <div
                    v-if="(cart.find(m => m.Phone == currentPhone).PackPrice) != (DataPackages.find(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack).Price) && (cart.find(m => m.Phone == currentPhone).SimType===10)"
                    style="font-size: 15px; font-style: italic; margin-top: -20px; text-align: center;">(Ưu đãi giá gói
                  áp dụng cho tháng đầu tiên)
                </div>
                <div class="row">
                  <div class="col-12">
                    <div v-html="DataPackages.find(p=>p.Name==cart.find(j=>j.Phone==currentPhone).Pack).Brief"
                         class="w-100 b2c-package-brief-selected"
                         style="padding:6px !important;line-height:1.5"></div>
                  </div>
                </div>
                <!--<div class="row border-top mt-1">
                  <div class="col-12 pt-2 b2c-agree-text pt-2 pl-3 pb-2">
                    <span class="float-left">
                      <b-form-checkbox name="vip" v-model="cart.find(j=>j.Phone==currentPhone).eSIM">Sử dụng ESIM</b-form-checkbox>
                    </span>
                  </div>
                </div>-->
              </div>
            </div>
            <b-row>
              <!--<b-col cols="12" class="p-3" v-if="cart.find(j=>j.Phone==currentPhone).ThoiGianCamKet>0">
                <div class="border rounded bg-white p-3 b2c-pc-agree">
                  <b-form-checkbox :value="true" v-model="cart_info.month_agree">
                    Tôi đồng ý với <a href="https://itel.vn/tin-tuc/tin-dich-vu/cac-thong-tin-khach-hang-can-luu-y-ve-sim-cam-ket" class="text-primary" target="_blank">chính sách sử dụng</a> SIM cam kết của iTEL
                  </b-form-checkbox>
                </div>
              </b-col>-->
              <b-col cols="12" class="text-center">
                <div class="text-center text_lh pb-1">
                  <feather-icon icon="AlertCircleIcon" class="text-primary"/>
                  Hệ thống không giữ số thuê bao cho đến khi Quý Khách hoàn tất thanh toán
                </div>
                <button @click="goToStep(3)"
                        class="b2c-search-button b2c-search-button-red pc-step2-button">Kế tiếp
                </button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="step==3" class="b2c-o-h">
      <validation-observer ref="infoValid">
        <div class="fs-sp">
          <div class="w-100 b2c-bg fs-sp">
            <div class="w-100 b2c-bg-top" v-if="!checkIsInIframe">
              <div class="b2c-top-left" @click="goToStep(2)"></div>
              <div class="b2c-top-center">Giỏ hàng & thanh toán</div>
              <div class="b2c-top-right"></div>
            </div>
            <div class="b2c-steps">
              <div class="b2c-step3"/>
            </div>
            <div class="b2c-text-solo">
              Thông tin đơn hàng
            </div>
          </div>
          <div class="w-100 b2c-float-bg">
          </div>
          <div class="w-100 pb-2 pr-2 pl-2 pt-0" v-if="!isLoading">
            <b-card v-for="i in cart" :key="i.Phone" body-class="p-1" class="b2c-cart-bg">
              <div class="b2c-cart-price">GÓI<br><span>{{ i.Pack }}</span></div>
              <div class="row">
                <div class="col-12 b2c-page-msisdn border-bottom" v-if="isHasSearchCode">
                  {{ f087(i.Phone) }}
                  <p class="b2c-price-texts pt-1">Giá số: <b
                      style="color: #ED1F24; padding-left: 17px;">{{ i.Price|number }}đ</b></p>
                </div>
                <div class="col-12 b2c-page-msisdn border-bottom" v-else>
                  {{ f087(i.Phone) }}
                  <p class="b2c-price-texts pt-1">Giá số: <b
                      style="color: #ED1F24; padding-left: 17px;">{{ i.Price|number }}đ</b></p>
                  <p class="b2c-price-texts" v-if="i.eSIM">Giá eSIM: <b style="color: #ED1F24;">{{
                      i.EsimPrice|number
                    }}đ</b>
                  </p>
                  <p class="b2c-price-texts" v-else>Giá SIM: <b
                      style="color: #ED1F24; padding-left: 8px;">{{ i.SimPrice|number }}đ</b></p>
                  <p class="b2c-ghtk-fee b2c-price-texts line-through"
                     v-if="(i.SimPrice !=50000 && i.SimType===10) || i.isBeautifulSimNumber"
                     style="font-size: 12px; color: #5e5873; padding-left: 66px;">
                    {{ (i.isBeautifulSimNumber ? (i.Price + i.SimPrice + 160000) : 50000) | number }}đ</p>
                  <p class="b2c-price-texts">Giá gói: <b
                      style="color: #ED1F24; padding-left: 13px;">{{ i.PackPrice |number }}đ/tháng</b>
                    <span v-if="i.CommitType == 'PSC' || (i.CommitType == null && i.SimType == 10)"
                          style="color: #ED1F24; font-size: 12px;"> (*)</span>
                  </p>
                  <p class="b2c-ghtk-fee b2c-price-texts line-through"
                     v-if="i.PackPrice != DataPackages.find((m) => m.Name == i.Pack).Price"
                     style="font-size: 12px; color: #5e5873; padding-left: 66px;">
                    {{ DataPackages.find((m) => m.Name == i.Pack).Price|number }}đ /
                    tháng </p>
                  <p class="b2c-price-texts mb-1" style="font-style: italic;"
                     v-if="i.PackPrice != DataPackages.find((m) => m.Name == i.Pack).Price">(Ưu đãi giá gói áp dụng cho
                    tháng đầu tiên)</p>
                  <p class="b2c-note-price">
                    (*) Khi mua SIM đã bao gồm gói cước tháng đầu
                  </p>
                </div>
                <div class="col-12 pt-2 b2c-agree-text" v-if="!isHasSearchCode">
                  <p class="font-medium-5">Loại hình SIM (lựa chọn loại SIM tương thích với máy)</p>
                  <div class="d-flex mt-1">
                    <div class="pick-sim-type" @click="()=>{i.eSIM=false}">
                      <b-row class="justify-content-between">
                        <p class="font-medium-3 font-weight-bold ml-2">Sim vật lý</p>
                        <img v-if="!i.eSIM" alt="checkbox" style="width: 20px; height: 20px"
                             src="../assets/images/check-circle-red.png"/>
                        <img v-else alt="checkbox" style="width: 20px; height: 20px"
                             src="../assets/images/circleblank.png"/>
                      </b-row>
                      <img alt="checkbox" class="w-100 h-75"
                           src="../assets/images/b2c/SIM-icon-1.png"/>

                    </div>
                    <div class="pick-sim-type ml-2" @click="()=>{i.eSIM=true;changeESIM()}"
                         v-if="paymentMethod != 'COD'">
                      <b-row class="justify-content-between">
                        <p class="font-medium-3 font-weight-bold ml-2">eSIM</p>
                        <img v-if="i.eSIM " alt="checkbox" style="width: 20px; height: 20px"
                             src="../assets/images/check-circle-red.png"/>
                        <img v-else alt="checkbox" style="width: 20px; height: 20px"
                             src="../assets/images/circleblank.png"/>
                      </b-row>
                      <img alt="checkbox" class="esim-icon"
                           src="../assets/images/b2c/eSIM-icon-2.png"/>
                    </div>
                  </div>
                  <!--                    <b-form-checkbox name="vip" @change="changeESIM" v-model="i.eSIM">Sử dụng eSIM<br><small-->
                  <!--                        class="text-primary font-small-3">eSIM là SIM điện tử, được gửi về email của khách hàng <br/>Chỉ dùng cho các dòng máy hỗ trợ eSIM.</small></b-form-checkbox>-->
                  <span class="float-right" v-if="cart.length>1"><button class="b2c-cart-bg-del"
                                                                         @click="isLoading=true;cartRemoveItem(i);isLoading=false; getMGMDiscount()">Xoá</button></span>
                </div>
              </div>
            </b-card>
            <b-card class="b2c-cart-esim-text p-1">
              <b-card-text>
                      <span><b>Lưu ý:</b><span class="text-primary font-italic"> eSIM là SIM điện tử, được gửi về email của khách hàng <br/>Chỉ
                        dùng cho các dòng máy hỗ trợ eSIM.</span></span>
              </b-card-text>
            </b-card>
            <b-card @click="goToStep(1,true)" class="b2c-cart-add-more" v-if="!isHasSearchCode">
              <b-card-text>
                <a @click="goToStep(1,true)">Chọn thêm số</a>
                <span class="float-right"><feather-icon size="24" icon="PlusSquareIcon"/></span>
              </b-card-text>
            </b-card>
          </div>
          <div class="w-100 b2c-cart-info-title" v-if="!isHasSearchCode">Thông tin giỏ hàng</div>
          <!--<validation-observer ref="infoValid">-->
          <div class="row p-2" v-if="!isHasSearchCode">
            <div class="col-md-6">
              <validation-provider #default="{ errors }" rules="required|min:3">
                <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                <b-form-input size="sm1" maxlength="35" v-model="cart_info.fullName" autocomplete="name" name="name"
                              id="fullName"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập đầy đủ họ và tên người
                  nhận</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider #default="{ errors }" rules="required|min:10">
                <label class="d-block">Điện thoại liên hệ <span class="text-primary">*</span></label>
                <b-form-input type="text" autocomplete="phone" name="phone" id="phone"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                              maxlength="10" size="sm1" v-model.trim="cart_info.ContactPhone"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập số điện thoại liên
                  hệ</small>
              </validation-provider>
            </div>
            <div class="col-md-12" v-if="cart.find(i=>i.eSIM)!=undefined">
              <validation-provider #default="{ errors }" rules="required|email">
                <label class="d-block">Email để nhận eSIM <span class="text-primary">*</span></label>
                <b-form-input size="sm1" maxlength="40" v-model.trim="cart_info.email" autocomplete="email" name="email"
                              id="email"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập chính xác email để nhận
                  thông tin
                  eSIM</small>
              </validation-provider>
            </div>
          </div>
          <div class="w-100 b2c-cart-info-title border-top b2c-card-header" v-if="cart.find(i => !i.eSIM) != undefined">
            Lựa chọn hình thức giao hàng
          </div>
          <div class="p-2 cursor-pointer bg-white" v-if="cart.find(i => !i.eSIM) != undefined">
            <div class="d-flex align-baseline" @click="onShipmentTabChange(0)">
              <div class="check-box mr-3">
                <img v-if="activeTab===0" alt="checkbox"
                     src="../assets/images/check-circle-red.png"/>
                <img v-else alt="checkbox"
                     src="../assets/images/circleblank.png"/>
              </div>
              <span class="card-header-title">
                          Giao hàng tận nơi
                        </span>
            </div>
            <div class="mt-2 d-flex align-baseline" @click="onShipmentTabChange(1)">
              <div class="check-box mr-3">
                <img v-if="activeTab===1" alt="checkbox"
                     src="../assets/images/check-circle-red.png"/>
                <img v-else alt="checkbox"
                     src="../assets/images/circleblank.png"/>
              </div>
              <span class="card-header-title">
                          Nhận tại phòng giao dịch iTel
                        </span>
            </div>
          </div>
          <div v-if="cart.find(i => !i.eSIM) != undefined" class="bg-white">
            <div v-if="activeTab===0">
              <!--<div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title">Giao hàng tận nơi
                                <span class="float-right">
                                <b-form-checkbox @change="change_shipment('home')" name="shipment_type" value="home" v-model="cart_info.shipment_type"></b-form-checkbox>
                              </span>
                            </div> -->
              <div class="row p-1"
                   v-if="!isLoading && cart_info.shipment_type == 'home' && cart.find(i => !i.eSIM) != undefined">
                <!-- <div class="col-md-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider rules="">
                    <label class="d-block">Thời gian<span class="text-primary">*</span></label>
                    <v-select
                        class="select-delivery-time"
                        label="text"
                        v-model="cart_info.deliver_work_ship"
                        :filter="fuseSearch"
                        :options="deliveryTimes"
                        :reduce="option => option.id"
                        placeholder="Chọn thời gian giao hàng"
                    />
                  </validation-provider>
                </div> -->
                <div class="col-md-12" v-if="cart.find(i => !i.eSIM) != undefined">
                  <validation-provider #default="{ errors }"
                                       rules="required|min:2">
                    <label class="d-block">Nhập địa chỉ <span
                        class="text-primary">*</span></label>
                    <b-form-input size="sm1"
                                  placeholder="Số nhà, tên đường, tên thôn/ấp/khu"
                                  v-model="cart_info.addr"
                                  :state="errors.length > 0 ? false : null"/>
                    <small class="fs-error-txt" v-if="errors.length > 0">Vui
                      lòng điền chi tiết số nhà, tên
                      đường,
                      thôn/ấp/khu</small>

                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                  <validation-provider #default="{ errors }"
                                       rules="required|min0">
                    <label class="d-block">Tỉnh/TP <span
                        class="text-primary">*</span></label>
                    <v-select @input="onCityChange" label="text"
                              v-model="CityId" :filter="fuseSearch"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              :options="Cities"
                              :reduce="CityId => CityId.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length > 0">Vui
                      lòng nhập Tỉnh/TP</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                  <validation-provider #default="{ errors }"
                                       rules="required|min0">
                    <label class="d-block">Quận/Huyện <span
                        class="text-primary">*</span></label>
                    <v-select :disabled="!(CityId > 0)" :filter="fuseSearch"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              ref="districtSelect1"
                              label="text" v-model="DistrictId"
                              :options="Districts"
                              :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length > 0">Vui
                      lòng nhập Quận/Huyện</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                  <validation-provider #default="{ errors }"
                                       rules="required|min0">
                    <label class="d-block">Phường/Xã <span class="text-primary">*</span></label>
                    <v-select :disabled="!(DistrictId > 0)" :filter="fuseSearch"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              ref="WardsSelect1"
                              label="text" v-model="WardId"
                              :options="Wards"
                              :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length > 0">Vui
                      lòng nhập Phường/Xã</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                  <validation-provider #default="{ errors }" rules="">
                    <label class="d-block">Ấp/Khu</label>
                    <v-select :disabled="!(WardId > 0)" :filter="fuseSearch"
                              label="text" v-model="HamletId"
                              :options="Hamlets" :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length > 0">Vui
                      lòng nhập Ấp/Khu</small>
                  </validation-provider>
                </div>
                <div class="col-12 mt-1">
                  <div
                      :class="[(CityId > 0) && cart.find(i => !i.eSIM) != undefined && hasExpressShip && paymentMethod != 'COD' && framePaymentMethods!=1 ? '' : 'disabled', ' d-flex delivery-type']"
                      v-if="(CityId > 0)">
                    <div class="check-box mr-3" @click="onClickExpress()"
                         v-if="cart.find(i => !i.eSIM) != undefined && hasExpressShip && paymentMethod != 'COD'">
                      <img v-if="isExpress" alt="checkbox"
                           src="../assets/images/check-circle-red.png"/>
                      <img v-else alt="checkbox"
                           src="../assets/images/circleblank.png"/>
                    </div>
                    <div class="check-box mr-3" v-else>
                      <img alt="checkbox"
                           src="../assets/images/circleblank.png"/>
                    </div>
                    <div class="info-delivery">
                      <div class="info-delivery__title">Giao hàng hỏa tốc
                        <span
                            class="info-delivery--subtile ">(Không
                                                áp dụng với hình thức thanh toán COD) <br/> <i
                              v-if="!hasExpressShip && !paymentMethod != 'COD' && !cart.find(i => !i.eSIM) != undefined"> Chưa
                                                  hỗ trợ tại tỉnh thành đang chọn </i> </span>
                      </div>
                      <div class="info-delivery__sub-title" disabled
                           v-if="cart.find(i => !i.eSIM) != undefined && paymentMethod != 'COD' &&isShowExpressText">
                        {{ cart_info.delivery_time }}
                      </div>
                    </div>

                  </div>
                  <div class="border-bt"></div>
                </div>
                <div class="col-12" v-if="cart.find(i => !i.eSIM) != undefined">
                  <div class="d-flex delivery-type">
                    <div class="check-box mr-3" @click="onClickFast()">
                      <img v-if="isFast" alt="checkbox"
                           src="../assets/images/check-circle-red.png"/>
                      <img v-else alt="checkbox"
                           src="../assets/images/circleblank.png"/>
                    </div>
                    <div class="info-delivery">
                      <div class="info-delivery__title">Giao hàng tiêu chuẩn</div>
                      <div class="info-delivery__sub-title">Nhận hàng từ 1 tới
                        3 ngày kể từ thời điểm đặt đơn
                        hàng
                      </div>
                    </div>

                  </div>
                  <div class="border-bt"></div>
                </div>
              </div>
            </div>
            <div v-else>
              <!--<div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title border">Nhận tại phòng giao dịch iTel
                                <span class="float-right">
                                  <b-form-checkbox @change="change_shipment('itel')" name="shipment_type" value="itel" v-model="cart_info.shipment_type"></b-form-checkbox>
                                </span>
                            </div> -->
              <b-row v-if="cart.find(i => !i.eSIM) != undefined">
                <div class="col-12 p-1">
                  <div @click="storeCollapse = !storeCollapse"
                       :class="storeCollapse ? 'b2c-store-colapse' : 'b2c-store-nocolapse'"
                       class="border d-block bg-white p-2">
                                          <span v-if="!isLoading && cart_info.StoreId > 0">{{
                                              Stores.find(m => m.Id == cart_info.StoreId).Name
                                            }}</span>
                    <span v-else>Chọn chi nhánh</span>
                  </div>
                </div>
              </b-row>
              <div v-if="!isLoading && !storeCollapse && cart.find(i => !i.eSIM) != undefined">
                <div v-on:click="selectStore(i)"
                     class=" ml-1 mr-1 p-2 bg-white border d-flex align-items-center" v-for="i in Stores"
                     :key="i.Id">
                  <b-form-checkbox class="vs-checkbox-con" :value="i.Id" v-model="cart_info.StoreId"/>
                  <div>
                    <h2 class="h2 pt-1">{{ i.Name }}</h2>
                    <p class="text">Giờ làm việc: thứ 2 - thứ 6 (trừ ngày lễ,
                      Tết)</p>
                    <p class="text">Sáng: 8h-12h, Chiều: 13h-18h (thứ 6 làm việc đến
                      17h)</p>
                    <p class="pt-1 text text-primary pb-1"><a target="_blank"
                                                              class="text text-primary"
                                                              :href="'http://maps.google.com/maps/dir/?api=1&destination=' + i.Lat + ',' + i.Long + '&travelmode=driving'"
                                                              :title="$t('Common.Direct')">
                      <feather-icon icon="MapPinIcon"/>
                      {{ $t('Common.Direct') }}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--</validation-observer>-->

          <!--<validation-observer  class="w-100" ref="addrValid">-->

          <!--</validation-observer>-->

          <!-- <b-row>
          <b-col cols="12" class="p-3">
            <div class="border rounded bg-white p-3">
              <b-form-checkbox v-if="cart.find(i=>i.Months>0)!=undefined" :value="true" v-model="cart_info.agree">
                Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">điều khoản và điều kiện giao dịch chung</a> của iTel
              </b-form-checkbox>
              <b-form-checkbox v-else :value="true" v-model="cart_info.agree">
                Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">chính sách mua SIM số</a> của iTel
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row> -->
          <div class="w-100 b2c-cart-info-title b2c-card-header border-top" v-if="!isOnIframe">
            <span>Chọn hình thức thanh toán</span>
          </div>
          <template v-if="!isOnIframe">
            <b-row>
              <b-col cols="12" class="p-1">
                <div class="bg-white p-1">
                  <div class="payment-method-container"
                       v-if="!isLoading && cart.find(i => !i.eSIM)!= undefined && paymentMethodStatus.COD && !isHasSearchCode"
                       @click="ChangePaymentMethod('COD')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="COD" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText"> Thanh toán khi nhận hàng (COD)</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/COD.svg')" alt="logoCOD">
                      </div>
                    </div>
                    <div v-show="paymentMethod === 'COD' && activeTab === 1" xss=removed>
                      <p>(Quý Khách vui lòng đến PGD đã chọn để nhận hàng. iTel sẽ
                        giữ đơn hàng của Quý Khách
                        trong vòng 48h, sau thời điểm trên đơn hàng sẽ bị
                        hủy.)</p>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.MOMO && getSource != 'MY ITEL'"
                       @click="ChangePaymentMethod('MOMO')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="MOMO" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Thanh toán Ví Momo</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/MOMO.png')" alt="logoCOD">
                      </div>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.VIETQR"
                       @click="ChangePaymentMethod('VIETQR')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="VIETQR" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Chuyển khoản ngân hàng</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/VIETQR.png')" alt="logoVIETQR">
                      </div>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_VNPAYQR"
                       @click="ChangePaymentMethod('VNPAY')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="VNPAY" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Thanh toán qua VNPAY</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/VNPAY.png')" alt="logoVNPAY">
                      </div>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.ZALOPAY && getSource != 'MY ITEL'"
                       @click="ChangePaymentMethod('ZALOPAY')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="ZALOPAY" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Thanh toán Ví ZaloPay</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/ZALO.png')" alt="logoZALOPAY">
                      </div>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_VNBANK"
                       @click="ChangePaymentMethod('BANK')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="BANK" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Thẻ ATM (có Internet Banking)</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/COD.svg')" alt="logoBANK">
                      </div>
                    </div>
                  </div>
                  <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_INTCARD"
                       @click="ChangePaymentMethod('INTCARD')">
                    <div class="payment-method-content">
                      <div class="payment-method-content-text">
                        <b-form-checkbox value="INTCARD" v-model="paymentMethod" class="vs-checkbox-con" disabled/>
                        <span class="inputText">Thẻ thanh toán quốc tế</span>
                      </div>
                      <div class="payment-method-content-image">
                        <img :src="require('@/assets/images/b2c/payment/INTCARD.png')" alt="logoINTCARD">
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
<!--          <div class="w-100 b2c-cart-info-title border-top b2c-card-header">-->
<!--            <span>Mã giới thiệu/ ưu đãi</span>-->
<!--          </div>-->
<!--          <b-row class="border-top pt-2 pl-3 pr-3 bg-white">-->
<!--            <b-col cols="12">-->
<!--              <div class="d-flex mb-1">-->
<!--                <b-form-input size="sm1" placeholder="Nhập mã ưu đãi hoặc mã giới thiệu"-->
<!--                              style="width: 85%;"-->
<!--                              v-model="promotionCode"-->
<!--                              @update="handlePromotionCode()"/>-->
<!--                <b-button :disabled="promotionCode === null || promotionCode === ''"-->
<!--                          @click="getMGMDiscount()" class="b2c__btn"-->
<!--                          :style="promotionCode === null || promotionCode === '' ? '' : 'background: #ed1f24 !important;'">-->
<!--                  Áp dụng-->
<!--                </b-button>-->
<!--              </div>-->
<!--              <b-row v-if="!isStatusPromotionCode && promotionCode.length>0">-->
<!--                <b-col class="text-primary " style="font-size: 14px">-->
<!--                  Ấn "Áp dụng" để được hưởng ưu đãi-->
<!--                </b-col>-->
<!--              </b-row>-->
<!--            </b-col>-->
<!--            <b-col cols="12" v-if="isStatusPromotionCode">-->
<!--              <p v-if="isMGMSuccess" style="color: blue; font-size: 16px;">-->
<!--                Mã giới thiệu hợp lệ! iTel sẽ tặng ngay {{ totalSuccessTopup | number }}đ vào TKC cho số TB-->
<!--                0{{ mgmPhone }} khi Bạn hoàn thành thanh toán và kích-->
<!--                hoạt SIM thành công.-->
<!--              </p>-->
<!--              <p v-else>-->
<!--                <span v-if="SuccessMessage.length<=0" style="color: blue; font-size: 16px;">Áp dụng mã khuyến mại-->
<!--                  thành công!</span>-->
<!--                <span v-if="SuccessMessage.length>0" style="color: blue; font-size: 16px;">{{-->
<!--                    SuccessMessage-->
<!--                  }}</span>-->
<!--              </p>-->
<!--            </b-col>-->
<!--          </b-row>-->
          <div class="w-100 b2c-cart-info-title border-top b2c-card-header">
            <span>Chi tiết thanh toán</span>
          </div>
          <b-row class="border-top pt-2 pl-3 pr-3 bg-white payment-info">
            <b-col cols="12" class="h2">
              <div class="d-flex mb-1 align-items-center"
                   v-if="isOnIframe && frameType==='TeleSale'">
                <p style="width: 70%">Hình thức thanh toán</p>
                <v-select style="width: 100%;" label="text"
                          :searchable=false
                          v-model="framePaymentMethods"
                          :options="framePaymentMethodsList"
                          @input="onFramePaymentMethodChange()"
                          :reduce="c => c.id"
                ></v-select>
              </div>
            </b-col>
            <!--Tổng tiền -->
            <b-col cols="6" class="h2">Tổng tiền hàng</b-col>
            <b-col cols="6" class="h2 text-right">
              {{ totalPriceBeforeDiscount |number }}đ
            </b-col>
            <!--Phí vận chuyển-->
            <b-col cols="6" class="h2">Tổng tiền phí vận chuyển:</b-col>
            <b-col cols="6" class="h2 text-right">
                        <span
                            v-if="cart_info.ghtk_fee!=cart_info.fee || (!isFullEsim() && cart_info.shipment_type == 'home')"
                        >
                          {{ (!isFullEsim() && cart_info.shipment_type == 'home') ? formatNumber(cart_info.fee) : 0 }}đ
                        </span>
              <span v-else>0đ</span>
            </b-col>
            <!--Itel KM-->
            <b-col cols="6" class="h2" v-if="totalDiscountSim>0">
              {{ cart[0].isBeautifulSimNumber ? 'Giảm giá sim số đẹp giá sốc:' : 'iTel khuyến mãi:' }}
            </b-col>
            <b-col cols="6" class="h2 text-right" v-if="totalDiscountSim>0">
              -{{ totalDiscountSim | number }}đ
            </b-col>
            <!--Trừ tiền Ship-->
            <b-col cols="6" class="h2" v-if="activeTab === 0">Giảm giá phí vận chuyển:</b-col>
            <b-col cols="6" class="h2 text-right" v-if="activeTab === 0">
              <span v-if="!isFullEsim()">-{{ formatNumber(cart_info.fee - cart_info.ghtk_fee) }}đ</span>
              <span v-if="isFullEsim()">0đ</span>
            </b-col>
            <!--Trừ tiền MGT-->
            <b-col cols="6" class="h2" v-if="isMGMSuccess">Mã giới thiệu:</b-col>
            <b-col cols="6" class="h2 text-right" v-if="isMGMSuccess">
              -{{ totalDiscountPromotion|number }}đ
            </b-col>
            <!--Trừ tiền MGG-->
            <b-col cols="6" class="h2" v-if="!isMGMSuccess &&isStatusPromotionCode">Mã giảm giá:</b-col>
            <b-col cols="6" class="h2 text-right" v-if="!isMGMSuccess &&isStatusPromotionCode">
              -{{ totalSalePrice | number }}đ
            </b-col>
            <!--Tổng tiền sau giảm-->
            <b-col cols="6" class="h1 pt-1 d-flex align-items-center">Tổng thanh toán:</b-col>
            <b-col cols="6" class="huge-text pt-1 text-primary text-right mb-0">
              {{ getTotalPrice() - totalSalePrice - totalDiscountPromotion|number }}đ
            </b-col>
          </b-row>
          <button v-if="!isOnIframe" :disabled=isDisablePayment @click="onPayment()"
                  class="b2c-search-button b2c-search-button-red">
            {{ payment_text }}
          </button>
          <button v-if="isOnIframe" @click="$bvModal.show('modal-preview-order')"
                  class="b2c-search-button b2c-search-button-red">Tạo đơn
          </button>
          <b-row>
            <div class="pl-4 pr-4 w-100">
              <div class="d-block text-center pb-3">
                Bằng cách bấm thanh toán, bạn đồng ý với <a
                  href="https://itel.vn/thong-tin/dieu-kien-&-dieu-khoan-giao-dich-chung"
                  class="text-primary text-decoration-underline" target="_blank">điều khoản và điều
                kiện giao dịch
                chung</a>
                của iTel
              </div>
            </div>
          </b-row>
        </div>
        <div class="fs-pc">
          <div class="b2c-pc-header">
            <h2><a @click="goToStep(2)">
              <feather-icon size="24" icon="ChevronLeftIcon"/>
            </a> GIỎ HÀNG & THANH TOÁN
            </h2>
            <div class="nav2"></div>
          </div>
          <div class="b2c-pc-pack-content">
            <div class="row">
              <div class="col-6 b2c-pc-payment">
                <div class="package">
                  <h4>Thông tin đơn hàng</h4>
                  <b-card v-for="i in cart" :key="i.Phone" body-class="p-0"
                          class="b2c-cart-bg mt-2 p-3">
                    <div class="b2c-cart-price">GÓI<br><span>{{ i.Pack }}</span></div>
                    <div class="row">
                      <div class="col-12 b2c-page-msisdn border-bottom">
                        {{ f087(i.Phone) }}
                        <p class="b2c-price-texts pt-1">Giá SIM số:
                          <b style="color: #ED1F24; padding-left: 18px;">{{ i.Price + i.SimPrice | number }}đ</b></p>
                        <p class="b2c-price-texts line-through mb-1"
                           v-if="((i.Price + i.SimPrice) !=50000 && i.SimType===10) || i.isBeautifulSimNumber"
                           style="padding-left: 95px; font-size: 12px; color: #5e5873;">
                          {{ (i.isBeautifulSimNumber ? (i.Price + i.SimPrice + 160000) : 50000) | number }}đ</p>
                        <p class="b2c-price-texts">Giá gói:
                          <b style="color: #ED1F24; padding-left: 42px;">{{ i.PackPrice | number }}đ/tháng</b>
                          <span v-if="i.CommitType == 'PSC' || (i.CommitType == null && i.SimType == 10)"
                                style="color: #ED1F24; font-size: 12px;"> (*)</span>
                        </p>
                        <p class="b2c-price-texts line-through"
                           v-if="i.PackPrice !=DataPackages.find((m) => m.Name == i.Pack).Price"
                           style="padding-left: 95px; font-size: 12px; color: #5e5873;">{{
                            DataPackages.find((m) => m.Name == i.Pack).Price | number
                          }}đ/tháng</p>
                        <p class="b2c-price-texts mb-1" style="font-style: italic;"
                           v-if="i.PackPrice !=DataPackages.find((m) => m.Name == i.Pack).Price">(Ưu đãi giá gói áp dụng
                          cho tháng đầu tiên)</p>

                        <p class="b2c-price-texts" v-if="i.eSIM">Phụ phí eSIM:
                          <b style="color: #ED1F24;">{{ i.EsimPrice - i.SimPrice | number }}đ</b>
                        </p>
                        <p class="b2c-note-price">
                          (*) Khi mua SIM đã bao gồm gói cước tháng đầu
                        </p>
                        <!--<p class="b2c-price-texts" v-else>Giá SIM: <b>{{i.SimPrice|number}}đ</b></p>-->
                      </div>
                      <div class="col-12 pt-2 b2c-agree-text">
                        <p class="font-medium-5">Loại hình SIM (lựa chọn loại SIM tương thích với máy)</p>
                        <div class="d-flex mt-1">
                          <div class="pick-sim-type" @click="()=>{i.eSIM=false}">
                            <b-row class="justify-content-between">
                              <p class="ml-2 font-medium-3 font-weight-bold">Sim vật lý</p>
                              <img v-if="!i.eSIM" alt="checkbox" style="width: 20px; height: 20px"
                                   src="../assets/images/check-circle-red.png"/>
                              <img v-else alt="checkbox" style="width: 20px; height: 20px"
                                   src="../assets/images/circleblank.png"/>
                            </b-row>
                            <img alt="checkbox" class="w-100 h-75"
                                 src="../assets/images/b2c/SIM-icon-1.png"/>

                          </div>
                          <div class="pick-sim-type ml-2" @click="()=>{i.eSIM=true;changeESIM()}"
                               v-if="paymentMethod != 'COD'">
                            <b-row class="justify-content-between">
                              <p class="font-medium-3 font-weight-bold ml-2">eSIM</p>
                              <img v-if="i.eSIM " alt="checkbox" style="width: 20px; height: 20px"
                                   src="../assets/images/check-circle-red.png"/>
                              <img v-else alt="checkbox" style="width: 20px; height: 20px"
                                   src="../assets/images/circleblank.png"/>
                            </b-row>
                            <img alt="checkbox" class="esim-icon"
                                 src="../assets/images/b2c/eSIM-icon-2.png"/>
                          </div>
                        </div>
                        <!--                                    <span class="float-left" v-if="paymentMethod != 'COD'">-->
                        <!--                                      <b-form-checkbox name="vip" @change="changeESIM" v-model="i.eSIM">Sử dụng eSIM<br><small-->
                        <!--                                          class="text-primary font-small-3">eSIM là SIM điện tử, được gửi về email của khách hàng <br/>Chỉ-->
                        <!--                                          dùng cho các dòng máy hỗ trợ eSIM.</small></b-form-checkbox>-->
                        <!--                                    </span>-->
                        <span class="float-right" v-if="cart.length > 1"><button
                            class="b2c-cart-bg-del"
                            @click="cartRemoveItem(i); getMGMDiscount()">Xoá</button></span>
                      </div>
                    </div>
                  </b-card>
                  <b-card class="b2c-cart-esim-text p-1">
                    <b-card-text>
                      <span><b>Lưu ý:</b><span class="text-primary font-italic"> eSIM là SIM điện tử, được gửi về email của khách hàng <br/>Chỉ
                        dùng cho các dòng máy hỗ trợ eSIM.</span></span>
                    </b-card-text>
                  </b-card>
                  <b-card @click="goToStep(1, true)" class="b2c-cart-add-more p-1">
                    <b-card-text>
                      <a @click="goToStep(1, true)">Chọn thêm số</a>
                      <span class="float-right"><feather-icon size="24"
                                                              icon="PlusSquareIcon"/></span>
                    </b-card-text>
                  </b-card>
                </div>

              </div>
              <div class="col-6 b2c-pc-info">
                <b-card class="p-0" body-class="p-0">
                  <b-card-header v-if="cart.find(i => i.eSIM) != undefined">Thông tin nhận eSIM
                  </b-card-header>
                  <b-card-header v-else>Thông tin người nhận</b-card-header>
                  <b-card-text>
                    <!--<validation-observer ref="infoValid">-->
                    <div class="row p-2">
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:3">
                          <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" maxlength="35" v-model="cart_info.fullName" autocomplete="name"
                                        name="name" id="fullName"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập
                            đầy đủ họ và tên người
                            nhận</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:10">
                          <label class="d-block">Điện thoại liên hệ <span
                              class="text-primary">*</span></label>
                          <b-form-input type="text"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                        maxlength="10" size="sm1"
                                        autocomplete="phone" name="phone" id="phone"
                                        v-model.trim="cart_info.ContactPhone"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập
                            số điện thoại liên
                            hệ</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12" v-if="cart.find(i => i.eSIM) != undefined">
                        <validation-provider #default="{ errors }" rules="required|email">
                          <label class="d-block">Email để nhận eSIM <span
                              class="text-primary">*</span></label>
                          <b-form-input size="sm1" maxlength="40"
                                        autocomplete="email" name="email" id="email"
                                        v-model.trim="cart_info.email"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập
                            chính xác email để nhận
                            thông
                            tin
                            eSIM</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="w-100 b2c-cart-info-title border-top b2c-card-header"
                         v-if="cart.find(i => !i.eSIM) != undefined">Lựa chọn hình thức giao hàng
                    </div>
                    <div class="p-2 cursor-pointer" v-if="cart.find(i => !i.eSIM) != undefined">
                      <div class="d-flex align-baseline" @click="onShipmentTabChange(0)">
                        <div class="check-box mr-3">
                          <img v-if="activeTab===0" alt="checkbox"
                               src="../assets/images/check-circle-red.png"/>
                          <img v-else alt="checkbox"
                               src="../assets/images/circleblank.png"/>
                        </div>
                        <span class="card-header-title">
                          Giao hàng tận nơi
                        </span>
                      </div>
                      <div class="mt-2 d-flex align-baseline" @click="onShipmentTabChange(1)">
                        <div class="check-box mr-3">
                          <img v-if="activeTab===1" alt="checkbox"
                               src="../assets/images/check-circle-red.png"/>
                          <img v-else alt="checkbox"
                               src="../assets/images/circleblank.png"/>
                        </div>
                        <span class="card-header-title">
                          Nhận tại phòng giao dịch iTel
                        </span>
                      </div>
                    </div>
                    <div v-if="cart.find(i => !i.eSIM) != undefined" class="px-1">
                      <div v-if="activeTab===0">
                        <!--<div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title">Giao hàng tận nơi
                                          <span class="float-right">
                                          <b-form-checkbox @change="change_shipment('home')" name="shipment_type" value="home" v-model="cart_info.shipment_type"></b-form-checkbox>
                                        </span>
                                      </div> -->
                        <div class="row p-1"
                             v-if="!isLoading && cart_info.shipment_type == 'home' && cart.find(i => !i.eSIM) != undefined">
                          <!-- <div class="col-md-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider rules="">
                              <label class="d-block">Thời gian<span class="text-primary">*</span></label>
                              <v-select
                                  class="select-delivery-time"
                                  label="text"
                                  v-model="cart_info.deliver_work_ship"
                                  :filter="fuseSearch"
                                  :options="deliveryTimes"
                                  :reduce="option => option.id"
                                  placeholder="Chọn thời gian giao hàng"
                              />
                            </validation-provider>
                          </div> -->
                          <div class="col-md-12" v-if="cart.find(i => !i.eSIM) != undefined">
                            <validation-provider #default="{ errors }"
                                                 rules="required|min:2">
                              <label class="d-block">Nhập địa chỉ <span
                                  class="text-primary">*</span></label>
                              <b-form-input size="sm1"
                                            placeholder="Số nhà, tên đường, tên thôn/ấp/khu"
                                            v-model="cart_info.addr"
                                            :state="errors.length > 0 ? false : null"/>
                              <small class="fs-error-txt" v-if="errors.length > 0">Vui
                                lòng điền chi tiết số nhà, tên
                                đường,
                                thôn/ấp/khu</small>

                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                            <validation-provider #default="{ errors }"
                                                 rules="required|min0">
                              <label class="d-block">Tỉnh/TP <span
                                  class="text-primary">*</span></label>
                              <v-select @input="onCityChange" label="text"
                                        v-model="CityId" :filter="fuseSearch"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :options="Cities"
                                        :reduce="CityId => CityId.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length > 0">Vui
                                lòng nhập Tỉnh/TP</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                            <validation-provider #default="{ errors }"
                                                 rules="required|min0">
                              <label class="d-block">Quận/Huyện <span
                                  class="text-primary">*</span></label>
                              <v-select :disabled="!(CityId > 0)" :filter="fuseSearch"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        ref="districtSelect"
                                        label="text" v-model="DistrictId"
                                        :options="Districts"
                                        :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length > 0">Vui
                                lòng nhập Quận/Huyện</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                            <validation-provider #default="{ errors }"
                                                 rules="required|min0">
                              <label class="d-block">Phường/Xã <span class="text-primary">*</span></label>
                              <v-select :disabled="!(DistrictId > 0)" :filter="fuseSearch"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        ref="WardsSelect"
                                        label="text" v-model="WardId"
                                        :options="Wards"
                                        :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length > 0">Vui
                                lòng nhập Phường/Xã</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i => !i.eSIM) != undefined">
                            <validation-provider #default="{ errors }" rules="">
                              <label class="d-block">Ấp/Khu</label>
                              <v-select :disabled="!(WardId > 0)" :filter="fuseSearch"
                                        label="text" v-model="HamletId"
                                        :options="Hamlets" :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length > 0">Vui
                                lòng nhập Ấp/Khu</small>
                            </validation-provider>
                          </div>
                          <div class="col-12 mt-1">
                            <div
                                :class="[(CityId > 0) && cart.find(i => !i.eSIM) != undefined && hasExpressShip && paymentMethod != 'COD' && framePaymentMethods!=1 ? '' : 'disabled', ' d-flex delivery-type']"
                                v-if="(CityId > 0)">
                              <div class="check-box mr-3" @click="onClickExpress()"
                                   v-if="cart.find(i => !i.eSIM) != undefined && hasExpressShip && paymentMethod != 'COD'">
                                <img v-if="isExpress" alt="checkbox"
                                     src="../assets/images/check-circle-red.png"/>
                                <img v-else alt="checkbox"
                                     src="../assets/images/circleblank.png"/>
                              </div>
                              <div class="check-box mr-3" v-else>
                                <img alt="checkbox"
                                     src="../assets/images/circleblank.png"/>
                              </div>
                              <div class="info-delivery">
                                <div class="info-delivery__title">Giao hàng hỏa tốc
                                  <span
                                      class="info-delivery--subtile ">(Không
                                                áp dụng với hình thức thanh toán COD) <br/> <i
                                        v-if="!hasExpressShip && !paymentMethod != 'COD' && !cart.find(i => !i.eSIM) != undefined"> Chưa
                                                  hỗ trợ tại tỉnh thành đang chọn </i> </span>
                                </div>
                                <div class="info-delivery__sub-title" disabled
                                     v-if="cart.find(i => !i.eSIM) != undefined && paymentMethod != 'COD' &&isShowExpressText">
                                  {{ cart_info.delivery_time }}
                                </div>
                              </div>

                            </div>
                            <div class="border-bt"></div>
                          </div>
                          <div class="col-12" v-if="cart.find(i => !i.eSIM) != undefined">
                            <div class="d-flex delivery-type">
                              <div class="check-box mr-3" @click="onClickFast()">
                                <img v-if="isFast" alt="checkbox"
                                     src="../assets/images/check-circle-red.png"/>
                                <img v-else alt="checkbox"
                                     src="../assets/images/circleblank.png"/>
                              </div>
                              <div class="info-delivery">
                                <div class="info-delivery__title">Giao hàng tiêu chuẩn</div>
                                <div class="info-delivery__sub-title">Nhận hàng từ 1 tới
                                  3 ngày kể từ thời điểm đặt đơn
                                  hàng
                                </div>
                              </div>

                            </div>
                            <div class="border-bt"></div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <!--<div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title border">Nhận tại phòng giao dịch iTel
                                          <span class="float-right">
                                            <b-form-checkbox @change="change_shipment('itel')" name="shipment_type" value="itel" v-model="cart_info.shipment_type"></b-form-checkbox>
                                          </span>
                                      </div> -->
                        <b-row v-if="cart.find(i => !i.eSIM) != undefined">
                          <div class="col-12 p-1">
                            <div @click="storeCollapse = !storeCollapse"
                                 :class="storeCollapse ? 'b2c-store-colapse' : 'b2c-store-nocolapse'"
                                 class="border d-block bg-white p-2">
                                          <span v-if="!isLoading && cart_info.StoreId > 0">{{
                                              Stores.find(m => m.Id == cart_info.StoreId).Name
                                            }}</span>
                              <span v-else>Chọn chi nhánh</span>
                            </div>
                          </div>
                        </b-row>
                        <div v-if="!isLoading && !storeCollapse && cart.find(i => !i.eSIM) != undefined">
                          <div v-on:click="selectStore(i)"
                               class=" ml-1 mr-1 p-2 bg-white border d-flex align-items-center" v-for="i in Stores"
                               :key="i.Id">
                            <b-form-checkbox class="vs-checkbox-con" :value="i.Id" v-model="cart_info.StoreId"/>
                            <div>
                              <h2 class="h2 pt-1">{{ i.Name }}</h2>
                              <p class="text">Giờ làm việc: thứ 2 - thứ 6 (trừ ngày lễ,
                                Tết)</p>
                              <p class="text">Sáng: 8h-12h, Chiều: 13h-18h (thứ 6 làm việc đến
                                17h)</p>
                              <p class="pt-1 text text-primary pb-1"><a target="_blank"
                                                                        class="text text-primary"
                                                                        :href="'http://maps.google.com/maps/dir/?api=1&destination=' + i.Lat + ',' + i.Long + '&travelmode=driving'"
                                                                        :title="$t('Common.Direct')">
                                <feather-icon icon="MapPinIcon"/>
                                {{ $t('Common.Direct') }}</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                    <b-tabs active-nav-item-class="font-weight-bold text-danger"-->
                    <!--                            nav-class="b2c-cart-info-title-text shipping-nav"-->
                    <!--                            @input="onShipmentTabChange($event)"-->
                    <!--                             v-model="activeTab">-->

                    <!--                    </b-tabs>-->
                    <!-- <b-row>                                                                                                                                         <b-col cols="12" class="p-3">
                                        <div class="border rounded bg-white p-3">
                                          <b-form-checkbox v-if="cart.find(i=>i.Months>0)!=undefined" :value="true" v-model="cart_info.agree">Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">điều khoản và điều kiện giao dịch chung</a> của iTel
                                          </b-form-checkbox>
                                          <b-form-checkbox v-else :value="true" v-model="cart_info.agree">Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">chính sách mua SIM số</a> của iTel
                                          </b-form-checkbox>
                                        </div>
                                      </b-col>
                                  </b-row> -->
                    <div class="w-100 b2c-cart-info-title b2c-card-header border-top  " v-if="!isOnIframe">
                      <span>Chọn hình thức thanh toán</span>
                    </div>
                    <template v-if="!isOnIframe">
                      <b-row>
                        <b-col cols="12" class="p-3">
                          <div class="bg-white">
                            <div class="payment-method-container"
                                 v-if="!isLoading && cart.find(i => !i.eSIM)!= undefined && paymentMethodStatus.COD"
                                 @click="ChangePaymentMethod('COD')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="COD" v-model="paymentMethod" disabled/>
                                  <span class="inputText"> Thanh toán khi nhận hàng (COD)</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/COD.svg')" alt="logoCOD">
                                </div>
                              </div>
                              <div v-show="paymentMethod === 'COD' && activeTab === 1" xss=removed>
                                <p>(Quý Khách vui lòng đến PGD đã chọn để nhận hàng. iTel sẽ
                                  giữ đơn hàng của Quý Khách
                                  trong vòng 48h, sau thời điểm trên đơn hàng sẽ bị
                                  hủy.)</p>
                              </div>
                            </div>
                            <div class="payment-method-container"
                                 v-if="paymentMethodStatus.MOMO && getSource != 'MY ITEL' "
                                 @click="ChangePaymentMethod('MOMO')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="MOMO" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Thanh toán Ví Momo</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/MOMO.png')" alt="logoCOD">
                                </div>
                              </div>
                            </div>
                            <div class="payment-method-container" v-if="paymentMethodStatus.VIETQR"
                                 @click="ChangePaymentMethod('VIETQR')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="VIETQR" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Chuyển khoản ngân hàng</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/VIETQR.png')" alt="logoVIETQR">
                                </div>
                              </div>
                            </div>
                            <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_VNPAYQR"
                                 @click="ChangePaymentMethod('VNPAY')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="VNPAY" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Thanh toán qua VNPAY</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/VNPAY.png')" alt="logoVNPAY">
                                </div>
                              </div>
                            </div>
                            <div class="payment-method-container"
                                 v-if="paymentMethodStatus.ZALOPAY && getSource != 'MY ITEL'"
                                 @click="ChangePaymentMethod('ZALOPAY')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="ZALOPAY" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Thanh toán Ví ZaloPay</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/ZALO.png')" alt="logoZALOPAY">
                                </div>
                              </div>
                            </div>
                            <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_VNBANK"
                                 @click="ChangePaymentMethod('BANK')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="BANK" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Thẻ ATM (có Internet Banking)</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/COD.svg')" alt="logoBANK">
                                </div>
                              </div>
                            </div>
                            <div class="payment-method-container" v-if="paymentMethodStatus.VNPAY_INTCARD"
                                 @click="ChangePaymentMethod('INTCARD')">
                              <div class="payment-method-content">
                                <div class="payment-method-content-text">
                                  <b-form-checkbox value="INTCARD" v-model="paymentMethod" disabled/>
                                  <span class="inputText">Thẻ thanh toán quốc tế</span>
                                </div>
                                <div class="payment-method-content-image">
                                  <img :src="require('@/assets/images/b2c/payment/INTCARD.png')" alt="logoINTCARD">
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
<!--                    <div class="w-100 b2c-cart-info-title border-top b2c-card-header">-->
<!--                      <span>Mã giới thiệu/ ưu đãi</span>-->
<!--                    </div>-->
<!--                    <b-row class="pt-2 pl-3 pr-3">-->
<!--                      <b-col cols="12">-->
<!--                        <div class="d-flex mb-1">-->
<!--                          <b-form-input size="sm1" placeholder="Nhập mã ưu đãi hoặc mã giới thiệu"-->
<!--                                        style="width: 85%;"-->
<!--                                        v-model="promotionCode"-->
<!--                                        @update="handlePromotionCode()"/>-->
<!--                          <b-button :disabled="promotionCode === null || promotionCode === ''"-->
<!--                                    @click="getMGMDiscount()" class="b2c__btn"-->
<!--                                    :style="promotionCode === null || promotionCode === '' ? '' : 'background: #ed1f24 !important;'">-->
<!--                            Áp dụng-->
<!--                          </b-button>-->
<!--                        </div>-->
<!--                        <b-row v-if="!isStatusPromotionCode && promotionCode.length>0">-->
<!--                          <b-col class="text-primary mb-1 " style="font-size: 14px">-->
<!--                            Ấn "Áp dụng" để được hưởng ưu đãi-->
<!--                          </b-col>-->
<!--                        </b-row>-->
<!--                      </b-col>-->
<!--                      <b-col cols="12 mb-1" v-if="isStatusPromotionCode">-->
<!--                        <p v-if="isMGMSuccess" style="color: blue; font-size: 16px;">-->
<!--                          Mã giới thiệu hợp lệ! iTel sẽ tặng ngay {{ totalSuccessTopup | number }}đ vào TKC cho số TB-->
<!--                          0{{ mgmPhone }} khi Bạn hoàn thành thanh toán và kích-->
<!--                          hoạt SIM thành công.-->
<!--                        </p>-->
<!--                        <p v-else>-->
<!--                          <span v-if="SuccessMessage.length<=0" style="color: blue; font-size: 16px;">Áp dụng mã khuyến mại-->
<!--                            thành công!</span>-->
<!--                          <span v-if="SuccessMessage.length>0" style="color: blue; font-size: 16px;">{{-->
<!--                              SuccessMessage-->
<!--                            }}</span>-->
<!--                        </p>-->
<!--                      </b-col>-->
<!--                    </b-row>-->
                    <div class="w-100 b2c-cart-info-title border-top b2c-card-header">
                      <span>Chi tiết thanh toán</span>
                    </div>
                    <b-row class=" pt-2 pl-3 pr-3 payment-info">
                      <b-col cols="12" class="h2">
                        <div class="d-flex mb-1 align-items-center"
                             v-if="isOnIframe && frameType==='TeleSale'">
                          <p style="width: 70%">Hình thức thanh toán</p>
                          <v-select style="width: 100%;" label="text"
                                    :searchable=false
                                    v-model="framePaymentMethods"
                                    :options="framePaymentMethodsList"
                                    @input="onFramePaymentMethodChange()"
                                    :reduce="c => c.id"
                          ></v-select>
                        </div>
                      </b-col>
                      <!--Tổng tiền -->
                      <b-col cols="6" class="h2 ">Tổng tiền hàng:</b-col>
                      <b-col cols="6" class="h2  text-right ">
                        {{ totalPriceBeforeDiscount |number }}đ
                      </b-col>
                      <!--Phí vận chuyển-->
                      <b-col cols="6" class="h2">Tổng tiền phí vận chuyển:</b-col>
                      <b-col cols="6" class="h2 text-right">
                        <span
                            v-if="cart_info.ghtk_fee!=cart_info.fee || (!isFullEsim() && cart_info.shipment_type == 'home')"
                        >
                          {{ (!isFullEsim() && cart_info.shipment_type == 'home') ? formatNumber(cart_info.fee) : 0 }}đ
                        </span>
                        <span v-else>0đ</span>
                      </b-col>
                      <!--Trừ tiền Ship-->
                      <b-col cols="6" class="h2 " v-if="activeTab === 0">Giảm giá phí vận chuyển:</b-col>
                      <b-col cols="6" class="h2  text-right" v-if="activeTab === 0">
                        <span v-if="!isFullEsim()">-{{ formatNumber(cart_info.fee - cart_info.ghtk_fee) }}đ</span>
                        <span v-if="isFullEsim()">0đ</span>
                      </b-col>
                      <!--Itel KM-->
                      <b-col cols="6" class="h2" v-if="totalDiscountSim>0">
                        {{ cart[0].isBeautifulSimNumber ? 'Giảm giá sim số đẹp giá sốc:' : 'iTel khuyến mãi:' }}
                      </b-col>
                      <b-col cols="6" class="h2  text-right" v-if="totalDiscountSim>0">
                        -{{ totalDiscountSim | number }}đ
                      </b-col>

                      <!--Trừ tiền MGT-->
                      <b-col cols="6" class="h2  " v-if="isMGMSuccess">Mã giới thiệu:</b-col>
                      <b-col cols="6" class="h2 text-right " v-if="isMGMSuccess">
                        -{{ totalDiscountPromotion|number }}đ
                      </b-col>
                      <!--Trừ tiền MGG-->
                      <b-col cols="6" class="h2 " v-if="!isMGMSuccess &&isStatusPromotionCode">Mã giảm giá:</b-col>
                      <b-col cols="6" class="h2 text-right " v-if="!isMGMSuccess &&isStatusPromotionCode">
                        -{{ totalSalePrice | number }}đ
                      </b-col>
                      <!--Tổng tiền sau giảm-->
                      <b-col cols="6" class="h1 pt-1 d-flex align-items-center">Tổng thanh toán:</b-col>
                      <b-col cols="6" class="huge-text pt-1 text-primary text-right mb-0">
                        {{ getTotalPrice() - totalSalePrice - totalDiscountPromotion|number }}đ
                      </b-col>
                    </b-row>
                    <button v-if="!isOnIframe" @click="onPayment()" :disabled=isDisablePayment
                            class="b2c-search-button b2c-search-button-red">
                      {{ payment_text }}
                    </button>
                    <button v-if="isOnIframe" @click="$bvModal.show('modal-preview-order')"
                            class="b2c-search-button b2c-search-button-red">Tạo đơn
                    </button>
                    <b-row>
                      <div class="pl-4 pr-4 w-100">
                        <div class="d-block bg-white text-center pb-3">
                          Bằng cách bấm thanh toán, bạn đồng ý với <a
                            href="https://itel.vn/thong-tin/dieu-kien-&-dieu-khoan-giao-dich-chung"
                            class="text-primary text-decoration-underline" target="_blank">điều
                          khoản và điều kiện giao
                          dịch
                          chung</a> của iTel
                        </div>
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>

    <!-- Success / Failure VNPAY -->
    <div class="p-2" v-else-if="step==4">
      <div v-if="VnPayResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center" v-if="VnPayResponse.vnp_ResponseCode=='00'">
              <template v-if="$route.params.payment == 'VNPAYISP'">
                <h2 class="huge-text" style="color: #424242">
                  <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                        stroke="#40E108" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </svg>
                  <br><br>Đặt hàng trả góp thành công
                  <br/>
                  <p class="text-center mt-2"
                     style="font-size: 1.6rem; line-height: 110%; font-weight: 400; color: #424242">
                    Quý Khách sẽ nhận được tin nhắn thông tin <br/>
                    đặt hàng về số điện thoại
                  </p>
                  <p class="text-center mt-2"
                     style="font-size: 1.6rem; line-height: 110%; font-weight: 400; color: #424242">
                    Lưu ý: Tổ chức phát hành thẻ sẽ xem xét phê duyệt <br/> chuyển đổi trả góp cho
                    giao dịch của Quý
                    khách.
                  </p>
                </h2>
              </template>
              <template v-else>
                <h2 class="huge-text p-1">
                  <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                        stroke="#40E108" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </svg>
                  <br><br>Chúc mừng bạn đã sở hữu SIM iTel!!!
                </h2>
                <p class="text" v-if="OrderInfo.Phones">
                  Bạn vui lòng kích hoạt SIM trong vòng 20 ngày kể từ ngày đặt hàng. <br/>
                  Nếu quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định
                  <img src="../assets/images/b2c/cryingicon.svg" alt="">
                </p>
              </template>
            </div>
            <div class="pb-3 pt-3 w-100 text-center" v-else>
              <template v-if="$route.params.payment == 'VNPAYISP'">
                <h2 class="huge-text" style="color: #424242">
                  <svg width="127" height="127" viewBox="0 0 127 127" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                        stroke="#ED1F24" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </svg>
                  <br><br>Đặt hàng không thành công
                  <br/>
                  <p class="text-center mt-2"
                     style="font-size: 1.6rem; line-height: 110%; font-weight: 400; color: #424242">
                    Đơn hàng của Quý Khách chưa được ghi nhận <br/> thành công trong hệ thống, xin
                    vui lòng thử lại.
                  </p>
                </h2>
              </template>
              <template v-else>
                <h2 class="huge-text">
                  <svg width="127" height="127" viewBox="0 0 127 127" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                        stroke="#ED1F24" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  </svg>
                  <br><br>Thanh toán thất bại
                </h2>
              </template>
            </div>
            <div v-if="VnPayResponse.vnp_ResponseCode=='00'" class="p-0">
              <template v-if="$route.params.payment == 'VNPAYISP'">
                <div class="detail-order-isp p-2">
                  <b-row>
                    <b-col cols="6" class="h2 text" style="color: #333333">Mã đơn hàng:</b-col>
                    <b-col cols="6" class="pl-0 h1 text-right text-primary"
                           style="font-size: 1.6rem; font-weight: 700">
                      {{ VnPayResponse.vnp_TxnRef }}
                    </b-col>
                  </b-row>
                  <b-row class="pt-0 pb-1">
                    <b-col cols="6" class="h2 text" style="color: #333333">Số tiền đăng ký trả
                      góp:
                    </b-col>
                    <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                      {{
                        (OrderInfo.ispInfo ? OrderInfo.ispInfo.totalIspAmount || 0 : 0) / 100|number
                      }} đ
                    </b-col>
                  </b-row>
                  <b-row class="pt-0 pb-1">
                    <b-col cols="6" class="h2 text" style="color: #333333">Số tiền trả góp hàng
                      tháng:
                    </b-col>
                    <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                      {{
                        (OrderInfo.ispInfo ? OrderInfo.ispInfo.recurringAmount || 0 : 0) / 100|number
                      }} đ
                    </b-col>
                  </b-row>
                  <b-row class="pt-0 pb-1 border-bottom">
                    <b-col cols="6" class="h2 text" style="color: #333333">Kỳ hạn:</b-col>
                    <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                      {{ (OrderInfo.ispInfo ? OrderInfo.ispInfo.recurringNumberOfIsp || 0 : 0) }}
                      tháng
                    </b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #333333">Người
                      mua hàng
                    </b-col>
                    <b-col cols="12" class="h2 text-primary text-isp-info">{{
                        OrderInfo.FullName
                      }}
                    </b-col>
                    <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #333333">Số điện
                      thoại liên hệ
                    </b-col>
                    <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary text-isp-info">
                      {{ OrderInfo.ContactPhone }}
                    </b-col>
                    <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #333333">Email
                    </b-col>
                    <b-col cols="12" class="h2 text-primary text-isp-info">{{
                        OrderInfo.Email
                      }}
                    </b-col>
                    <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #333333">Địa chỉ
                      nhận hàng
                    </b-col>
                    <b-col cols="12" class="h2 text-primary text-isp-info">{{
                        OrderInfo.Addr
                      }}
                    </b-col>
                  </b-row>
                </div>
                <b-row class="pt-2">
                  <b-col cols="12" class="h2 text text-center"
                         style="font-size: 1.6rem;color: #424242">Xem thông tin
                    đơn
                    hàng <a :href="`/shipment/${VnPayResponse.vnp_TxnRef}`"
                            class="text-primary text-decoration-underline"
                            style="font-style: italic" target="_blank">tại đây</a></b-col>
                </b-row>
              </template>
              <template v-else>
                <div class="detail-order p-2">
                  <b-row>
                    <b-col cols="6" class="h2">Mã đơn hàng
                    </b-col>
                    <b-col cols="6" class="pl-0 h1 text-right text-primary">
                      {{ VnPayResponse.vnp_TxnRef }}
                    </b-col>
                  </b-row>
                  <b-row class="pt-0">
                    <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng
                      để phục vụ kích hoạt
                      SIM
                    </b-col>
                  </b-row>
                  <b-row class="pt-0 pb-1 border-bottom">
                    <b-col cols="6" class="h2">Đã thanh toán</b-col>
                    <b-col cols="6" class="h1 text-right text-primary">
                      {{ VnPayResponse.vnp_Amount / 100|number }}đ
                    </b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="12" v-if="OrderInfo.FullName" class="b2c-price-texts">Người mua hàng</b-col>
                    <b-col cols="12" class="h2 text-primary">{{ OrderInfo.FullName }}</b-col>
                    <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts">Số iTel đã đặt
                      hàng
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.Phones" class="h2 text-primary">
                      {{ OrderInfo.Phones }}
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.ContactPhone" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                    <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary">
                      {{ OrderInfo.ContactPhone }}
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.hassim==0" class="b2c-price-texts">Email
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.hassim==0" class="h2 text-primary">
                      {{ OrderInfo.Email }}
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.hassim>0" class="b2c-price-texts">Địa chỉ nhận
                      hàng
                    </b-col>
                    <b-col cols="12" v-if="OrderInfo.hassim>0" class="h2 text-primary">
                      {{ OrderInfo.Addr }}
                    </b-col>
                    <b-col cols="12" v-if="$route.query.orderType==10" class="h2 text-primary text-center">
                      Chuyển về màn hình kích hoạt sau vài giây
                    </b-col>
                  </b-row>
                </div>
              </template>
              <!-- khảo sát ces -->
              <SurveyCES v-if="VnPayResponse && $route.query.orderType!=10" :phone="OrderInfo.ContactPhone"
                         :orderId="VnPayResponse.vnp_TxnRef"/>
              <!-- <div class="w-100 pt-1 pb-2 text-center">
                <router-link class="btn btn-primary mr-1 p-1" to="/mua-hang">Giao dịch mớixxxxxx</router-link><router-link class="btn btn-secondary mr-1 p-1" to="/">Về trang chủ</router-link>
              </div> -->
            </div>
            <b-card v-if="!($route.params.payment == 'VNPAYISP') && !(VnPayResponse.vnp_ResponseCode=='00')"
                    class="p-0">
              <b-card-text>
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{
                      VnPayResponse.vnp_TxnRef
                    }}
                  </b-col>
                </b-row>
                <b-row class="pt-1 pb-1 border-bottom">
                  <b-col cols="4" class="h2">Tình trạng</b-col>
                  <b-col cols="8" class="h2 text-right text-primary">Thanh toán thất bại</b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="h2">Thông báo chi tiết</b-col>
                  <b-col cols="12" class="b2c-price-texts">
                    {{ vnPayErrors.find(e => e.id == VnPayResponse.vnp_ResponseCode).text }}
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <div class="row pt-3"
                 v-if="VnPayResponse.vnp_ResponseCode!='00' && !($route.params.payment == 'VNPAYISP')">
              <div class="col-12">
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="rePickPhoneNum()" variant="primary" class="" v-if="VnPayResponse.orderType==='10'">
                    Chọn lại
                  </b-button>
                  <b-button @click="chonThanhToanLai()" variant="primary" class="" v-else>Thanh toán lại
                  </b-button>
                </div>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
            <div class="row"
                 v-if="VnPayResponse.vnp_ResponseCode!='00' && $route.params.payment == 'VNPAYISP'">
              <div class="col-12">
                <div class="w-100 pb-2 text-center">
                  <b-button
                      variant="primary"
                      class="style-button"
                      @click="chonThanhToanLai()"
                  >THỬ LẠI
                  </b-button
                  >
                </div>
              </div>
              <div class="col-6">
                <b-button
                    variant="outline-danger"
                    class="style-button-chat"
                    @click="
                    $router.push(`${$t('Route.Support')}${$t('Route.ContactUs')}`)
                  "
                >Chat với CSKH
                </b-button
                >
              </div>
              <div class="col-6">
                <b-button
                    variant="outline-danger"
                    class="style-button-contact"
                    @click="$bvModal.show('modal-phone')"
                >
                  <span class="font-weight-btn"> Liên hệ CSKH</span> <br/>
                  087 7087087
                </b-button>
              </div>
              <b-modal
                  hide-header
                  body-class="p-2"
                  title-class="text-primary"
                  hide-footer
                  id="modal-phone"
                  content-class="your-class"
                  centered
              >
                <b-button class="mt-1 d-flex align-items-center style-btn" block>
                  <a href="tel:087 7087087">
                    <span class="mr-2">
                      <img
                          loading="lazy"
                          src="@/assets/images/contact-phone.png"
                          alt="Hotline ITEL"
                      />
                    </span>
                    <span class="text-contact"> Gọi 087 7087087</span>
                  </a>
                </b-button>
                <b-button
                    class="mt-2 style-btn text-contact"
                    block
                    @click="$bvModal.hide('modal-phone')"
                >Hủy
                </b-button
                >
              </b-modal>
            </div>
          </div>
        </b-card>
      </div>
      <!-- Success Failure  ZALO -->
      <div v-if="ZaloResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center " v-if="ZaloResponse.status==1">
              <h2 class="huge-text">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                      stroke="#40E108" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>Chúc mừng bạn đã sở hữu SIM iTel!!!
              </h2>
              <p class="text" v-if="OrderInfo.Phones">
                Bạn vui lòng kích hoạt SIM trong vòng 20 ngày kể từ ngày đặt hàng. <br/>
                Nếu quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định
                <img src="../assets/images/b2c/cryingicon.svg" alt="">
              </p>
            </div>
            <div class="pb-3 pt-3 w-100 text-center border-bottom" v-else>
              <h2 class="huge-text">
                <svg width="127" height="127" viewBox="0 0 127 127" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                      stroke="#ED1F24" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>Thanh toán thất bại
              </h2>
            </div>
            <div v-if="ZaloResponse.status==1" class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{
                      ZaloResponse.apptransid
                    }}
                  </b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để
                    phục vụ kích hoạt
                    SIM
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{
                      ZaloResponse.amount
                    }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ OrderInfo.FullName }}</b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts">Số iTel đã đặt
                    hàng
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="h2 text-primary">{{
                      OrderInfo.Phones
                    }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary">
                    {{ OrderInfo.ContactPhone }}
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="b2c-price-texts">Email</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="h2 text-primary">
                    {{ OrderInfo.Email }}
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="b2c-price-texts">Địa chỉ nhận
                    hàng
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="h2 text-primary">{{
                      OrderInfo.Addr
                    }}
                  </b-col>
                </b-row>
              </div>
              <!-- khảo sát ces -->
              <SurveyCES :phone="OrderInfo.ContactPhone" :orderId="ZaloResponse.apptransid"/>
              <!-- <div class="w-100 pt-1 pb-2 text-center">
                <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link>
              </div> -->
            </div>

            <div class="row pt-3" v-if="ZaloResponse.status!=1">
              <div class="col-12">
                <b-card-text>
                  <b-row class="pt-1">
                    <b-col cols="6" class="h2">Mã đơn hàng</b-col>
                    <b-col cols="6" class="h1 text-right text-primary">{{
                        ZaloResponse.apptransid
                      }}
                    </b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="6" class="h2">Đơn giá</b-col>
                    <b-col cols="6" class="h1 text-right text-primary">{{
                        ZaloResponse.amount
                      }}Đ
                    </b-col>
                  </b-row>
                  <b-row class="pt-1 pb-1 border-bottom">
                    <b-col cols="4" class="h2">Tình trạng</b-col>
                    <b-col cols="8" class="h2 text-right text-primary">Thanh toán thất bại</b-col>
                  </b-row>
                </b-card-text>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="chonThanhToanLai()" variant="primary" class="">Thanh toán lại
                  </b-button>
                </div>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <!-- Success VIETQR -->
      <div v-if="VietQRResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center">
              <h2 class="huge-text">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                      stroke="#40E108" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>Chúc mừng bạn đã sở hữu SIM iTel!!!
              </h2>
              <p class="text" v-if="VietQRResponse[0].Phones">
                Bạn vui lòng kích hoạt SIM trong vòng 20 ngày kể từ ngày đặt hàng. <br/>
                Nếu quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định
                <img src="../assets/images/b2c/cryingicon.svg" alt="">
              </p>
            </div>
            <div class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{
                      VietQRResponse[0].Id
                    }}
                  </b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để
                    phục vụ kích hoạt
                    SIM
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{
                      VietQRResponse[0].TotalPrice
                    }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ VietQRResponse[0].FullName }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Số iTel đã đặt
                    hàng
                  </b-col>
                  <b-col cols="12" class="h2 text-primary">{{
                      VietQRResponse[0].Phones
                    }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary">
                    {{ VietQRResponse[0].ContactPhone }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Email</b-col>
                  <b-col cols="12" class="h2 text-primary">
                    {{ VietQRResponse[0].Email }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Địa chỉ nhận
                    hàng
                  </b-col>
                  <b-col cols="12" class="h2 text-primary">{{
                      VietQRResponse[0].Addr
                    }}
                  </b-col>
                </b-row>
              </div>
              <!-- khảo sát ces -->
              <SurveyCES :phone="VietQRResponse[0].ContactPhone" :orderId="VietQRResponse[0].Id"/>
            </div>
          </div>
        </b-card>
      </div>

      <!-- Success Failure  MOMO-->
      <div v-if="MoMoResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center " v-if="MoMoResponse.resultCode==0">
              <h2 class="huge-text">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                      stroke="#40E108" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>
                Chúc mừng bạn đã sở hữu SIM iTel!!!
              </h2>
              <p class="text" v-if="OrderInfo.Phones">
                Bạn vui lòng kích hoạt SIM trong vòng 20 ngày kể từ ngày đặt hàng. <br/>
                Nếu quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định
                <img src="../assets/images/b2c/cryingicon.svg" alt="">
              </p>
            </div>
            <div class="pb-3 pt-3 w-100 text-center border-bottom" v-else>
              <h2 class="huge-text">
                <svg width="127" height="127" viewBox="0 0 127 127" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                      stroke="#ED1F24" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>Thanh toán thất bại
              </h2>
            </div>
            <div v-if="MoMoResponse.resultCode==0" class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{
                      MoMoResponse.orderId
                    }}
                  </b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để
                    phục vụ kích hoạt
                    SIM
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{
                      MoMoResponse.amount
                    }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ OrderInfo.FullName }}</b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts">Số iTel đã đặt
                    hàng
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="h2 text-primary">{{
                      OrderInfo.Phones
                    }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary">
                    {{ OrderInfo.ContactPhone }}
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="b2c-price-texts">Email</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="h2 text-primary">
                    {{ OrderInfo.Email }}
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="b2c-price-texts">Địa chỉ nhận
                    hàng
                  </b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="h2 text-primary">{{
                      OrderInfo.Addr
                    }}
                  </b-col>
                </b-row>
              </div>
              <!-- khảo sát ces -->
              <SurveyCES :phone="OrderInfo.ContactPhone" :orderId="MoMoResponse.orderId"/>
              <!-- <div class="w-100 pt-1 pb-2 text-center">
                <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link>
              </div> -->
            </div>

            <div class="row pt-3" v-if="MoMoResponse.resultCode!=0">
              <div class="col-12">
                <b-card-text>
                  <b-row class="pt-1">
                    <b-col cols="6" class="h2">Mã đơn hàng</b-col>
                    <b-col cols="6" class="h1 text-right text-primary">{{
                        MoMoResponse.orderId
                      }}
                    </b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="6" class="h2">Đơn giá</b-col>
                    <b-col cols="6" class="h1 text-right text-primary">{{
                        MoMoResponse.amount
                      }}Đ
                    </b-col>
                  </b-row>
                  <b-row class="pt-1 pb-1 border-bottom">
                    <b-col cols="4" class="h2">Tình trạng</b-col>
                    <b-col cols="8" class="h2 text-right text-primary">Thanh toán thất bại</b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="12" class="h2">Thông báo chi tiết</b-col>
                    <b-col cols="12" class="b2c-price-texts">
                      {{ MoMoResponse.message }}
                    </b-col>
                  </b-row>
                </b-card-text>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="chonThanhToanLai()" variant="primary" class="">Thanh toán lại
                  </b-button>
                </div>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <!-- Success Failure  MOMO-->
      <div v-if="codResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center border-bottom">
              <h2 class="huge-text">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                      stroke="#40E108" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
                <br><br>Chúc mừng bạn đã sở hữu SIM iTel!!!
              </h2>
              <p class="text">
                Bạn vui lòng kích hoạt SIM trong vòng 20 ngày kể từ ngày đặt hàng. <br/>
                Nếu quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định
                <img src="../assets/images/b2c/cryingicon.svg" alt="">
              </p>
            </div>
            <div class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{
                      codResponse.vnp_TxnRef
                    }}
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Tổng tiền</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{
                      codResponse.TotalPrice |number
                    }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Hình thức thanh toán</b-col>
                  <b-col cols="12" class="h2 text-primary">Thanh toán khi nhận hàng</b-col>
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ codResponse.fullName }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Số iTel đã đặt hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ codResponse.Phones }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="b2c-contac-phone-texts h2 text-primary">
                    {{ codResponse.ContactPhone }}
                  </b-col>
                  <b-col cols="12" class="b2c-price-texts">Địa chỉ nhận hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ codResponse.FullAddress }}</b-col>
                </b-row>

              </div>
              <!-- khảo sát ces -->
              <SurveyCES v-if="codResponse && VnPayResponse" :phone="codResponse.ContactPhone"
                         :orderId="VnPayResponse.vnp_TxnRef"/>
              <!-- <div class="w-100 pt-1 pb-2 text-center">
                <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link>
              </div> -->
            </div>

          </div>
        </b-card>
      </div>
    </div>

    <b-modal body-class="p-2" title-class="text-primary" hide-footer id="modal-dup" title="Thông báo!">
      <b-row>
        <b-col cols="12">
          <p class="text text-center">Rất tiếc số thuê bao của Quý Khách vừa được Khách hàng khác mua thành
            công, xin
            Quý
            Khách vui lòng bấm vào
            <route-link class="text-primary font-weight-bold" @click="buyOther">ĐÂY</route-link>
            để chọn số khác
          </p>
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <b-button @click="buyOther" variant="primary">Tìm số khác</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" title-class="text-primary" hide-footer id="modal-cod" title="">
      <b-row>
        <b-col cols="12">
          <h3 class="huge-text text-center">Xác nhận OTP</h3>
          <p class="text text-center">Bạn vui lòng nhập mã OTP được gửi đến thuê bao {{
              cart_info.ContactPhone
            }}</p>
          <div class="pt-2" style="display: flex;align-items: center;justify-content: center;">
            <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="handleCODNext"
            />
          </div>
          <p class="text text-center pt-2">Thời gian OTP có hiệu lực <span class="text-primary">{{
              countDown
            }}s</span>
          </p>
          <p class="text text-center text-primary pb-3 pt-2" role="button" @click="resendOTP">Gửi lại mã
            OTP</p>

        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" size="lg" title-class="text" id="modal-preview-order"
             footer-class="preview-order-footer"
             button-size="sm" title="Đơn chờ thanh toán" @ok="onOrder()">
      <b-row class="text">
        <b-col cols="12">
          <h4 class="font-weight-bold">Thông tin đơn hàng</h4>
          <b-card body-class="p-1" class="p-1 mt-1">
            <b-card-text>
              <!-- <b-row>
                <b-col cols="6">
                  Mã đơn hàng
                </b-col>
                <b-col cols="6">
                   {{previewOrderId}}
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols="6">
                  Số TB đã mua
                </b-col>
                <b-col cols="6">
                  {{
                    cart.length > 1 ? cart.map(el => el.Phone).toString().replace(",", ", ") : cart.map(el => el.Phone).toString().replace(",", "")
                  }}
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="6">
                  Tổng tiền đơn hàng
                </b-col>
                <b-col cols="6">
                  {{ getTotalPrice() - totalSalePrice|number }} VNĐ
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="6">
                  Hình thức thanh toán
                </b-col>
                <b-col cols="6">
                  {{ paymentMethod == 'BANKTRANFER' ? 'Chuyển khoản' : paymentMethod }}
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12">
          <h4 class="font-weight-bold">Thông tin giỏ hàng</h4>
          <b-card body-class="p-1" class="p-1 mt-1">
            <b-card-text>
              <template v-for="(sim, index) in cart">
                <b-row :key="index" :class="index > 0 ? 'mt-1' : ''">
                  <b-col cols="6">
                    <span>{{ sim.eSim ? 'eSim' : 'Sim vật lý' }}</span>
                    <span class="ml-1">{{ sim.Phone }}</span>
                  </b-col>
                  <b-col cols="6">
                    <span>Gói cước / Chính sách</span>
                    <span class="ml-1">{{ sim.Pack }} / {{ sim.PackPrice|number }} VNĐ</span>
                  </b-col>
                  <b-col cols="6">
                    <span>Loại sim</span>
                    <span
                        class="ml-1 badge badge-primary b2c-badge">{{
                        sim.ThoiGianCamKet > 0 ? 'Số cam kết' : ''
                      }}{{
                        sim.ThoiGianCamKet > 0 && sim.group_name ? ' / ' : ''
                      }}{{ sim.group_name }}</span>
                  </b-col>
                  <b-col cols="6" v-if="sim.ThoiGianCamKet>0">
                    <span>Thời gian cam kết</span>
                    <span class="ml-2 badge badge-success badge-outlined">{{ sim.ThoiGianCamKet }} tháng</span>
                  </b-col>
                </b-row>
              </template>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12">
          <h4 class="font-weight-bold">Thông tin người nhận</h4>
          <b-card body-class="p-1" class="p-1 mt-1">
            <b-card-text>
              <b-row>
                <b-col cols="6">
                  Họ và tên
                </b-col>
                <b-col cols="6">
                  {{ cart_info.fullName }}
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="6">
                  Điện thoại liên hệ
                </b-col>
                <b-col cols="6">
                  {{ cart_info.ContactPhone }}
                </b-col>
              </b-row>
              <b-row class="mt-1" v-if="cart.find(i=>i.eSIM)!=undefined">
                <b-col cols="6">
                  Email để nhận eSIM
                </b-col>
                <b-col cols="6">
                  {{ cart_info.email }}
                </b-col>
              </b-row>
              <template v-if="!isFullEsim() && cart_info.shipment_type=='home'">
                <b-row class="mt-1">
                  <b-col cols="12">
                    <h4 class="font-weight-bold">Nhận hàng tận nhà</h4>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6" class="d-flex align-items-center">
                    Hình thức vận chuyển
                  </b-col>
                  <b-col cols="6">
                    {{ isExpress ? 'Giao hàng hỏa tốc' : 'Giao hàng tiêu chuẩn' }}
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6" class="d-flex align-items-center">
                    Tỉnh/TP
                  </b-col>
                  <b-col cols="6">
                    <!-- <v-select disabled label="text" :filter="fuseSearch" v-model="CityId" :options="Cities" :reduce="CityId => CityId.id"></v-select> -->
                    {{
                      Cities.find(el => el.id == CityId) ? Cities.find(el => el.id == CityId).text : ''
                    }}
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6" class="d-flex align-items-center">
                    Quận/Huyện
                  </b-col>
                  <b-col cols="6">
                    {{
                      Districts.find(el => el.id == DistrictId) ? Districts.find(el => el.id == DistrictId).text : ''
                    }}
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6" class="d-flex align-items-center">
                    Phường/Xã
                  </b-col>
                  <b-col cols="6">
                    {{
                      Wards.find(el => el.id == WardId) ? Wards.find(el => el.id == WardId).text : ''
                    }}
                  </b-col>
                </b-row>
                <b-row class="mt-1" v-if="HamletId && HamletId > 0">
                  <b-col cols="6" class="d-flex align-items-center">
                    Ấp/Khu
                  </b-col>
                  <b-col cols="6">
                    {{
                      Hamlets.find(el => el.id == HamletId) ? Hamlets.find(el => el.id == HamletId).text : ''
                    }}
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6" class="d-flex align-items-center">
                    Địa chỉ
                  </b-col>
                  <b-col cols="6">
                    {{ cart_info.addr }}
                  </b-col>
                </b-row>
              </template>
              <template v-if="!isFullEsim() && cart_info.shipment_type=='itel'">
                <b-row class="mt-1">
                  <b-col cols="12" class="mb-1">
                    <h4 class="font-weight-bold">Nhận hàng tại cửa hàng</h4>
                  </b-col>
                  <b-col cols="6" class="d-flex align-items-center">
                    Chi nhánh
                  </b-col>
                  <b-col cols="6">
                    {{
                      Stores.find(el => el.Id == cart_info.StoreId) ? Stores.find(el => el.Id == cart_info.StoreId).Name : ''
                    }}
                  </b-col>
                </b-row>
              </template>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" title-class="text-primary" hide-footer id="modal-order-success" title="" centered>
      <b-row>
        <b-col class="text-center d-flex justify-content-center align-items-center">
          <h2 class="huge-text" style="color: #424242">
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                  stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <br><br>Tạo đơn thành công
            <br/>
            <p class="text-center mt-2"
               style="font-size: 1.6rem; line-height: 110%; font-weight: 400; color: #424242">
              <span>Mã đơn hàng</span>
              <span class="ml-2 font-weight-bold">{{ previewOrderId }}</span>
            </p>
          </h2>
        </b-col>
      </b-row>
    </b-modal>
    <div class="d-none">
      <b-modal id="bv-modal-example" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3">Mã ưu đãi không đúng hoặc đã hết lượt sử dụng.<br> Vui lòng kiểm tra lại!</p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" @click="closePopup()"
                    style="background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="d-none">
      <b-modal id="bv-modal-sim-data" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3">Mã ưu đãi chỉ áp dụng cho đơn hàng SIM DATA<br>Vui lòng lựa chọn lại loại SIM để
            nhận ưu đãi!
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" block @click="closePopup()"
                    style="background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="d-none">
      <b-button id="show-btn" @click="$bvModal.show('bv-modal-create-null-price')"></b-button>
      <b-modal id="bv-modal-create-null-price" @ok="getCreateNullPrice()">
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-2">Bạn có muốn thanh toán đơn hàng này không?</p>
        </div>
      </b-modal>
    </div>
    <b-modal id="bv-modal-onPayment-VietQR" title="VietQR" hide-footer>
      <div class="d-block border-danger rounded mb-3 onPayment-w">
        <div class="b2c-payment">
          <div class="d-block p-2 onPayment-w">
            <p class="mb-2 b2c-badge"><b>Cách 1: </b>Chuyển khoản bằng mã QR Mở App Ngân Hàng Quét QRCode</p>
            <p class="text-center mb-2">
              <img :src="vietQr.qr_link" alt="VietQR-payment" style="width: 230px; height: auto;">
            </p>
            <div class="text-center img__btn text-decoration-underline mb-2" style="color: #72A0B3;"
                 @click="downloadFile()">
              Tải xuống
            </div>
            <div class="b2c-note d-flex mb-2">
              <div style="margin-top: -0.5rem;">
                <feather-icon size="24" icon="AlertTriangleIcon"/>
              </div>
              <p class="b2c-badge ml-1"><b>Lưu ý: Bạn vui lòng không thay đổi nội dung thanh toán</b></p>
            </div>
            <div class="line"></div>
          </div>
          <div class="d-block p-2 onPayment-w">
            <p class="mb-2 b2c-badge"><b>Cách 2: </b>Chuyển khoản THỦ CÔNG theo thông tin</p>
            <div class="d-flex mb-2">
              <p class="b2c-badge" style="width: 50%;">Ngân hàng:</p>
              <p class="b2c-badge"><b>{{ vietQr.vietqr_bank_name }}</b></p>
            </div>
            <div class="d-flex mb-2">
              <p class="b2c-badge" style="width: 50%;">Chủ tài khoản:</p>
              <p class="b2c-badge"><b>{{ vietQr.vietqr_account_name }}</b></p>
            </div>
            <div class="d-flex mb-2">
              <p class="b2c-badge" style="width: 80%;">Số tài khoản:</p>
              <p class="b2c-badge" style="width: 60%;"><b>{{ vietQr.vietqr_account_no }}</b></p>
              <div @click="myCopyText(vietQr.vietqr_account_no)">
                <img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
              </div>
            </div>
            <div class="d-flex mb-2">
              <p class="b2c-badge" style="width: 80%;">Số tiền:</p>
              <p class="b2c-badge" style="width: 60%;"><b>{{ vietQr.amount }}đ</b></p>
              <div @click="myCopyText(vietQr.amount)">
                <img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
              </div>
            </div>
            <div class="d-flex mb-2">
              <p class="b2c-badge" style="width: 80%;">Nội dung:</p>
              <p class="b2c-badge" style="width: 60%;"><b style="color: #EA0029;">{{ vietQr.addInfo }}</b></p>
              <div @click="myCopyText(vietQr.addInfo)">
                <img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
              </div>
            </div>
            <div class="b2c-note d-flex">
              <div style="margin-top: 0.5rem;">
                <feather-icon size="24" icon="AlertTriangleIcon"/>
              </div>
              <p class="b2c-badge ml-1"><b>Lưu ý: Bạn vui lòng nhập đúng nội dung chuyển khoản <b
                  style="color: #EA0029;">{{ vietQr.addInfo }}</b>. Bạn sẽ nhận được email (hoặc SMS) xác nhận khi giao
                dịch thành công.</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end payment-center b2c-payment">
        <p v-if="isTextVietQR" class="b2c-badge mb-3">Đơn hàng của bạn sẽ được xác nhận thanh toán tự động trong 1-5
          phút tới,
          chúng tôi sẽ gửi Tin nhắn Zalo/SMS xác nhận đến số điện thoại liên lạc khi đã hoàn tất</p>
        <b-button class="mb-3" v-if="isOnVietQR"
                  style="background: #ed1f24 !important; border-radius: 3rem !important; width: 276px; height: 48px;"
                  @click="onPaymentVietQR">
          Tôi đã thanh toán
        </b-button>
      </div>
    </b-modal>
    <form id="payment_form" action="https://sandbox.vnpayment.vn/isp-svc/payment/pay" method="post"
          style="display: none">
      <input name="ispTxnId" id="ispTxnId" type="text"/>
      <input name="dataKey" id="dataKey" type="text"/>
      <input name="tmnCode" id="tmnCode" type="text"/>
    </form>
    <!--detai esim  -->
    <ModalDetaiEsim :isShowEsim="isShowEsim"/>
  </div>
</template>

<script>
const SearchForMonth = () => import('@/components/SearchForMonth.vue');
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, min, email} from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import OtpInput from "@bachdgvn/vue-otp-input";
import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
  BFormTextarea,
} from "bootstrap-vue";

import {WebCam} from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import {v4 as uuidv4} from "uuid";
import {$ctx} from "@themeConfig";
import {forEach} from "postcss-rtl/lib/affected-props";
import {formatNumber} from "@core/utils/utils";
import SurveyCES from "@/components/SurveyCES.vue"
import LuckyNumber from "@/components/LuckyNumber.vue"
import LuckyNumberMB from '@/components/LuckyNumberMB.vue'
import ModalDetaiEsim from '@/components/ModalDetaiEsim.vue'
import {mapMutations} from 'vuex';
import {value} from "dom7";
import InputRequiredPhone from '@/components/InputRequiredPhone.vue';
import moment from "moment";
import _ from "lodash";

let debounceHandle = null;
let debounceHandleSaveData = null;
Vue.component("v-select", vSelect);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
      (first4 === "8487" && value.length !== 11) ||
      (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});
extend("space", (value) => {
  return value.indexOf(" ") > 0;
});
extend("min0", (value) => {
  return value > 0;
});

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
    require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    InputRequiredPhone,
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
    SurveyCES,
    SearchForMonth,
    LuckyNumber,
    LuckyNumberMB,
    ModalDetaiEsim,
    BFormTextarea
  },
  data() {
    return {
      isMountedChange: false,
      mgmPhone: '',
      isMGMSuccess: false,
      intervalSearchCode: null,
      timeoutfee: null,
      searchCode: '',
      resultCheckCode: {},
      isNotFound: false,
      isOnVietQR: true,
      isTextVietQR: false,
      frameType: '',
      framePaymentMethods: "",
      framePaymentMethodsList: [
        {id: 2, text: 'Chuyển khoản'},
        {id: 1, text: 'COD'}
      ],
      SuccessMessage: '',
      isStatusGPMN: false,
      isShowEsim: false,
      isTotalSimPrice: 0,
      totalSalePrice: 0,
      totalPrice: 0,
      isStatusPromotionCode: false,
      isStatusUrbox: false,
      isSaleEvoucher: 0,
      promotionCode: '',
      isSeachMonth: false,
      dataSearchMonthly: [],
      isPaid: false,
      countDown: 0,
      payment_text: 'Thanh toán',
      numberExceptCollapse: true,
      storeCollapse: true,
      step: 1,
      deviceId: null,
      isLoading: false,
      isLoadingFee: false,
      currentPhone: "",
      SearchString: "",
      Slug: "",
      required,
      min,
      email,
      totalRecords: 0,
      rows: [],
      cart_info: {
        deliver_work_ship: null,
        shipment_type: "home",
        ghtk_fee: 0,
        _track: "T_" + Math.random().toString(36).slice(2),
      },
      cart: [],
      serverParams: {
        columnFilters: {
          vip: true,
          normal: true,
          year: 0,
          price1: 0,
          simCategory: 0,
          except: [],
        },
        extra: {price_sort: "default"},
        sort: [],
        page: 1,
        pageSize: 10,
      },
      prices: [
        {id: "default", text: "Giá mặc định"},
        {id: "random", text: "Giá ngẫu nhiên"},
        {id: "asc", text: "Giá tăng dần"},
        {id: "desc", text: "Giá giảm dần"},
        //{id:'1',text:'Dưới 500 nghìn'},
        //{id:'2',text:'500 nghìn - 1 triệu'},
        //{id:'3',text:'Trên 1 triệu'},
      ],
      eCommerceNotes: '',
      ecommerces: 0,
      eCommerceId: [],
      deliveryTimes: [
        {id: 1, text: "Sáng: 8h00 - 11h30"},
        {id: 2, text: "Chiều: 14h00 - 17h00"},
      ],
      DataPackages: [],
      simGroups: [],
      years: [],
      Cities: [],
      Districts: [],
      Wards: [],
      Hamlets: [],
      CityId: 0,
      DistrictId: 0,
      WardId: 0,
      HamletId: 0,
      vietQr: {},
      VnPayResponse: null,
      MoMoResponse: null,
      ZaloResponse: null,
      codResponse: null,
      VietQRResponse: null,
      paymentMethod: "",
      isZaloMono: false,
      Stores: [],
      PackInfo: {
        MAY: "<b>4GB/ngày</b>, miễn phí gọi nội mạng VinaPhone & iTel",
        ITEL100:
            "<b>1GB/ngày</b> (tối đa 20GB/tháng), miễn phí 100 phút gọi nội mạng iTel và Vinaphone",
        ITEL149:
            "<b>3GB/ngày</b> (tối đa 25GB/tháng), miễn phí 1000 phút gọi nội mạng Vinaphone và iTel",
        ITEL199:
            "<b>5GB/ngày</b> (tối đa 30GB/tháng), miễn phí 1000 phút gọi nội mạng Vinaphone và iTel",
      },
      PromotionInfo: {
        MAY: {
          flow: "Miễn phí <b>4GB/ngày</b>",
          extraText: "Miễn phí gọi nội mạng VinaPhone & iTel"
        },
        ITEL100: {
          flow: "Miễn phí <b>1GB/ngày</b> (tối đa 20GB/tháng)",
          extraText: "Miễn phí 100 phút gọi nội mạng iTel và Vinaphone"
        },
        ITEL149: {
          flow: "Miễn phí <b>3GB/ngày</b> (tối đa 25GB/tháng)",
          extraText: "Miễn phí 1000 phút gọi nội mạng Vinaphone và iTel"
        },
        ITEL199: {
          flow: "Miễn phí <b>5GB/ngày</b> (tối đa 30GB/tháng)",
          extraText: "Miễn phí 1000 phút gọi nội mạng Vinaphone và iTel"
        },
      },
      vnPayErrors: [
        {id: "00", text: "Giao dịch thành công"},
        {
          id: "07",
          text: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
        },
        {
          id: "09",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
        },
        {
          id: "10",
          text: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần",
        },
        {
          id: "11",
          text: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "12",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
        },
        {
          id: "13",
          text: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "24",
          text: "Giao dịch không thành công do: Khách hàng hủy giao dịch",
        },
        {
          id: "51",
          text: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
        },
        {
          id: "65",
          text: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
        },
        {id: "75", text: "Ngân hàng thanh toán đang bảo trì."},
        {
          id: "79",
          text: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
        },
        {
          id: "99",
          text: "Lỗi thanh toán ngân hàng, quý khách vui lòng thử lại",
        },
      ],
      OrderInfo: {},
      isExpress: false,
      isFast: true,
      hasExpressShip: false,
      expressShipInfo: {
        ahamoveFee: 0,
        lastFee: 0,
        deleveryTime: "",
      },
      needUpdateAddress: true,
      activeTab: 0,
      previewOrderId: '',
      activeCOD: false,
      paymentMethodStatus: {
        "VIETQR": 1,
        "VNPAY_VNPAYQR": 1,
        "VNPAY_INTCARD": 1,
        "VNPAY_VNBANK": 1,
        "MOMO": 1,
        "ZALOPAY": 1,
        "COD": 1
      },
      searchPhone: localStorage.getItem("searchPhone"),
      checkIsInIframe: false
    };
  },
  watch: {
    cart_info: {
      handler(value) {
        if (value.ghtk_fee < 0) {
          value.ghtk_fee = 0;
        }
        if (value.fee < 0) {
          value.fee = 0;
        }
      },
      deep: true,
    },
    isReload() {
      this.serverParams = {
        columnFilters: {
          vip: true,
          normal: true,
          year: 0,
          price1: 0,
          simCategory: 0,
          except: [],
        },
        extra: {price_sort: "default"},
        sort: [],
        page: 1,
        pageSize: 10,
      }
    },
    paymentMethod: function (i, v) {
      if (i === "ZALO" || i === "MOMO") {
        this.isZaloMono = true;
      } else {
        this.isZaloMono = false
      }
      if (this.paymentMethod == 'COD') {
        this.payment_text = 'Đặt hàng'
        this.onClickFast();
        this.cart.forEach(i => i.eSIM = false);
      } else {
        this.payment_text = 'Thanh toán'
      }
    },
    $route(to, from) {
      this.changeState();
      if (to.params.step == undefined) {
        window.insider_object.page = {
          type: "mua-sim",
        };
        this.step = 1;
        this.cart = [];
        this.cart_info._track = "T_" + Math.random().toString(36).slice(2);
      }
      if (to.name == 'ket-thuc-mua-hang') {
        this.step = 4;
        if (this.$route.params.paymentResult == 'Thanh-cong') {
          window.insider_object.page = {
            type: "Confirmation",
          };
        }
      }
    },
    serverParams: {

      handler: function (newValue) {
        console.log("run param")
        this.search();
      },
      deep: true,
    },
    CityId: function (i, v) {
      if (this.needUpdateAddress) {
        this.DistrictId = 0;
      }
      this.timeoutSaveReceiverInfo();
      if (this.CityId > 0) {
        this.loading(true);
        useJwt
            .post("getMasterData/GHTK_Districts", {
              columnFilters: {CityId: this.CityId},
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.Districts = response.data.result;
              if (this.isMountedChange) {
                this.$refs.districtSelect.open = false
                this.$refs.districtSelect1.open = false
                setTimeout(() => {
                  this.isMountedChange = false
                }, 200)
              } else {
                this.$refs.districtSelect.open = true
                this.$refs.districtSelect1.open = true
              }
              this.loading(false);
            });
      }
    },
    DistrictId: function (i, v) {
      if (this.needUpdateAddress) {
        this.WardId = 0;
      }
      this.getGHTKFee();
      this.timeoutSaveReceiverInfo();
      if (this.DistrictId > 0) {
        this.loading(true);
        useJwt
            .post("getMasterData/GHTK_Wards", {
              columnFilters: {DistrictId: this.DistrictId},
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.Wards = response.data.result;
              if (this.isMountedChange) {
                setTimeout(() => {
                  this.isMountedChange = false
                }, 200)
                this.$refs.WardsSelect.open = false
                this.$refs.WardsSelect1.open = false
              } else {
                this.$refs.WardsSelect.open = true
                this.$refs.WardsSelect1.open = true
              }
              this.loading(false);
            });
      }
    },
    WardId: async function (i, v) {
      if (this.needUpdateAddress) {
        this.HamletId = 0;
      }
      if (this.WardId > 0) {
        this.loading(true);
        await useJwt.post('getMasterData/GHTK_Hamlets', {
          columnFilters: {WardId: this.WardId},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l
        }).then(response => {
          this.Hamlets = response.data.result;
          this.loading(false);
        })

      }
      this.needUpdateAddress = true;
      this.isLoadingFee = true;
      this.timeoutSaveReceiverInfo();
      // this.getShippingFee();
      await this.getGHTKFee()
    },
    HamletId: async function (i, v) {
      this.timeoutSaveReceiverInfo();
    },
    isFast: async function (i, v) {
      this.needUpdateAddress = true;
      this.isLoadingFee = true;
      this.timeoutSaveReceiverInfo();
      if (this.isFast) {
        this.getGHTKFee()
      }
    },
    "cart_info.fullName": {
      handler(value) {
        this.handleGetShippingFee();
        this.timeoutSaveReceiverInfo();
      },
    },
    "cart_info.addr": {
      handler(value) {
        this.handleGetShippingFee();
        this.timeoutSaveReceiverInfo();
      },
    },
    "cart_info.ContactPhone": {
      handler(value) {
        this.handleGetShippingFee();
        this.timeoutSaveReceiverInfo();
      },
    },
    "cart_info.email": {
      deep: true,
      handler(value) {
        this.timeoutSaveReceiverInfo();
      },
    },
    activeTab: async function (i, v) {
      this.timeoutSaveReceiverInfo();
    },
    isExpress: async function (i, v) {
      this.timeoutSaveReceiverInfo();
    },
    hasExpressShip: async function (i, v) {
      this.timeoutSaveReceiverInfo();
    },
    storeCollapse: async function (i, v) {
      this.timeoutSaveReceiverInfo();
    },
    step: function (newValue, oldValue) {
      this.tracking(localStorage.trackId, this.step);
      localStorage.userTrackStep = newValue
    },
  },
  created() {
    this.getMGMDiscount()
    this.getSearchMonthly();
    if (this.$route.query && this.$route.query.sim_category_id_sort && this.$route.query.sim_category_id_sort !== 'null') {
      this.serverParams.extra = {
        sim_category_id_sort: Number(this.$route.query.sim_category_id_sort),
        price_sort: "desc"
      }

    }
    if (this.$route.query.simCategory) {
      this.serverParams.columnFilters.simCategory = Number(this.$route.query.simCategory)
    }
    this.years.push({id: 0, text: "Tất cả ngày sinh"});
    for (var i = 0; i < 50; i++) {
      this.years.push({id: i + 1970, text: i + 1970});
    }
  },
  computed: {
    isHasSearchCode() {
      return this.searchCode.length > 0
    },
    isReload() {
      return this.$store.state.app.isReload;
    },
    isOnIframe() {
      return this.$store.getters[`app/isOnIframe`]
    },
    isChooseSim() {
      return this.$store.getters[`app/getIsChooseSim`]
    },
    getSource() {
      return this.$store.getters[`app/getSource`]
    },
    isShowExpressText() {
      return this.CityId === 126 || this.CityId === 1 || this.CityId === 129
    },
    totalDiscountSim() {
      let total = 0;
      this.cart.forEach(i => {
        //thang dau sim thuong
        if (i.PackPrice != this.DataPackages.find((m) => m.Name == i.Pack).Price) {
          total += 32000
        }
        // sim so dep 50k
        if (i.isBeautifulSimNumber) {
          total += 160000
        }
      })
      return total;
    },
    totalPriceBeforeDiscount() {
      let total = 0;
      this.cart.forEach(i => {
        total += i.TraThang ? i.TotalPrice : i.Price;
        if (i.eSIM) {
          total += 10000;
        }
        if (i.isBeautifulSimNumber) {
          total += 160000
        }
        if (i.PackPrice != this.DataPackages.find((m) => m.Name == i.Pack).Price) {
          total += this.DataPackages.find((m) => m.Name == i.Pack).Price
          total += 50000
        } else {
          total += i.SimPrice;
          total += i.PackPrice
        }
      })
      return total;
    },
    totalDiscountPromotion() {
      let total = 0;
      if (this.isMGMSuccess) {
        this.cart.forEach(i => {
          total += 15000
        })
      } else {
        total = 0
      }
      return total
    },
    totalSuccessTopup() {
      let total = 0;
      if (this.isMGMSuccess) {
        this.cart.forEach(i => {
          total += 20000
        })
      } else {
        total = 0
      }
      return total
    },
    isDisablePayment() {
      if (!this.paymentMethod) {
        return true
      }
      if (this.cart_info.fee < 0) {
        return true
      }
      if (this.isLoadingFee) {
        return true
      }
      if (this.isFullEsim() && this.paymentMethod == 'COD') {
        return true
      }
      if (!this.isFullEsim() && this.activeTab === 0 && this.cart_info.fee <= 0) {
        return true
      }
      if (!this.isFullEsim() && this.cart_info.shipment_type === 'home' && (this.CityId <= 0 || this.DistrictId <= 0 || this.WardId <= 0)) {
        return true
      }
      return false
    }
  },
  async mounted() {
    if (this.$route.params.step) {
      this.step = this.$route.params.step;
    }
    _.throttle(this.onOrder, 3000)
    this.PromotionCode = '';
    this.onEcommerceChange();
    if (this.searchPhone && this.searchPhone.length > 0 && this.$route.path === '/mua-hang') {
      this.SearchString = this.searchPhone
      this.serverParams.columnFilters.search = this.searchPhone;
      this.serverParams.page = 1;
    }
    window.addEventListener("message", this.handleMessageEventer, false);
    if (this.$route.query.iframe) {
      this.frameType = this.$route.query.type;
      this.$store.dispatch("app/setIframe", true);
      this.hiddenHeaderAndFooter();
      this.paymentMethod = 'BANKTRANFER'
      this.isPaid = false
    }
    this.checkIsInIframe = window === window.parent ? false : true;
    if (this.checkIsInIframe) {
      this.$store.dispatch("app/setIframe", true);
      this.hiddenHeaderAndFooter();
      if (window.hideLayout) {
        window.hideLayout()
      }
    }
    if (this.$route.query.isChooseSim) {
      this.$store.dispatch("app/setIsChooseSim", true);
    }
    if (this.$route.query.m) {
      this.$store.dispatch("app/setSource", 'myitel');
    }
    if (this.$route.query.from == "appmyitel") {
      this.checkIsInIframe = true;
      this.$store.dispatch("app/setIframe", false);
      this.hiddenHeaderAndFooter();
      if (window.hideLayout) {
        window.hideLayout()
      }
      this.$store.dispatch("app/setSource", 'MY ITEL');
      if (this.$route.query.searchCode) {
        this.searchCode = this.$route.query.searchCode;
        this.fetchPackFromCode(this.searchCode)
        localStorage.setItem("searchCode", this.searchCode);

      }
    }
    this.countDownTimer();
    // insider_object
    window.insider_object = window.insider_object || {};
    if (this.$router.currentRoute.path === "/mua-hang/buoc/2" || this.$router.currentRoute.path === "/mua-hang/buoc/3") {
      window.insider_object.page = {
        type: "AddToCart",
      };
    } else {
      window.insider_object.page = {
        type: "mua-sim",
      };
    }


    if (localStorage.trackId == undefined) {
      localStorage.trackId = Math.random().toString(36).slice(2);
    }

    await this.fetch();
    await this.changeState();
    await this.getFeeOnMount()
    this.serverParams.page = 1;

    if (this.$route.query.searchCode) {
      this.searchCode = this.$route.query.searchCode;
      this.fetchPackFromCode(this.searchCode)
    }

    //  payment with payment
    if (this.$route.name == "mua-hang-hoan-thanh") {
      let paymentResult = "That-bai";
      console.log("router check mua-hang-hoan-thanh", this.$route.query)
      this.step = 4;
      this.cart = [];
      sessionStorage.removeItem("B2CDataForm");
      sessionStorage.removeItem("B2CSimDataForm");
      sessionStorage.removeItem("INSTALL_PAYMENT_FORM");
      sessionStorage.removeItem("PAYMENT_FORM");
      console.log("router check mua-hang-hoan-thanh", this.$route.params.payment)
      window.insider_object.page = {
        type: "Mua-sim",
      };
      // payment with Zalo Pay
      if (this.$route.params.payment == "ZALOPAY") {
        this.ZaloResponse = this.$route.query;
        if (this.ZaloResponse.status == "1") {
          paymentResult = "Thanh-cong";
          window.insider_object.page = {
            type: "Confirmation",
          };
          this.GA4(this.$route.query.apptransid, this.$route.query.amount, 15000, 'ZALOPAY', this.OrderInfo.Phones);
          useJwt
              .post("zalopay-update", {
                amount: this.$route.query.amount,
                appid: this.$route.query.appid,
                apptransid: this.$route.query.apptransid,
                bankcode: this.$route.query.bankcode,
                checksum: this.$route.query.checksum,
                discountamount: this.$route.query.discountamount,
                pmcid: this.$route.query.pmcid,
                status: this.$route.query.status,
              })
              .then((response) => {
                if (response.data.result.length > 0) {
                  this.OrderInfo = response.data.result[0];
                  // add Inserder
                  window.insider_object.user = {
                    uuid: uuidv4(),
                    name: this.OrderInfo?.FullName,
                    surname: "",
                    username: this.OrderInfo?.FullName,
                    email: this.OrderInfo?.email,
                    phone_number: this.OrderInfo.ContactPhone,
                    language: "vi",
                    returning: true,
                  };

                }
              })
              .catch((err) => {
                this.loading(false);
                this.$toast.error(
                    "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                    {icon: true, closeButton: "button"}
                );
              });
          this.$router.push({
            name: 'ket-thuc-mua-hang',
            params: {
              orderId: this.$route.query.apptransid,
              paymentResult: paymentResult,
              payment: this.$route.params.payment,
            },
            query: this.$route.query
          })
          return;
        }
        console.log("router check zalo res", this.$route.query.apptransid, paymentResult, this.$route.params.payment, this.$route.query)
        this.$router.push({
          name: 'ket-thuc-mua-hang',
          params: {
            orderId: this.$route.query.apptransid,
            paymentResult: paymentResult,
            payment: this.$route.params.payment,
          },
          query: this.$route.query
        })
        return;
      }
      // payment with MoMo
      if (this.$route.params.payment == "MOMO") {
        this.MoMoResponse = this.$route.query;
        // if MOMO code = 0 => order sucress
        if (this.MoMoResponse?.resultCode == "0") {
          paymentResult = "Thanh-cong";
          window.insider_object.page = {
            type: "Confirmation",
          };
        } else {
          paymentResult = "That-bai";
        }
        this.GA4(this.MoMoResponse.orderId, this.MoMoResponse.amount, 15000, 'MOMO', this.OrderInfo.Phones);
        useJwt
            .post("momo-update", this.$route.query)
            .then((response) => {
              if (response.data.result.length > 0) {
                this.OrderInfo = response.data.result[0];
                // add Inserder
                window.insider_object.user = {
                  uuid: uuidv4(),
                  name: this.OrderInfo?.FullName,
                  surname: "",
                  username: this.OrderInfo?.FullName,
                  email: this.OrderInfo?.email,
                  phone_number: this.OrderInfo.ContactPhone,
                  language: "vi",
                  returning: true,
                };

              }
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
        console.log("push router MOMO")
        this.$router.push({
          name: 'ket-thuc-mua-hang',
          params: {
            orderId: this.$route.query.orderId,
            paymentResult: paymentResult,
            payment: this.$route.params.payment,
          },
          query: this.$route.query
        })
        console.log("push router MOMO 2")
        return;
      }

      if (this.$route.params.payment == "COD") {
        paymentResult = "Thanh-cong";
        this.codResponse = JSON.parse(this.$route.query.cart_info);
        this.GA4(this.codResponse.vnp_TxnRef, this.codResponse.TotalPrice, 15000, 'COD', this.codResponse.Phones);
        this.$router.push({
          name: 'ket-thuc-mua-hang',
          params: {
            orderId: this.codResponse.vnp_TxnRef,
            paymentResult: paymentResult,
            payment: this.$route.params.payment,
          },
          query: this.$route.query
        })
        return;
      }

      // payment with VNPay
      this.VnPayResponse = this.$route.query;
      // if VN pay code = 00 => order sucress
      if (this.VnPayResponse.orderType === '10') {
        this.checkIsInIframe = true;
        this.hiddenHeaderAndFooter();
        if (window.hideLayout) {
          window.hideLayout()
        }
      }
      if (this.VnPayResponse.vnp_ResponseCode == "00") {
        paymentResult = "Thanh-cong"
        localStorage.removeItem("cart");
        window.insider_object.page = {
          type: "Confirmation",
        };
        this.GA4(this.VnPayResponse.vnp_TxnRef, this.VnPayResponse.vnp_Amount / 100, 15000, 'VNPAY', this.codResponse?.Phones || []);

        if (this.VnPayResponse.orderType === '10') {
          this.intervalSearchCode = setInterval(() => {
            this.fetchPackFromCode(localStorage.getItem("searchCode"))
          }, 1000)
          return
        }

      } else {
        paymentResult = "That-bai"
      }
      useJwt
          .post(this.$route.params.payment == 'VNPAYISP' ? "vnpay-isp/update" : "vnpay-update", this.$route.query)
          .then((response) => {
            console.log(response.data.result);
            if (response.data.result.length > 0) {
              this.OrderInfo = response.data.result[0];
              if (this.$route.params.payment == 'VNPAYISP' && this.OrderInfo.ispInfo) {
                this.OrderInfo.ispInfo = JSON.parse(this.OrderInfo.ispInfo)
              }
              // add Inserder
              window.insider_object.user = {
                uuid: uuidv4(),
                name: this.OrderInfo?.FullName,
                surname: "",
                username: this.OrderInfo?.FullName,
                email: this.OrderInfo?.email,
                phone_number: this.OrderInfo.ContactPhone,
                language: "vi",
                returning: true,
              };
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      //tracking khi hoàn thành thanh toán
      //this.JITSU('Exit VnPay');
      /*rudderanalytics.track('B2C - Exit VnPay', {
          step: this.step,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          VnPayResponse:this.VnPayResponse,
          cart_info: this.cart_info,
        },
        () => {console.log("in track call");}
      );*/
      console.log("check redirect")
      console.log("router check zalo res", this.$route.query.apptransid, paymentResult, this.$route.params.payment, this.$route.query)
      this.$router.push({
        name: 'ket-thuc-mua-hang',
        params: {
          orderId: this.$route.query.vnp_TxnRef,
          paymentResult: paymentResult,
          payment: this.$route.params.payment,
        },
        query: this.$route.query
      })
    }
    if (this.$route.name == "ket-thuc-mua-hang") {
      console.log("check ket-thuc-mua-hang", window.insider_object.page)
      this.step = 4;
      this.tracking(localStorage.trackId, this.step);
      this.cart = [];
      sessionStorage.removeItem("B2CDataForm");
      sessionStorage.removeItem("B2CSimDataForm");
      sessionStorage.removeItem("INSTALL_PAYMENT_FORM");
      sessionStorage.removeItem("PAYMENT_FORM");
      window.insider_object.page = {
        type: "Mua-sim",
      };
      // payment with Zalo Pay
      if (this.$route.params.payment == "ZALOPAY") {
        this.ZaloResponse = this.$route.query;
        if (this.ZaloResponse.status == "1") {
          window.insider_object.page = {
            type: "Confirmation",
          };
          this.GA4(this.$route.query.apptransid, this.$route.query.amount, 15000, 'ZALOPAY', this.OrderInfo.Phones);
          useJwt
              .post("zalopay-update", {
                amount: this.$route.query.amount,
                appid: this.$route.query.appid,
                apptransid: this.$route.query.apptransid,
                bankcode: this.$route.query.bankcode,
                checksum: this.$route.query.checksum,
                discountamount: this.$route.query.discountamount,
                pmcid: this.$route.query.pmcid,
                status: this.$route.query.status,
              })
              .then((response) => {
                if (response.data.result.length > 0) {
                  this.OrderInfo = response.data.result[0];
                  // add Inserder
                  window.insider_object.user = {
                    uuid: uuidv4(),
                    name: this.OrderInfo?.FullName,
                    surname: "",
                    username: this.OrderInfo?.FullName,
                    email: this.OrderInfo?.email,
                    phone_number: this.OrderInfo.ContactPhone,
                    language: "vi",
                    returning: true,
                  };

                }
              })
              .catch((err) => {
                this.loading(false);
                this.$toast.error(
                    "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                    {icon: true, closeButton: "button"}
                );
              });
          return;
        }
      }
      // payment with VIETQR
      if (this.$route.params.payment == "VIETQR") {
        this.VietQRResponse = this.$route.query.VietQRResponse;
        window.insider_object.page = {
          type: "Confirmation",
        };
        return;
      }
      // payment with MoMo
      if (this.$route.params.payment == "MOMO") {
        this.MoMoResponse = this.$route.query;
        // if MOMO code = 0 => order sucress
        if (this.MoMoResponse?.resultCode == "0") {
          window.insider_object.page = {
            type: "Confirmation",
          };
        }
        this.GA4(this.MoMoResponse.orderId, this.MoMoResponse.amount, 15000, 'MOMO', this.OrderInfo.Phones);
        useJwt
            .post("momo-update", this.$route.query)
            .then((response) => {
              if (response.data.result.length > 0) {
                this.OrderInfo = response.data.result[0];
                // add Inserder
                window.insider_object.user = {
                  uuid: uuidv4(),
                  name: this.OrderInfo?.FullName,
                  surname: "",
                  username: this.OrderInfo?.FullName,
                  email: this.OrderInfo?.email,
                  phone_number: this.OrderInfo.ContactPhone,
                  language: "vi",
                  returning: true,
                };

              }
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
        return;
      }

      if (this.$route.params.payment == "COD") {
        this.codResponse = JSON.parse(this.$route.query.cart_info);
        if (this.$route.query.status == '1') {
          window.insider_object.page = {
            type: "Confirmation",
          };
        }
        this.GA4(this.codResponse.vnp_TxnRef, this.codResponse.TotalPrice, 15000, 'COD', this.codResponse.Phones);
        return;
      }

      // payment with VNPay
      this.VnPayResponse = this.$route.query;
      // if VN pay code = 00 => order sucress
      if (this.VnPayResponse.vnp_ResponseCode == "00") {
        localStorage.removeItem("cart");
        window.insider_object.page = {
          type: "Confirmation",
        };
        if (this.VnPayResponse.orderType === '10') {
          this.checkIsInIframe = true;
          this.hiddenHeaderAndFooter();
          if (window.hideLayout) {
            window.hideLayout()
          }
        }
        this.GA4(this.VnPayResponse.vnp_TxnRef, this.VnPayResponse.vnp_Amount / 100, 15000, 'VNPAY', this.codResponse?.Phones || []);
      }
      useJwt
          .post(this.$route.params.payment == 'VNPAYISP' ? "vnpay-isp/update" : "vnpay-update", this.$route.query)
          .then((response) => {
            console.log(response.data.result);
            if (response.data.result.length > 0) {
              this.OrderInfo = response.data.result[0];
              if (this.$route.params.payment == 'VNPAYISP' && this.OrderInfo.ispInfo) {
                this.OrderInfo.ispInfo = JSON.parse(this.OrderInfo.ispInfo)
              }
              // add Inserder
              window.insider_object.user = {
                uuid: uuidv4(),
                name: this.OrderInfo?.FullName,
                surname: "",
                username: this.OrderInfo?.FullName,
                email: this.OrderInfo?.email,
                phone_number: this.OrderInfo.ContactPhone,
                language: "vi",
                returning: true,
              };
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      //tracking khi hoàn thành thanh toán
      //this.JITSU('Exit VnPay');
      /*rudderanalytics.track('B2C - Exit VnPay', {
          step: this.step,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          VnPayResponse:this.VnPayResponse,
          cart_info: this.cart_info,
        },
        () => {console.log("in track call");}
      );*/
    }
    this.tracking(localStorage.trackId, this.step);
    //Tracking khi vào trang
    //this.JITSU('Entry Page');
    /*rudderanalytics.track('B2C - Entry Page', {
        step: this.step,
        SearchString: this.SearchString,
        serverParams: this.serverParams,
        cart_info: this.cart_info,
      },
      () => {console.log("in track call");}
    );*/
    // console.log("cartInfo", this.cart_info)
  },
  beforeDestroy() {
    localStorage.removeItem('searchPhone')
    window.removeEventListener("message", this.handleMessageEventer);
  },
  methods: {
    hiddenHeaderAndFooter() {
      const headH = document.querySelector('.fs-header')
      if (headH) {
        headH.style.display = 'none'
      }
      const footer = document.querySelector('.fs-footer')
      if (footer) {
        footer.style.display = 'none'
      }
      let css = '#cs-live-chat { display: none !important; }',
          head = document.head || document.getElementsByTagName('head')[0],
          style = document.createElement('style');
      style.type = 'text/css';
      head.appendChild(style);

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
    timeoutSaveReceiverInfo() {
      if (debounceHandleSaveData) {
        clearTimeout(debounceHandleSaveData)
      }

      debounceHandleSaveData = setTimeout(() => {
        this.handleSaveReceiverInfo()
      }, 500)
    },
    handleSaveReceiverInfo() {
      localStorage.setItem(
          "ReceiverInfo",
          JSON.stringify({
            cart_info: this.cart_info,
            CityId: this.CityId,
            DistrictId: this.DistrictId,
            WardId: this.WardId,
            HamletId: this.HamletId,
            paymentMethod: this.paymentMethod,
            isFast: this.isFast,
            isExpress: this.isExpress,
            hasExpressShip: this.hasExpressShip,
            activeTab: this.activeTab,
            storeCollapse: this.storeCollapse,
            expiedDate: moment().add(30, "days").format("DD/MM/YYYY").toString(),
          })
      )
    },
    mapSim() {
      this.resultCheckCode = {
        ...this.resultCheckCode,
        code: this.searchCode,
        package: this.serverParams.columnFilters.packName,
        msisdn: this.cart[0].Phone,
      }
      useJwt.post('sim/map-sim', this.resultCheckCode).then(res => {
        const data = res.data
        if (data.code === 200) {
          this.$router.push({name: 'ket-thuc-chon-so', query: res.data.result})
        } else if (data.code === 201) {
          document.location = data.result.paymentUrl
        }
      }).catch(err => {
        this.$toast.error("Có lỗi xảy ra vui lòng thử lại sau", {icon: true, closeButton: "button"});
      })

    },
    onFramePaymentMethodChange() {
      if (this.framePaymentMethods === 1) {
        this.isExpress = false;
        this.isFast = true;
      }
    },
    fetchPackFromCode(code) {
      useJwt.get('sim/check-code/' + code).then(res => {
        this.serverParams.columnFilters.packName = res.data.result.package
        this.serverParams.extra.price_sort = "asc";
        this.resultCheckCode = res.data.result
        if (this.resultCheckCode.type !== 1) {
          clearInterval(this.intervalSearchCode)
          this.$router.push({name: 'ket-thuc-chon-so', query: res.data.result})
        }
      }).catch(err => {
        this.$toast.error("Có lỗi xảy ra vui lòng thử lại sau", {icon: true, closeButton: "button"});
      })
    },
    async rePickPhoneNum() {
      await this.$router.push('/mua-hang?from=appmyitel&searchCode=' + localStorage.getItem("searchCode"))
      this.$router.go(this.$router.currentRoute)
    },
    myCopyText(textValue) {
      navigator.clipboard.writeText(textValue);
      this.$toast.success("Sao chép thành công", {icon: true});
    },
    async downloadFile() {
      const blob = await (await fetch(this.vietQr.qr_link)).blob();
      const url = URL.createObjectURL(blob);
      Object.assign(
          document.createElement('a'),
          {
            href: url,
            download: this.vietQr.addInfo + '.jpg'
          }).click();
      URL.revokeObjectURL(url);
    },
    onPaymentVietQR() {
      this.loading(true)
      this.isTextVietQR = true;
      this.isOnVietQR = false;
      useJwt.get(`get-order-status/` + this.cart_info.vnp_TxnRef)
          .then((response) => {
            this.VietQRResponse = response.data.result;
            this.$router.push({
              name: 'ket-thuc-mua-hang',
              params: {
                orderId: this.cart_info.vnp_TxnRef,
                paymentResult: 'Thanh-cong',
                payment: 'VIETQR',
              },
              query: this.$route.query
            })
            this.$bvModal.hide('bv-modal-onPayment-VietQR')
          }).catch((err) => {
        this.loading(false)
        this.$toast.error("Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!", {
          icon: true,
          closeButton: "button"
        });
      });
    },
    onEcommerceChange() {
      useJwt.get('platform_list')
          .then((response) => {
            this.eCommerceId = response.data.result;
          }).catch((err) => {
        this.loading(false);
        this.$toast.error("Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!", {
          icon: true,
          closeButton: "button"
        });
      });
    },
    handleSearch() {
      if (this.$route.query?.code && this.serverParams.columnFilters.search && !this.SearchString) {
        this.$router.push({path: "/mua-hang"})
      }
      this.serverParams.columnFilters.search = this.SearchString;
      this.serverParams.page = 1;
      this.search();
    },
    handlePledge() {
      delete this.serverParams.columnFilters.simTagGroup;
      delete this.serverParams.columnFilters.simTag;
      delete this.serverParams.columnFilters.isVip;
      this.serverParams.columnFilters.isSoCamKet = true;
      this.serverParams.page = 1;
      this.search();
    },
    handleSimVip() {
      delete this.serverParams.columnFilters.simTagGroup;
      delete this.serverParams.columnFilters.simTag;
      delete this.serverParams.columnFilters.isSoCamKet;
      this.serverParams.columnFilters.isVip = true;
      this.serverParams.page = 1;
      this.search();
    },
    handlePromotionCode() {
      this.totalSalePrice = 0;
      this.isStatusPromotionCode = false;
      this.isMGMSuccess = false;
    },
    closePopup() {
      this.totalSalePrice = 0
      this.$bvModal.hide('bv-modal-example');
      this.$bvModal.hide('bv-modal-sim-data');
      this.isStatusPromotionCode = false
      this.promotionCode = '';
    },
    getCreateNullPrice() {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      useJwt.post("create-null-price", {
        Phone: this.cart_info.ContactPhone,
        PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
        MGT: this.isMGMSuccess ? promotionCode : null,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
        ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
      }).then((response) => {
        this.cart_info.vnp_TxnRef = response.data.result;
        this.tracking(localStorage.trackId, "10");
        localStorage.userTrackStep = 10
        sessionStorage.setItem(
            "B2CDataForm",
            JSON.stringify({
              cart_info: this.cart_info,
              CityId: this.CityId,
              DistrictId: this.DistrictId,
              WardId: this.WardId,
              paymentMethod: this.paymentMethod,
              isFast: this.isFast,
              isExpress: this.isExpress,
              hasExpressShip: this.hasExpressShip,
              activeTab: this.activeTab,
              storeCollapse: this.storeCollapse,
              cart: this.cart,
            })
        );
        var c = JSON.parse(JSON.stringify(this.cart_info));
        var p = [];
        this.cart.forEach((item) => {
          p.push(item.Phone);
        });
        c.Phones = p.join(",");
        c.TotalPrice = this.getTotalPrice() - this.totalSalePrice
        try {
          if (this.activeTab === 1) {
            c.FullAddress = this.Stores.find(el => el.Id == this.cart_info.StoreId).Name
          } else {
            c.FullAddress = this.cart_info.addr + ", " + this.Wards.find(i => i.id == this.WardId).text + " - " + this.Districts.find(i => i.id == this.DistrictId).text + " - " + this.Cities.find(i => i.id == this.CityId).text;
          }
        } catch (err) {
        }
        document.location = '/mua-hang/hoan-thanh/COD?cart_info=' + JSON.stringify(c);
      }).catch((err) => {
        this.loading(false);
        this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!", {
          icon: true,
          closeButton: "button"
        });
      });
    },
    async getMGMDiscount() {
      if (!this.promotionCode || this.promotionCode.length == 0) return;
      this.loading(true)
      this.promotionCode = this.promotionCode.trim();
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      if (this.step == 1) return
      if (this.step == 3 || this.step == 2) {
        this.isMGMSuccess = false;
        this.isStatusPromotionCode = false;
      }
      await useJwt.post(`check-MGT`, {
        Phone: this.cart[0].Phone,
        MGT: promotionCode,
        PaymentChannel: this.paymentMethod,
        TotalPrice: this.getTotalPrice(),
        ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
        Source: this.getSource
      }).then((response) => {
        if (response.data.result) {
          this.isMGMSuccess = true;
          this.mgmPhone = response.data.result.phoneMGT.substring(2)
          this.SuccessMessage = 'Áp dụng mã giới thiệu thành công!'
          this.isStatusPromotionCode = true;
        } else {
          this.isMGMSuccess = false;
          this.getStatusPromotionCode();
        }
        this.loading(false)
      })
          .catch((err) => {
            if (err.response.status == 404) {
              this.isMGMSuccess = false;
              this.$toast.error(
                  err.response.data.message ? err.response.data.message : 'Áp dụng mã giới thiệu không thành công!',
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false)
          })
    },
    getStatusPromotionCode() {
      if (!this.promotionCode || this.promotionCode.length == 0) return;
      this.promotionCode = this.promotionCode.trim();
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      if (this.promotionCode?.length > 0) {
        useJwt.post(`check-promo-status-and-price`, {
          Phone: this.cart[0].Phone,
          PromotionCode: promotionCode,
          PaymentChannel: this.paymentMethod,
          TotalPrice: this.getTotalPrice() - this.totalSalePrice,
          ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
          Source: this.getSource
        }).then((response) => {
          if (response.data.result) {
            this.SuccessMessage = response.data.result.SuccessMessage ? response.data.result.SuccessMessage : ''
            this.isStatusPromotionCode = true;
            this.getSalePrice(response.data.result);
          }
          return true;
        })
            .catch((err) => {
              if (err.response.status == 404) {
                const res = this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }
      }
    },
    hadleByNow(item) {
      this.addToCart(item, 1)
    },
    handleTypeTags(item) {
      delete this.serverParams.columnFilters.simTagGroup;
      delete this.serverParams.columnFilters.isSoCamKet;
      delete this.serverParams.columnFilters.isVip;
      this.serverParams.columnFilters.simTag = item.Tag;
      this.serverParams.page = 1
      this.search();
    },
    handleTypeNumber(simType) {
      delete this.serverParams.columnFilters.simTag;
      delete this.serverParams.columnFilters.isSoCamKet;
      delete this.serverParams.columnFilters.isVip;
      this.serverParams.columnFilters.simTagGroup = simType;
      this.serverParams.page = 1
      this.search();
    },
    GA4(id_, total_, shipment_fee_, payment_type_, phones_) {
      useJwt.get("get-order-info/" + id_, this.$route.query).then((response) => {
        var o = response.data.result;
        var items = [];
        response.data.result.detail.forEach(m => {
          items.push({
            item_name: "Sim so",
            item_brand: "iTel",
            item_category: "Sim so",
            item_category2: "-",
            item_variant: "Sim vat ly",
            price: (m.Price * 1 + m.SimPrice * 1)
          });
          items.push({
            item_name: m.Package,
            item_brand: "iTel",
            item_category: "Goi cuoc",
            item_category2: "Goi thang",
            item_variant: "-",
            price: m.PackPrice
          })
        })
        console.log('GA4 *******:', items);
        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: id_,
            value: o.TotalPrice,
            shipping: o.ShipmentFee,
            currency: "VND",
            tax: 0,
            payment_type: o.PaymentChannel,
            shipping_tier: o.DeliveryType == 2 ? "Giao hang tan noi" : "Nhan tai cua hang",
            items: items
          }
        });
      });
      console.log('GA4 ok');
    },
    handleSeachMonth(id) {
      this.isSeachMonth = false;
      this.$router
          .push({
            name: 'mua-hang',
            query: {
              utm_source: this.$route.query.utm_source,
              utm_medium: this.$route.query.utm_medium,
              utm_content: this.$route.query.utm_content,
              utm_campaign: this.$route.query.utm_campaign,
              sim_category_id_sort: id,
            },
          })
          .catch(() => {
          });
      this.serverParams.extra.sim_category_id_sort = Number(id);
      this.serverParams.extra = {
        sim_category_id_sort: Number(id),
        price_sort: "desc"
      }
      delete this.serverParams.columnFilters.simCategory
      delete this.serverParams.columnFilters.simGroup1
    },
    getSearchMonthly() {
      const params = {
        "columnFilters": {
          "IsManySearchMonthly": 1
        },
        "sort": [],
        "page": 1,
        "pageSize": 10
      }
      useJwt.post('getMasterData/SimCategory', params)
          .then((response) => {
            this.dataSearchMonthly = response.data.result
            // this.dataSearchMonthly =  [
            //   {
            //     "Id": 18,
            //     "Name": "SIM Số Thường"
            //   },
            //   {
            //     "Id": 12,
            //     "Name": "SIM Đuôi Năm Sinh"
            //   },
            //   {
            //     "Id": 19,
            //     "Name": "SIM tài lộc"
            //   }
            // ]
          }).catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lỗi trong quá trình xứ lý",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    getGHTKFee() {
      this.isLoadingFee = true;
      const isExpress = this.isExpress ? 1 : 0
      const codFee = this.paymentMethod === 'COD' ? this.getTotalPriceNotFee() : 0
      clearTimeout(this.timeoutfee)
      this.timeoutfee = setTimeout(() => {
        useJwt
            .get(
                "shipment/fee?address=" +
                this.cart_info.addr +
                "&province=" +
                this.CityId +
                "&district=" +
                this.DistrictId +
                "&ward=" +
                this.WardId +
                "&fast=" + isExpress +
                "&cod=" + codFee
            )
            .then((response) => {
              this.cart_info = {
                ...this.cart_info,
                delivery_time: this.isExpress ? response.data.result.delivery_time : this.cart_info.delivery_time,
                ghtk_fee: response.data.result.discountedFee,
                fee: response.data.result.fee,
              };
              this.isLoadingFee = false;
            })
            .catch((err) => {
              this.cart_info = {
                ...this.cart_info,
                ghtk_fee: 0,
                fee: 0,
              };
              this.isLoadingFee = false;
            });
      }, 500)
    },
    getFeeOnMount() {
      useJwt
          .get(
              "shipment/fee?address=" +
              this.cart_info.addr +
              "&province=" +
              this.CityId +
              "&district=" +
              this.DistrictId +
              "&ward=" +
              this.WardId +
              "&fast=1&cod=0"
          )
          .then((response) => {
            this.cart_info = {
              ...this.cart_info,
              delivery_time: response.data.result.delivery_time,
              ghtk_fee: response.data.result.discountedFee,
              fee: response.data.result.fee,
            };
            this.isLoadingFee = false;
          })
          .catch((err) => {
            this.cart_info = {
              ...this.cart_info,
              ghtk_fee: 0,
              fee: 0,
            };
            this.isLoadingFee = false;
          });
    },
    handleCategory() {
      this.isSeachMonth = true;
      this.$router
          .push({
            name: 'mua-hang',
            query: {
              utm_source: this.$route.query.utm_source,
              utm_medium: this.$route.query.utm_medium,
              utm_content: this.$route.query.utm_content,
              utm_campaign: this.$route.query.utm_campaign,
              simCategory: this.serverParams.columnFilters.simCategory
            },
          })
          .catch(() => {
          });
      delete this.serverParams.extra.sim_category_id_sort
      this.serverParams.page = 1
      this.serverParams.extra.price_sort = "desc"
    },
    countDownTimer() {
      setTimeout(() => {
        this.countDown = this.countDown > 1 ? this.countDown - 1 : 0
        this.countDownTimer()
      }, 1000)
    },
    changeESIM() {
      this.isShowEsim = !this.isShowEsim
      if (this.cart.find(i => !i.eSIM) == undefined) {
        this.paymentMethod = "VNPAY";
      }


    },
    resendOTP() {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      useJwt.post("otp_cod/" + this.cart_info.ContactPhone, {
        Phone: this.cart_info.ContactPhone,
        PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
        MGT: this.isMGMSuccess ? promotionCode : null,
        PaymentChannel: this.paymentMethod,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
      }).then((response) => {
        this.$toast.success("Vui lòng kiểm tra tin nhắn!",
            {icon: true, closeButton: "button"}
        );
        this.countDown = 60;
        this.loading(false);
      }).catch((err) => {
        this.loading(false);
        this.$toast.error(err.response.data.message || "Có lỗi gửi OTP, Quý khách vui lòng thử lại!",
            {icon: true, closeButton: "button"}
        );
      });
    },
    handleCODNext(otp_) {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      console.log('OTP completed: ', otp_);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      this.cart_info.OTP = otp_;
      useJwt.post("create-cod-order", {
        Phone: this.cart[0].Phone,
        PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
        MGT: this.isMGMSuccess ? promotionCode : null,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
        Source: this.getSource
      }).then((response) => {
        this.cart_info.vnp_TxnRef = response.data.result;
        this.tracking(localStorage.trackId, "10");
        localStorage.userTrackStep = 10
        sessionStorage.setItem(
            "B2CDataForm",
            JSON.stringify({
              cart_info: this.cart_info,
              CityId: this.CityId,
              DistrictId: this.DistrictId,
              WardId: this.WardId,
              paymentMethod: this.paymentMethod,
              isFast: this.isFast,
              isExpress: this.isExpress,
              hasExpressShip: this.hasExpressShip,
              activeTab: this.activeTab,
              storeCollapse: this.storeCollapse,
              cart: this.cart,
            })
        );
        var c = JSON.parse(JSON.stringify(this.cart_info));
        var p = [];
        this.cart.forEach((item) => {
          p.push(item.Phone);
        });
        c.Phones = p.join(",");
        c.TotalPrice = this.getTotalPrice() - this.totalSalePrice - this.totalDiscountPromotion
        try {
          if (this.activeTab === 1) {
            c.FullAddress = this.Stores.find(el => el.Id == this.cart_info.StoreId).Name
          } else {
            c.FullAddress = this.cart_info.addr + ", " + this.Wards.find(i => i.id == this.WardId).text + " - " + this.Districts.find(i => i.id == this.DistrictId).text + " - " + this.Cities.find(i => i.id == this.CityId).text;
          }
        } catch (err) {
        }
        this.$bvModal.hide("modal-cod");
        this.loading(false);
        document.location = '/mua-hang/hoan-thanh/COD?cart_info=' + JSON.stringify(c);
      })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else if (err.response.status == 403) {
              this.$toast.error(
                  "Vui lòng kiểm tra lại OTP, OTP chỉ có hiệu lực trong 1 phút!",
                  {icon: true, closeButton: "button"}
              );
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện đặt hàng, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },
    formatNumber(x) {
      return formatNumber(x);
    },
    handleGetShippingFee() {
      if (debounceHandle) {
        clearTimeout(debounceHandle);
      }
      debounceHandle = setTimeout(() => {
        this.getGHTKFee();
      }, 2000);
    },
    f087(value) {
      return value.substr(0, 4) + " " + value.substr(4);
    },
    changeState() {
      //console.log(this.$route.params.step!='hoan-thanh' && this.$route.params.step!='4');
      //if (this.$route.params.step!='hoan-thanh' && this.$route.params.step!='4'){
      //this.isLoading=true;
      if (this.$route.query.currentPhone) {
        this.currentPhone = this.$route.query.currentPhone;
      }
      if (this.$route.query.cart) {
        this.cart = JSON.parse(this.$route.query.cart);
      }
      if (this.$route.query.cart_info) {
        this.cart_info = JSON.parse(this.$route.query.cart_info);
        if (!this.cart_info.ghtk_fee) {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: 0,
          };
        }
      }
      if (this.$route.params.step) {
        this.step = this.$route.params.step;
      }
      if (sessionStorage.getItem("B2CDataForm")) {
        const oldData = JSON.parse(sessionStorage.getItem("B2CDataForm"));

        // this.cart_info = {
        //   deliver_work_ship: null,
        //   ...oldData.cart_info,
        // };
        this.CityId = oldData.CityId;
        this.DistrictId = oldData.DistrictId;
        this.WardId = oldData.WardId;
        this.paymentMethod = oldData.paymentMethod;
        this.isFast = oldData.isFast;
        this.isExpress = oldData.isExpress;
        this.hasExpressShip = oldData.hasExpressShip;
        this.activeTab = oldData.activeTab;
        // this.cart = oldData.cart
        this.storeCollapse = oldData.storeCollapse;

        if (
            this.cart.find((i) => i.eSIM) == undefined &&
            oldData.cart_info.shipment_type == "home"
        ) {
          this.needUpdateAddress = false;
        } else {
          this.needUpdateAddress = true;
        }
      } else {
        if (localStorage.getItem("ReceiverInfo")) {
          const oldData = JSON.parse(localStorage.getItem("ReceiverInfo"));
          if (moment(oldData.expiedDate, "DD/MM/YYYY").isAfter(moment(), 'day')) {
            this.cart_info = {
              deliver_work_ship: null,
              ...oldData.cart_info,
            };
            if (oldData.CityId > 0) {
              this.isMountedChange = true;
              console.log('checkl', this.isMountedChange)
            }
            this.CityId = oldData.CityId || 0;
            this.DistrictId = oldData.DistrictId || 0;
            this.WardId = oldData.WardId || 0;
            this.HamletId = oldData.HamletId || 0;
            // this.paymentMethod = oldData.paymentMethod;
            this.isFast = oldData.isFast;
            this.isExpress = oldData.isExpress;
            this.hasExpressShip = oldData.hasExpressShip;
            this.activeTab = oldData.activeTab;
            this.storeCollapse = oldData.storeCollapse;

            if (
                (this.cart.find((i) => i.eSIM) == undefined && oldData.cart_info.shipment_type == "home")
                || (oldData.CityId && oldData.DistrictId && oldData.WardId && oldData.HamletId)
            ) {
              this.needUpdateAddress = false;
            } else {
              this.needUpdateAddress = true;
            }
          } else {
            localStorage.removeItem("ReceiverInfo")
          }
        }
      }
      if (this.step == 3) {
        this.getPaymentMethod()
      }
      //this.isLoading=false;
      //}
    },
    getPaymentMethod() {
      useJwt
          .post("get-payment-method", {
            cart: this.cart
          })
          .then((response) => {
            this.paymentMethodStatus = {
              "VIETQR": response.data.result.VIETQR,
              "VNPAY_VNPAYQR": response.data.result.VNPAY_VNPAYQR,
              "VNPAY_INTCARD": response.data.result.VNPAY_INTCARD,
              "VNPAY_VNBANK": response.data.result.VNPAY_VNBANK,
              "MOMO": response.data.result.MOMO,
              "ZALOPAY": response.data.result.ZALOPAY,
              "COD": response.data.result.COD
            }
          });
    },
    buyOther() {
      this.$bvModal.hide("modal-dup");
      this.goToStep(1, false);
      this.search();
    },
    goToStep(step_, type) {
      //console.log(type)
      if (step_ == 0) {
        this.$router.push({path: "/"});
        return;
      }
      this.isLoading = true;
      let isBeauty = false;
      if (this.cart[0]) {
        isBeauty = this.cart[0].isBeautifulSimNumber;
      }
      if (step_ == 1 && type == false) {

        this.step = 1;
        this.cart = [];
        this.cart_info._track = "T_" + Math.random().toString(36).slice(2);

        if (isBeauty) {
          this.$router.push({
            path: "/mua-sim-so-dep",
            query: {},
          });
          this.isLoading = false;
          return
        }
      }
      //Tracking khi chuyển bước
      //this.JITSU('GoTo Step '+step_+', type: '+type);
      /*rudderanalytics.track('B2C - chuyển bước '+step_, {
          step: this.step,
          currentPhone: this.currentPhone,
          SearchString: this.SearchString,
          totalRecords: this.totalRecords,
          cart_info: this.cart_info,
          cart: this.cart,
          serverParams: this.serverParams,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          VnPayResponse:this.VnPayResponse,
          OrderInfo:this.OrderInfo,
          type: type
        },
        () => {console.log("in track call");}
      );*/

      if (step_ === 1 && type && isBeauty) {
        this.$router.push({
          path: "/mua-sim-so-dep",
          query: {
            currentPhone: this.currentPhone,
            cart: JSON.stringify(this.cart.map(i => {
              if (!i.eSIM)
                i.eSIM = false
              return i
            })),
            cart_info: JSON.stringify(this.cart_info),
            searchCode: this.searchCode,
          },
        });
        this.step = step_;
        this.isLoading = false;
        return
      }

      // Kiểm tra đơn hàng từ sim phong thuỷ hay không?
      if (step_ == 2 && type == false) {
        delete this.cart_info?.isSimPhongThuy;
      }
      this.$router.push({
        path: "/mua-hang/buoc/" + step_,
        query: {
          currentPhone: this.currentPhone,
          cart: JSON.stringify(this.cart.map(i => {
            if (!i.eSIM)
              i.eSIM = false
            return i
          })),
          cart_info: JSON.stringify(this.cart_info),
          searchCode: this.searchCode,
        },
      });
      this.step = step_;
      this.isLoading = false;
    },
    tracking(id, step) {
      const parsedParams = this.$route.query;
      this.$route.hash
          .split("&")
          .map((part) => part.replace(/^#/, ""))
          .forEach((param) => {
            const parts = param.split("=");
            parsedParams[parts[0]] = parts[1];
          });
      useJwt
          .post("tracking/" + id + "/" + step, {
            cart_info: this.cart_info,
            cart: this.cart,
            query: parsedParams,
          })
          .then((response) => {
            //console.log(response.data.result)
          });
    },
    w() {
      return window;
    },
    updateRate: function (rating) {
      this.loading(true);
      const OrderId = this.VnPayResponse?.vnp_TxnRef
          ? this.VnPayResponse?.vnp_TxnRef
          : this.MoMoResponse.orderId;
      localStorage.currentOrderId = OrderId
      useJwt
          .put("update-rate", {
            OrderId: OrderId,
            rate: rating,
          })
          .then((response) => {
            this.loading(false);
          })
          .catch((err) => {
            this.loading(false);
          });
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["text", "text1"],
        shouldSort: true,
      });
      return search.length
          ? fuse.search(search).map(({item}) => item)
          : fuse.list;
    },
    // getShippingFee() {
    //   if (
    //       !(this.CityId && this.DistrictId && this.WardId && this.cart_info.addr)
    //   ) {
    //     this.expressShipInfo = {
    //       ahamoveFee: 0,
    //       lastFee: 0,
    //       deleveryTime: "",
    //     };
    //     return;
    //   }
    //   let fullAddress = this.cart_info.addr || "";
    //   if (this.WardId && this.Wards.find((el) => el.id == this.WardId)) {
    //     fullAddress += `, ${this.Wards.find((el) => el.id == this.WardId).text
    //     }`;
    //   }
    //   if (
    //       this.DistrictId &&
    //       this.Districts.find((el) => el.id == this.DistrictId)
    //   ) {
    //     fullAddress += `, ${this.Districts.find((el) => el.id == this.DistrictId).text
    //     }`;
    //   }
    //   if (this.CityId && this.Cities.find((el) => el.id == this.CityId)) {
    //     fullAddress += `, ${this.Cities.find((el) => el.id == this.CityId).text
    //     }`;
    //   }
    //   useJwt
    //       .post("get_fee_and_delivery_time", {
    //         city_id: this.CityId,
    //         addr: fullAddress,
    //         FullName: this.cart_info.fullName,
    //         ContactPhone: this.cart_info.ContactPhone,
    //       })
    //       .then((response) => {
    //         this.expressShipInfo = {
    //           ahamoveFee: response?.data?.result?.ahamove_fee || 0,
    //           lastFee: response?.data?.result?.last_fee || 0,
    //           deleveryTime: response?.data?.result?.delivery_time || "",
    //         };
    //       })
    //       .catch((err) => {
    //         this.$toast.error(
    //             err?.response?.data?.message ||
    //             "Có lỗi trong khi thực hiện tra cứu, Quý khách vui lòng thử lại!",
    //             {icon: true, closeButton: "button"}
    //         );

    //         this.expressShipInfo = {
    //           ahamoveFee: 0,
    //           lastFee: 0,
    //           deleveryTime: "",
    //         };
    //       });
    // },
    onShipmentTabChange(e) {
      this.activeTab = e;
      this.change_shipment(e == 0 ? "home" : "itel");
    },
    change_shipment(e) {
      this.isLoading = true;
      this.cart_info.shipment_type = e;
      this.storeCollapse = this.cart_info.shipment_type == "home";
      this.isLoading = false;
    },
    onCityChange() {
      this.loading(true);
      this.getGHTKFee();
      useJwt
          .get(`is_fast/${this.CityId}`)
          .then((response) => {
            this.hasExpressShip = !!response?.data?.result;
            if (!this.hasExpressShip) {
              this.isExpress = false;
              this.isFast = true;
            }
            this.loading(false);
          })
          .catch((err) => {
            this.hasExpressShip = false;
            this.isExpress = false;
            this.isFast = true;
            this.loading(false);
          });
    },
    onClickExpress() {
      if (!this.isExpress) {
        this.isExpress = true;
        this.isFast = false;
      }
      this.getGHTKFee();
    },
    onClickFast() {
      if (!this.isFast) {
        this.isFast = true;
        this.isExpress = false;
      }
    },
    isFullEsim() {
      return this.cart.find((m) => !m.eSIM) == undefined
    },
    // getShipmentFee() {
    //   if (this.isOnIframe) {
    //     if (
    //         this.cart_info.shipment_type == "home" &&
    //         this.cart.find((m) => !m.eSIM) != undefined
    //     ) {
    //       //Nếu đơn CoD thì 25000
    //       if (this.framePaymentMethods == 1) {
    //         return 25000;
    //       }
    //       return 15000;
    //     } else {
    //       return 0;
    //     }
    //   } else {
    //     if (
    //         this.cart_info.shipment_type == "home" &&
    //         this.cart.find((m) => !m.eSIM) != undefined
    //     ) {
    //       //Nếu đơn CoD thì 25000
    //       if (this.paymentMethod == 'COD') {
    //         return 25000;
    //       }
    //       return 15000;
    //     } else {
    //       return 0;
    //     }
    //   }
    // },
    exception(value) {
      this.serverParams.page = 1;
      var e = this.serverParams.columnFilters.except.find((i) => i == value);
      if (e != undefined) {
        this.serverParams.columnFilters.except =
            this.serverParams.columnFilters.except.filter((i) => i != value);
      } else {
        this.serverParams.columnFilters.except.push(value);
      }
    },
    bindingTotalPrice() {
      this.totalPrice = this.getTotalPrice() - this.totalSalePrice;
    },
    getSalePrice(data) {
      this.totalSalePrice = data.DiscountAmount;
      this.handleTotalPrice();
      // this.totalSalePrice = 0;
      // if (this.isUrbox) {
      //     this.totalSalePrice = this.isSaleEvoucher;
      // }
      // this.handleTotalPrice();
      // if (this.promotionCode.toUpperCase() === 'GITEL50') {
      //     this.cart.forEach((i) => {
      //         this.totalSalePrice += 50000
      //     })
      // }
      // if (this.promotionCode.toUpperCase() === 'DAD85') {
      //     this.cart.forEach((i) => {
      //         this.totalSalePrice += 85000
      //     })
      // }
      // if (this.isPromotionCode && !this.isUrbox) {
      //     if (this.isTotalSimPrice >= 100000) {
      //         this.totalSalePrice = 30000;
      //     } else {
      //         this.totalSalePrice = this.isTotalSimPrice * 30 / 100
      //     }
      // }
    },
    handleTotalPrice() {
      this.isTotalSimPrice = 0;
      this.cart.forEach((i) => {
        if (i.eSIM) {
          this.isTotalSimPrice += i.EsimPrice + i.Price;
        } else {
          this.isTotalSimPrice += i.SimPrice + i.Price;
        }
      });
      var a = 0
      this.cart.forEach((i) => {
        if (this.DataPackages.find((m) => m.Name == i.Pack) != undefined) {
          a += this.DataPackages.find((m) => m.Name == i.Pack).Price;
        }
      });
      if ((this.isTotalSimPrice + a) - this.isSaleEvoucher < 0) {
        this.totalSalePrice = (this.isTotalSimPrice + a);
      }
    },
    getTotalPriceNotFee() {
      var n = 0;
      this.cart.forEach((i) => {
        n += i.TraThang ? i.TotalPrice : i.Price;
        if (i.eSIM) {
          n += i.EsimPrice;
        } else {
          n += i.SimPrice;
        }
        n += i.PackPrice
      });
      return n;
    },
    getTotalPrice() {
      var n = 0;
      this.cart.forEach((i) => {
        n += i.TraThang ? i.TotalPrice : i.Price;
        if (i.eSIM) {
          n += i.EsimPrice;
        } else {
          n += i.SimPrice;
        }
        n += i.PackPrice
      });
      return (
          n + (!this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0)
      );
    },
    addToCart(i, isQuaySoMayman) {
      if (this.isOnIframe && this.isChooseSim) {
        let data = i
        parent.postMessage({type: 'onChooseSim', data}, '*')
        return
      }

      console.log('cart', i);
      // insider_object
      window.insider_object = window.insider_object || {};
      window.insider_object.page = {
        type: "AddToCart",
      };
      // console.log("addToCart-->", i);
      // Push data to Insider
      window.insider_object.product = {
        id: i.Phone,
        name: i.Pack,
        currency: "VND",
        unit_price: i.SimPrice,
        unit_sale_price: 0,
        url: window.location.href,
        product_image_url: `https://itel.vn/san-pham/${i.Phone}`,
        custom: {
          product_link: `https://itel.vn/san-pham/${i?.Phone}`,
          cart: this.cart,
        },
        fromQuaySoMayMan: isQuaySoMayman
      };
      const query = {...i, fromQuaySoMayMan: isQuaySoMayman}
      this.cart.push(JSON.parse(JSON.stringify(query)));
      this.getMGMDiscount();
      console.log("cart step 2", this.cart)
      this.currentPhone = i.Phone;
      this.goToStep(2, false); // workround to remove isSimPhongThuy
    },

    cartRemoveItem(cartSelect) {
      window.insider_object = window.insider_object || {};
      window.insider_object.page = {
        type: "RemoveItem",
      };
      this.isLoading = true;
      this.cart = this.cart.filter((j) => j.Phone != cartSelect.Phone);
      // this.changeESIM();
      this.$router.push({
        path: "/mua-hang/buoc/" + this.step,
        query: {
          currentPhone: this.currentPhone,
          cart: JSON.stringify(this.cart.map(i => {
            if (!i.eSIM)
              i.eSIM = false
            return i
          })),
          cart_info: JSON.stringify(this.cart_info),
        },
      });
      this.isLoading = false;
    },

    redirectToSupport() {
      this.$router.push({
        path: this.$t("Route.Support") + this.$t("Route.ContactUs"),
      });
    },

    chonThanhToanLai() {
      if (this.$route.query.orderType == "momo_wallet") {
        return this.thanhtoanlaiMoMo();
      }
      if (this.$route.params.payment == "ZALOPAY") {
        return this.thanhtoanlaiZalo();
      }
      return this.thanhtoanlai();
    },

    thanhtoanlaiZalo() {
      const urlPay = "get-zalopay-url-again/" + this.$route.query.apptransid;
      this.loading(true);
      useJwt
          .get(urlPay)
          .then((response) => {
            if (response.data.result.url) {
              this.tracking(localStorage.trackId, "11");
              localStorage.userTrackStep = 11
              document.location = response.data.result.url;
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },

    thanhtoanlaiMoMo() {
      const urlPay = "get-momo-url-again/" + this.$route.query.orderId;
      this.loading(true);
      useJwt
          .get(urlPay)
          .then((response) => {
            if (response.data.result.url) {
              this.tracking(localStorage.trackId, "11");
              localStorage.userTrackStep = 11
              document.location = response.data.result.url;
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },

    thanhtoanlai() {
      if (this.$route.params.payment == 'VNPAYISP') {
        const localData = localStorage.getItem("ispData");
        let ispData = {}
        if (localData) {
          ispData = JSON.parse(localData)
        }
        this.loading(true);
        useJwt.post("vnpay-isp/init", ispData)
            .then((response) => {
              if (response.data.result) {
                const requestUrl = response?.data?.result.paymentUrl
                const ispTxnId = response?.data?.result.ispTxnId
                const dataKey = response?.data?.result.dataKey
                const tmnCode = response?.data?.result.tmnCode

                document.getElementById("ispTxnId").setAttribute("value", ispTxnId);
                document.getElementById("dataKey").setAttribute("value", dataKey);
                document.getElementById("tmnCode").setAttribute("value", tmnCode);
                document.getElementById("payment_form").setAttribute('action', requestUrl)
                document.getElementById("payment_form").submit();
              }
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
      } else {
        const urlPay = "get-vnpay-url-again/" + this.VnPayResponse.vnp_TxnRef;
        this.loading(true);
        useJwt
            .get(urlPay)
            .then((response) => {
              var urlParams = new URLSearchParams(response.data.result.url);
              this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
              this.cart_info.old_vnp_TxnRef = this.VnPayResponse.vnp_TxnRef;
              // console.log(response.data.result);
              this.tracking(localStorage.trackId, "11");
              localStorage.userTrackStep = 11
              document.location = response.data.result.url;
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
      }
    },
    getPrices() {
      var x = JSON.parse(JSON.stringify(this.prices));
      x.splice(0, 0, {id: 0, text: "Tất cả giá"});
      return x;
    },
    getSimGroups() {
      var x = JSON.parse(JSON.stringify(this.simGroups));
      x.splice(0, 0, {id: 0, text: "Tất cả danh mục số"});
      return x;
    },
    selectStore(id) {
      this.isLoading = true;
      this.cart_info.StoreId = id.Id;
      this.isLoading = false;
      this.storeCollapse = true;
    },
    filterReset(msg) {
      this.isLoading = true;
      this.serverParams.columnFilters.simGroup = [];
      this.simGroups.forEach((i) => {
        this.serverParams.columnFilters.simGroup.push(i.Id);
      });
      this.serverParams.columnFilters.price = [];
      for (var i = 0; i < this.prices.length; i++) {
        this.serverParams.columnFilters.price.push(i + 1);
      }
      this.serverParams.columnFilters.vip = true;
      this.serverParams.columnFilters.normal = true;
      this.serverParams.columnFilters.price1 = 0;
      if (!this.$route.query.simCategory) {
        this.serverParams.columnFilters.simCategory = 0;
      }
      this.serverParams.columnFilters.year = 0;
      this.isLoading = false;
      this.serverParams.columnFilters.except = [];
      if (msg)
        this.$toast.info("Đã reset bộ lọc!", {
          icon: true,
          closeButton: "button",
        });
    },
    search() {
      console.log("run")
      this.loading(true);
      if (this.$route.query?.code && !this.serverParams.columnFilters.search) {
        useJwt
            .post(`case-search`, {
              id: this.$route.query?.code,
              page: this.serverParams.page,
              pageSize: this.serverParams.pageSize
            })
            .then((response) => {
              this.rows = response.data.result;
              this.totalRecords = response.data.totalRecords;
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi tìm kiếm số, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
      } else {
        this.serverParams.columnFilters.price1 =
            this.serverParams.columnFilters.price1 * 1;
        console.log("serverParam", this.serverParams)
        useJwt
            .post("sim_search", this.serverParams)
            .then((response) => {
              if (response.data.totalRecords > 0) {
                this.rows = response.data.result;
                this.totalRecords = response.data.totalRecords;
                this.isNotFound = response.data.extra.isMatch == 0 ? true : false;
              } else {
                this.isNotFound = response.data.extra.isMatch == 0 ? true : false;
              }

              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi tìm kiếm số, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
              this.isNotFound = true;
            });
      }

    },

    async thanhtoan(vnp_BankCode) {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-vnpay-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
            MGT: this.isMGMSuccess ? promotionCode : null,
            PaymentChannel: this.paymentMethod,
            fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            vnp_BankCode: vnp_BankCode,
            Source: this.getSource
          })
          .then((response) => {
            //console.log(response.data.result);
            var urlParams = new URLSearchParams(response.data.result.url);
            this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
            console.log('check', this.cart_info.vnp_TxnRef);

            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                })
            );
            document.location = response.data.result.url;
            this.loading(false);
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },

    onPayment() {
      (!this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0)
      if (this.paymentMethod === "ZALOPAY" || this.paymentMethod === "MOMO" || this.paymentMethod === "VNPAY" || this.paymentMethod === "BANK" || this.paymentMethod === "INTCARD") {
        if (this.isStatusUrbox && this.getTotalPrice() - this.totalSalePrice <= 0) {
          this.$bvModal.show('bv-modal-create-null-price');
          return;
        }
      }
      switch (this.paymentMethod) {
        case "VNPAY":
          return this.thanhtoan("VNPAYQR");
        case "BANK":
          return this.thanhtoan("VNBANK");
        case "INTCARD":
          return this.thanhtoan("INTCARD");
        case "VIETQR":
          return this.thanhtoanVIETQR();
        case "MOMO":
          return this.thanhtoanMOMO();
        case "ZALOPAY":
          return this.thanhtoanZALO();
        case "COD":
          return this.thanhtoanCOD();
      }
    },
    async onOrder() {
      console.log('onOrder')
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });

      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }

      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }

      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      let data = {}
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      if (this.frameType == 'tmdt') {
        data = {
          // eCommerceId: this.ecommerces.id,
          // eCommerceNotes: this.eCommerceNotes,
          Phone: this.cart_info.ContactPhone,
          PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
          MGT: this.isMGMSuccess ? promotionCode : null,
          TotalPrice: this.getTotalPrice() - this.totalSalePrice,
          ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
        }
      }
      if (this.frameType == 'TeleSale') {
        data = {
          payment: this.framePaymentMethods,
          Phone: this.cart_info.ContactPhone,
          PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
          MGT: this.isMGMSuccess ? promotionCode : null,
          TotalPrice: this.getTotalPrice() - this.totalSalePrice,
          ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
        }
      }
      parent.postMessage({type: 'onOrderSIM', data}, '*')
    },
    handleMessageEventer(e) {
      let key = e.message ? "message" : "data";
      let data = e[key];

      if (typeof (data) == 'object') {
        if (data?.type == 'onOrderSIMSucess') {
          this.$toast.success('Tạo đơn thành công',
              {icon: true, closeButton: "button"}
          );
          this.previewOrderId = data.data.id
          this.$bvModal.show('modal-order-success')
          setTimeout(() => {
            this.$bvModal.hide('modal-order-success')
            this.$router.push("/mua-hang/")
          }, 3000);
        }

        if (data?.type == 'onOrderSIMFail') {
          this.$toast.error(
              'Tạo đơn thất bại',
              {icon: true, closeButton: "button"}
          );
        }
      }
    },
    async thanhtoanCOD() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.loading(true);
      await useJwt.post("otp_cod/" + this.cart_info.ContactPhone, {
        Phone: this.cart_info.ContactPhone,
        PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
        MGT: this.isMGMSuccess ? promotionCode : null,
        PaymentChannel: this.paymentMethod,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
        ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        IsFast: this.isExpress ? 1 : 0,
        cart: this.cart,
      }).then((response) => {
        this.loading(false);
        this.$bvModal.show('modal-cod');
        this.countDown = 60;
      }).catch((err) => {
        this.loading(false);
        this.$toast.error(err.response.data.message || "Có lỗi gửi OTP, Quý khách vui lòng thử lại!",
            {icon: true, closeButton: "button"}
        );
      });
    },
    // Payment with ZALO
    async thanhtoanZALO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      //console.log(this.cart_info)
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-zalopay-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
            MGT: this.isMGMSuccess ? promotionCode : null,
            PaymentChannel: this.paymentMethod,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
            ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            Source: this.getSource
          })
          .then((response) => {
            this.cart_info.vnp_TxnRef = response.data.result.vnp_TxnRef;
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                })
            );
            document.location = response.data.result.url;
            // console.log("VNPAY link --->", response.data.result.url);
            this.loading(false);
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },
    // payment with VIETQR
    async thanhtoanVIETQR() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      //console.log(this.cart_info)
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-vietqr-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
            MGT: this.isMGMSuccess ? promotionCode : null,
            PaymentChannel: this.paymentMethod,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
            ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            Source: this.getSource
          })
          .then((response) => {
            this.vietQr = response.data.result.data;
            this.cart_info.vnp_TxnRef = response.data.result.vnp_TxnRef;
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                })
            );
            this.loading(false);
            this.$bvModal.show('bv-modal-onPayment-VietQR')
            this.isTextVietQR = false;
            this.isOnVietQR = true;
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },
    // payment with MOMO
    async thanhtoanMOMO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      //console.log(this.cart_info)
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.loading(true);
      //Tracking khi vào màn thanh toán
      //this.JITSU('Entry VnPay');
      /*rudderanalytics.track('B2C - Entry VnPay', {
          step: this.step,
          currentPhone: this.currentPhone,
          SearchString: this.SearchString,
          totalRecords: this.totalRecords,
          cart_info: this.cart_info,
          cart: this.cart,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId
        },
        () => {console.log("in track call");}
      );
      rudderanalytics.identify('userId', {
          email:this.cart_info.email,
          Phone:this.cart_info.ContactPhone,
          fullName:this.cart_info.fullName,
          addr:this.cart_info.addr,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          cart_info: this.cart_info,
        },
        () => {console.log("in identify call");}
      );*/

      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-momo-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: this.isStatusPromotionCode ? this.isMGMSuccess ? null : promotionCode : null,
            MGT: this.isMGMSuccess ? promotionCode : null,
            PaymentChannel: this.paymentMethod,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            fromQuaySoMayMan: JSON.parse(this.$route.query.cart)[0].fromQuaySoMayMan,
            ShipmentFee: !this.isFullEsim() && this.cart_info.shipment_type == "home" ? this.cart_info.ghtk_fee : 0,
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            Source: this.getSource
          })
          .then((response) => {
            //console.log(response.data.result);
            this.cart_info.vnp_TxnRef = response.data.result.vnp_TxnRef;
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                })
            );
            document.location = response.data.result.url;
            this.loading(false);
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },

    Img(pic) {
      return images[pic];
    },
    async fetch() {
      this.loading(true);
      this.isLoading = true;
      await useJwt
          .post("getMasterData/SimCategory", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.simGroups = response?.data?.result.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1)
            this.simGroups.forEach((i) => {
              i.text = i.Name;
            });
            this.filterReset();
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/DataPack1", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.DataPackages = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/GHTK_Cities", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Cities = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/Stores1", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Stores = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      this.loading(false);
      this.isLoading = false;
    },
    ChangePaymentMethod(method) {
      this.paymentMethod = method;
      this.getGHTKFee()
    }
  },
};
</script>
<style lang="scss">
.cs-desc {
  color: #3c3c3c;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;

  .bold-text {
    color: #3c3c3c;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
  }
}

.cs-desc_sub {
  margin-top: 5px;
  color: #3c3c3c;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.b2c-note-price {
  color: #424242;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  letter-spacing: 1px;
}

.payment-method-container {
  background: #FFFFFF;
  border: 1px solid rgba(228, 228, 228, 0.6);
  border-radius: 8px;
  padding: 12px 17px;
  margin-bottom: 11px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  p {
    margin-top: 4px;
    font-size: 14px;
    line-height: 19.6px;
    font-style: italic;
    text-align: justify;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .payment-method-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .payment-method-content-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputText {
        padding-left: 8px;
      }

      .custom-checkbox {
        .custom-control-label {
          &::before {
            top: -2px;
          }

          &::after {
            top: -2px;
          }
        }
      }
    }

    .payment-method-content-image {
      min-width: 64px;
      display: flex;
      justify-content: center;

      img {
        max-width: 60px;
        max-height: 28px;
      }
    }
  }
}
</style>
<style scoped lang="scss">

.cs-buy-info {
  list-style-type: disc;

  .text-align-center {
    text-align: left !important;

    &.small {
      font-size: 12px;
    }
  }
}

.text-align-center {
  text-align: center;
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .bold, &.bold {
    font-weight: 600;
  }

  .italic, &.italic {
    font-style: italic;
  }
}

.pick-sim-type {
  width: 40%;
  border: 1px solid #E4E4E4;
  border-radius: 20px;
  padding: 1rem 2rem 0rem 1rem;
}

.payment-info .h2 {
  font-weight: 400;
}

.feather-alert-triangle {
  width: 15px;
}

.b2c-note {
  border-radius: 5px;
  background: antiquewhite;
  padding: 10px !important;
}

#bv-modal-onPayment-VietQR___BV_modal_outer_ {
  z-index: 100 !important;
}

.modal-title {
  margin: 20px 0 0 20px;
  font-size: 20px !important;
  color: #424242;
  font-weight: 700;
}

.img__btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.line {
  position: absolute;
  border: 1px dashed #EA0029;
  transform: rotate(180deg);
  width: 96%;
  left: 16px;
}

textarea.form-control {
  overflow-y: auto !important;
  height: 50px !important;
  border-radius: 4px !important;
  line-height: 1.45 !important;
  padding: 10px !important;
  font-size: 16px;
}

.arrow-icon-end {
  background-image: url("../assets/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 10px 18px;
  background-position: 95% 50%;
  padding: 11.5px !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.6;
}
</style>
<style scoped>
.b2c-cart-esim-text {
  font-family: "Roboto";
  font-style: normal;
  max-width: 369px;
  font-weight: 400;
  font-size: 14px;
  margin-right: auto;
  margin-left: auto;
}

.esim-icon {
  width: 80px;
  height: 80px;
}
</style>
<style>
.modal-header .close {
  display: none;
}

.modal .modal-content {
  width: 752px;
  border-radius: 10px;
}

.modal.fade .modal-dialog {
  margin-left: 560px;
}

/* #modal-esim .moda .modal-content {
  width: 600px !important;
  border-radius: 10px;
} */
.badge.badge-outlined.badge-success {
  border: 1px solid transparent;
  border-color: #64DD17;
  color: #64DD17;
  background-color: transparent
}

.preview-order-footer button {
  padding: 0.486rem 1rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.4rem;
  line-height: 110%;
  height: 4rem;
}

.shipping-nav {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
}

.fs-sp .shipping-nav {
  overflow-y: visible;
  overflow-x: scroll;
}

.fs-sp .shipping-nav::-webkit-scrollbar {
  display: none;
}

.shipping-nav li a {
  white-space: nowrap;
}

.table-step {
  width: 100%;
}

.table-step td {
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}

.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}

.b2c-top-right {
  width: 20%;
}

.b2c-top-right1::before {
  width: 20%;
}

.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}

.b2c-steps {
  width: 100%;
  height: 42px;
  background: #d71a1f;
}

.b2c-text-solo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}

.b2c-number-087 {
  width: auto;
  padding-left: 25px;
  text-align: right;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}

.b2c-number-msisdn {
  width: 0px;
  flex: 100%;
  padding: 0px 10px;
}

.b2c-number-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  letter-spacing: 0px;
  padding-left: 0px;
  font-weight: 400;
}

.b2c-number-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ed1f24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  letter-spacing: 3px;
  padding-left: 10px;
}

.b2c-number-btn {
  width: auto;
  padding-right: 25px;
}

.b2c-number-btn button {
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center;
}

.b2c-number--container {
  padding: 0 25px;
  height: 36px;
  background: #d71a1f;
  width: 100%;
}

.b2c-number-except {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-except-e {
  margin: 0 25px;
  height: auto;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-type {
  margin-left: 25px;
  margin-right: 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c__btn {
  margin-left: 10px;
  font-size: 16px;
  height: auto !important;
  width: 22%;
}

.b2c-number-price {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 30px;
}

.b2c-number-except-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-except-buttons {
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}

.b2c-number-except-btn {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}

.b2c-number-except-btn-checked {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #ed1f24;
  width: 29px;
  height: 29px;
}

.b2c-number-except-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-except-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-type-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-type-select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background: #d71a1f;
}

.b2c-number-type-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}

.b2c-search-button {
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #ffffff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  margin: 25px;
}

.b2c-search-button-red {
  color: #ffffff !important;
  background: #ed1f24 !important;
}

.b2c-search-button-secondary {
  color: #ffffff !important;
  background: #8a8a8a !important;
}

.b2c-pagination-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}

.b2c-pagination-title-sort {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.b2c-badge {
  font-size: 14px;
}

.b2c-buy-btn {
  border: 2px solid #ed1f24;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-size: 16px;
  color: #ed1f24;
  height: 34px;
  width: 96px;
}

.b2c-page-msisdn {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}

.b2c-camket-text {
  font-size: 14px;
  margin-top: 12px;
}

.b2c-price-text {
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color: #424242;
}

.b2c-price-text b {
  color: #ed1f24;
}

.b2c-package-bg-selected {
  background: #f9f9f9;
}

.b2c-package-title-selected {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ed1f24;
}

.b2c-package-subtitle-selected {
  font-family: "Roboto";
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width: 50%;
  color: #424242;
  margin-bottom: 10px;
  padding-right: 10px;
}

.b2c-package-brief-selected {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief-selected li {
  margin-top: 10px;
}

.b2c-package-brief-selected li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  padding-right: 10px;
}

.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-title {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ed1f24;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}

.b2c-package-subtitle {
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}

.b2c-package-brief {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief li {
  margin-top: 10px;
}

.b2c-package-brief li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  padding-right: 10px;
}

.b2c-package-brief .pack-txt,
.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-brief-selected .pack-txt,
.b2c-package-brief .pack-txt {
  margin-bottom: 10px;
}

.b2c-float-bg {
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}

.b2c-cart-price {
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  right: 20px;
  background: #18d3c9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}

.b2c-cart-price span {
  font-size: 24px;
}

.b2c-cart-add-more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}


.b2c-cart-info-title {
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  padding: 1.5rem;
}

.b2c-cart-info-title-text {
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 10px 24px;
}

.b2c-agree-text button {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}

.b2c-store-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-store-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-bg-selected {
  background: #17e0d5;
  line-height: 40px;
}

.b2c-bg-selected .b2c-page-msisdn,
.b2c-bg-selected .b2c-price-text,
.b2c-bg-selected .b2c-camket-text,
.b2c-bg-selected .b2c-price-text b {
  color: #fff;
}

.b2c-text-solo h1 {
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}

.b2c-text-solo p {
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}

.b2c-search-button-red:disabled {
  background: #999999 !important;
}

.b2c-cart-bg-del {
  border-radius: 26.8px;
  -webkit-border-radius: 26.8px;
  border: 1.5px solid #ed1f24;
  padding: 6px 16px;
}

.b2c-price-texts {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.b2c-contac-phone-texts {
  font-weight: 500;
  letter-spacing: 0.3px;
}

.b2c-rate-text {
  font-size: 20px;
  line-height: 1.4;
}

.vue-star-rating {
  display: block !important;
}

.b2c-ghtk-fee {
  font-size: 14px;
  text-decoration-line: line-through !important;
}

.line-through {
  text-decoration-line: line-through !important;
}

.b2c-o-h {
  max-width: 100%;
  overflow-x: hidden;
}

.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}

.b2c-pc-header {
  display: flex;
}

.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}

.b2c-pc-find {
  max-width: 122.4rem;
  height: 120px;
  background: #ed1f24;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  margin: 34px 0px 20px 0px;
}

.b2c-pc-find .leading-required-number {
  padding-left: 92px;
}

.b2c-pc-find > p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 8px 0px 8px 20px;
}

.b2c-pc-find > div {
  display: flex;
}

.b2c-pc-find > div > div {
  width: 33.3%;
  display: flex;
}

.b2c-pc-number-087 {
  width: 25%;
  text-align: right;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
  margin-left: 20px;
}

.b2c-pc-number-msisdn {
  width: 75%;
  padding: 0px 10px;
}

.b2c-pc-number-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  letter-spacing: 0px;
  padding-left: 0px;
  font-weight: 400;
}

.b2c-pc-number-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ed1f24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  letter-spacing: 3px;
  padding-left: 10px;
}

.b2c-pc-number-except {
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 0px;
  margin-right: 30px;
  margin-left: 15px;
}

.b2c-pc-number-except-e {
  margin-right: 30px;
  margin-left: 15px;
  height: auto;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 0px;
}

.b2c-pc-number-except-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-pc-number-except-buttons {
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}

.b2c-pc-number-except-btn {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}

.b2c-pc-number-except-btn-checked {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #ed1f24;
  width: 29px;
  height: 29px;
}

.b2c-pc-number-except-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-pc-number-except-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-pc-number-type-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-pc-number-type-select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.b2c-pc-number-type-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}

.b2c-pc-number-type {
  background: #d71a1f;
  border-radius: 5px;
  max-height: 46px;
  margin-right: 40px;
}

.b2c-pc-find-sub {
  margin-right: auto;
  margin-left: auto;
  max-width: 122.4rem;
}

.b2c-pc-find-sub select {
  width: auto;
}

.b2c-pc-find-sub .b2c-pagination-title-sort {
  margin-left: auto;
}

.b2c-pc-list {
  margin-right: auto;
  margin-left: auto;
  max-width: 122.4rem;
  padding-bottom: 10px;
}

.b2c-pc-list table {
  border: 1px solid #c2c2c2;
  width: 100%;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #c2c2c2;
}

.b2c-pc-list th {
  font-weight: 700;
  font-size: 18px;
  line-height: 60px;
  height: 60px;
}

.b2c-pc-list .b2c-badge-cont {
  width: 50%;
}

.b2c-pc-list td {
  font-weight: 500;
  font-size: 18px;
  height: 70px;
  padding: 20px 10px;
  vertical-align: middle;
}

.b2c-pc-list td .custom-checkbox {
  margin-left: 80px;
}

.b2c-pc-list tr:nth-child(odd) {
  background: #f9f9f9;
}

.b2c-pc-list tr:nth-child(even) {
  background: #fff;
}

.b2c-pc-list th {
  background: #f9f9f9;
}

.b2c-pc-list th:first-child {
  background: #f9f9f9;
  border-top-left-radius: 10px;
}

.b2c-pc-list th:last-child {
  background: #f9f9f9;
  border-top-right-radius: 10px;
}

.b2c-pc-list tr:last-child:nth-child(odd) td:first-child {
  background: #f9f9f9;
  border-bottom-left-radius: 10px;
}

.b2c-pc-list tr:last-child:nth-child(odd) td:last-child {
  background: #f9f9f9;
  border-bottom-right-radius: 10px;
}

.b2c-pc-pack-content {
  min-height: 550px;
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
}

.b2c-pc-pack-content .package {
  width: 558px;
  height: 304px;
  background: #ed1f24;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 250px;
  position: relative;
}

.b2c-pc-pack-content .package h4 {
  font-size: 16px;
  text-align: center;
  padding-top: 30px;
  color: #fff;
}

.b2c-pc-pack-content .package h2 {
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.b2c-pc-pack-content .package .subpackage {
  width: 369px;
  height: auto;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 94.5px;
  margin-top: 20px;
}

.b2c-pc-pack-content .package .subpackage .sub-header {
  border-bottom: 1px solid #0000001a;
  display: flex;
  height: 64px;
}

.b2c-pc-pack-content .package .subpackage h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #ed1f24;
  font-style: italic;
  width: 50%;
  text-align: left;
  padding: 14px 0px 0px 28px;
}

.b2c-pc-pack-content .package .subpackage h4 {
  width: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #424242;
  padding-top: 20px;
  padding-right: 30px;
  text-align: right;
}

.b2c-pc-pack-content .package .subpackage h4 > span {
  font-weight: 400;
  font-size: 14px;
}

.b2c-pc-pack-content .package .subpackage .pack-txt {
  padding: 6px 15px 0px 15px;
  max-width: 100%;
}

.b2c-pc-agree,
.b2c-pc-pack-content .pc-step2-button {
  max-width: 558px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
}

.b2c-pc-pack-content .package .b2c-cart-bg {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-cart-add-more {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-pc-payment .package {
  height: 200px;
}

.b2c-pc-pack-content .b2c-pc-info {
  margin-top: 50px;
}

.b2c-pc-pack-content .b2c-pc-info .card-header {
  background: #f8f8f8;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.card-header-title {
  font-weight: 500;
  font-size: 18px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.custom-checkbox.custom-control {
  padding-left: 2.2rem;
}

table tr.b2c-bg-selected {
  background: #17e0d5 !important;
}

.b2c-pc-number-type .vs--searchable .vs__dropdown-toggle,
.b2c-number-type .vs--searchable .vs__dropdown-toggle {
  background: #d71a1f;
  border: none;
  height: 46px;
}
.b2c-pc-number-type .vs--unsearchable .vs__dropdown-toggle,
.b2c-number-type .vs--unsearchable .vs__dropdown-toggle {
  background: #d71a1f;
  border: none;
  height: 46px;
}

.b2c-pc-number-type .vs--single,
.b2c-number-type .vs--single {
  background: #d71a1f;
  height: 46px;
}

.b2c-pc-number-type .vs__selected,
.b2c-number-type .vs__selected {
  color: #fff;
  font-weight: 500;
}

.b2c-pc-pack-content .package .text1 {
  padding: 0 10%;
  color: #fff;
  font-size: 16px;
}

.space-between {
  justify-content: space-between;
}

.delivery-type {
  color: #424242;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-bt {
  border-bottom: 0.7px solid #bfbfbf;
}

.info-delivery__title {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.info-delivery--subtile {
  font-size: 1.2rem;
}

.info-delivery__sub-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-style: italic;
}

.check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.check-box > img {
  width: 22px;
  height: 22px;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.detail-order {
  max-width: 325px;
  /*max-height: 315px;*/
  margin: 0 auto;
  border: #ffff;
  border-radius: 8px;
  background: #FFFFFF;
  border: solid rgba(0, 0, 0, 0.05);
  line-height: 1.5;
}

.detail-order-isp {
  max-width: 325px;
  margin: 0 auto;
  border: #ffff;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.25);
}

.text-isp-info {
  font-size: 1.6rem;
  color: black !important;
  line-height: 150%;
  font-weight: 700;
}

button.style-button-chat {
  width: 100%;
  border-radius: 27px !important;
  font-size: 16px;
  font-weight: 500;
}

button.style-button-contact {
  width: 100%;
  border-radius: 27px !important;
  font-size: 16px;
  font-weight: 500;
}

button.style-button {
  width: 100%;
  border-radius: 27px !important;
  font-size: 18px;
  font-weight: 600;
}

.style-btn {
  background: #fff !important;
}

.text-contact {
  color: #007bff !important;
  font-size: 18px;
}

.complete {
  margin: 0 auto;
  max-width: 562px;
}

.complete .card-body {
  padding: 0 !important;
}

.huge-text {
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-bottom: 16px;
}

.select-delivery-time .vs__search::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
  font-size: 16px;
  letter-spacing: 0px;
  padding-left: 0px;
  font-weight: 400 !important;
}

.vs__search::placeholder {
  color: #fff !important;
  font-weight: 500 !important;
}

.b2c-pc-number-type .vs__selected-options {
  display: contents !important;
}

.content-body {
  font-size: 14px !important;
}

.btn-secondary {
  background: #6c757d !important;
  border: #6c757d !important;
  padding: 0.786rem 1.5rem !important;
  font-size: 14px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
}

.btn-primary {
  font-size: 14px !important;
  font-family: "Roboto";
  padding: 0.786rem 1.5rem !important;
  font-style: normal;
  font-weight: 700;
}

.inputText {
  font-size: 18px;
}

.paymentBox {
  height: 52px;
  margin-bottom: 4%;

}

.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}

.buttonZaloMomo {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  margin: 2%;
  width: 231px;
  height: 80px
}

.borderPaymentBox {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.buttonZaloMomoActive {
  border: 1px solid #FF0000;
}

[dir] .custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: white;
  border: #d8d6de solid 1px;
}

[dir] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgb(255 0 6);
}

@media screen and (max-width: 1440px) {
  .modal.fade .modal-dialog {
    margin-left: 250px;
  }
}

@media screen and (max-width: 820px) {
  .modal.fade .modal-dialog {
    margin: 0.8rem;
  }

  .cs-buy-info {
    padding: 0 25px;
  }
}

@media screen and (max-width: 450px) {
  .b2c-payment {
    flex-direction: column;
  }

  .onPayment-w {
    width: 100% !important;
  }

  .line {
    width: 90% !important;
  }

  .payment-center {
    justify-content: center !important;
  }

  .payment-center button {
    width: 100% !important;
  }

  .buttonZaloMomo {
    width: 45%;
  }

  .zaloLogo {
    width: 85%;
    height: 45%;
  }

  .inputText {
    font-size: 16px;
  }

  .b2c__btn {
    width: 45%;
  }

  .modal .modal-content {
    width: 90%;
    margin: 0 auto;
  }
}

.b2c-phone {
  font-weight: 400;
}

.b2c-phone .spotlight {
  font-weight: 900;
}

.b2c-card-header {
  background: #f8f8f8;
}

</style>
