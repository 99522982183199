import { render, staticRenderFns } from "./InputRequiredPhone.vue?vue&type=template&id=6aceabc9&scoped=true&"
import script from "./InputRequiredPhone.vue?vue&type=script&lang=js&"
export * from "./InputRequiredPhone.vue?vue&type=script&lang=js&"
import style0 from "./InputRequiredPhone.vue?vue&type=style&index=0&id=6aceabc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aceabc9",
  null
  
)

export default component.exports