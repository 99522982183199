<template>
    <div class="input-required-phone">
        <div class="input-required-phone-container">
            <input :value="phone[0]" ref="input1" type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 0)" @keydown="onInputChange($event, 0)" @click="onClickInput(0)"/>
            <input :value="phone[1]" ref="input2"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 1)" @keydown="onInputChange($event, 1)" @click="onClickInput(1)"/>
            <input :value="phone[2]" ref="input3"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 2)" @keydown="onInputChange($event, 2)" @click="onClickInput(2)"/>
            <input :value="phone[3]" ref="input4"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 3)" @keydown="onInputChange($event, 3)" @click="onClickInput(3)"/>

            <input :value="phone[4]" ref="input5"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 4)" @keydown="onInputChange($event, 4)" @click="onClickInput(4)"/>
            <input :value="phone[5]" ref="input6"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 5)" @keydown="onInputChange($event, 5)" @click="onClickInput(5)"/>
            <input :value="phone[6]" ref="input7"  type="number" maxlength="1" autocomplete="off" inputmode="number" placeholder="*" class="input-required-unit" @paste="onPaste($event, 6)" @keydown="onInputChange($event, 6)" @click="onClickInput(6)"/>
        </div>
        <div class="text-description-required-phone">
            Điền vào dấu * chữ số mong muốn
        </div>
    </div>
    
</template>
<script>
export default {
    name: "input-required-phone",
    props: {
        value: String
    },
    data() {
        return {
            phone: ['', '', '', '', '', '', '']
        }
    },
    mounted(){
        if(this.value){
            for(let i = 0; i < 7; i++){
                this.phone[i]= this.value[i] && this.value[i] !== '*' ? this.value[i] : '';
            }
        }
        this.$forceUpdate();
    },
    methods: {
        onPaste(e, index){
            e.preventDefault()
             
            let clipText = e.clipboardData.getData('text/plain')
            if(clipText){
                this.handlePasteData(index, clipText)
            }
        },
        onInputChange(e, index){
            if(e.key == "e"){
                e.preventDefault()
                return
            }
            if(e.key === "Backspace"){
                this.$forceUpdate()
                if(index > 0){
                    this.$refs[`input${index}`].focus()
                    if(this.phone[index -1]){
                        this.$refs[`input${index}`].select()
                    }
                }
                this.phone[index] = ""
                e.preventDefault()
                this.$emit("input", this.getRequiredPhone())
                return
            }
            if(e.key === "ArrowRight"){
                if(index < 6){
                    this.$refs[`input${index + 2}`].focus()
                }
                e.preventDefault()
                return
            }
            if(e.key === "ArrowLeft"){
                if(index > 0){
                    this.$refs[`input${index}`].focus()
                }
                e.preventDefault()
                return
            }
            if(e.key === "Enter"){
                this.$emit("onSearch")
                return
            }
            if((/^\d+$/).test(e.key)){
                this.phone[index] = e.key
                if(index < 6){
                    this.$refs[`input${index + 2}`].focus()
                    if(this.phone[index + 1]){
                        this.$refs[`input${index + 2}`].select()
                    }
                }
                e.preventDefault()
                this.$forceUpdate()
                this.$emit("input", this.getRequiredPhone())
                return
            }
        },
        handlePasteData(index, clipText){
           let listPastes = this.getListNumFromText(clipText);
            if(listPastes.length == 0){
                return
            }

            for(let i = index; i < 7; i++){
                this.phone[i] = listPastes[i - index + ((listPastes.length - (6 - index)) > 0 ? (listPastes.length - (7 - index)) : 0)] || ""
                if(listPastes[i - index + ((listPastes.length - (6 - index)) > 0 ? (listPastes.length - (7 - index)) : 0)] == undefined){ 
                    for(let j = i; j < 7; j++){
                        this.phone[j] = ""
                    }
                    this.$refs[`input${i + 1}`].focus()
                    break
                }
                if(i === 6){
                    this.$refs[`input7`].focus()
                }
            }

            this.$forceUpdate()
            this.$emit("input", this.getRequiredPhone())
        },
        onClickInput(index){
            this.$refs[`input${index + 1}`].focus()
            if(this.phone[index]){
                this.$refs[`input${index + 1}`].select()
            }
        },
        getRequiredPhone(){
            let rs = "";
            for(let i = 0; i < this.phone.length; i++){
                rs += this.phone[i] || '*'
            }
            return rs == "*******" ? "" : rs
        },
        getListNumFromText(text){
            let nums = [];
            if(text && text.length > 0){
                for(let i = 0; i < text.length; i++){
                    if((/^\d+$/).test(text[i])){
                        nums.push(text[i])
                    }
                }
            }
            if(nums.length > 7){
                nums = nums.slice(nums.length - 7, nums.length)
            }
            return nums
        }
    }
}
</script>
<style lang="scss" scoped>
    .input-required-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .text-description-required-phone{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        white-space: nowrap;
        margin-top: 7px;
    }
    .input-required-phone-container{
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;
        background: #fff;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        .input-required-unit{
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            height: 45px;
            color: #ed1f24;

            width: 18px;
            border: none;
            background: transparent;
            letter-spacing: 3px;
            &.space{
                margin-left: 16px;
            }
            &::placeholder{
                position: relative;
                top: 5px;
                text-align: center;
            }
            &:nth-child(5){
                margin-left: 16px;
            }
        }
    }
    .fs-sp .input-required-phone-container{
        justify-content: space-around;
    }
</style>